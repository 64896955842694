import { AccountContext } from 'contexts/Account/AccountContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useiceWater from '../../hooks/useIceWater';

import { WalletContext } from '../../contexts/Account/WalletContext';
import CubeReceived from './CubeReceived';
import Spacer from 'components/Spacer';
import { MobileStyles } from 'theme/Mixins';

const CubesReceived: React.FC = () => {
    const { address, connect, disconnect } = useContext(WalletContext)
    const iceWater = useiceWater()
    const { cubesReceived } = useContext(AccountContext)

    return (
        <Content>
            <Header>
                <Row>
                    Cubes Owned
                </Row>
                <Row>
                    Maturity Date
                </Row>
                <Row>
                    Time Remaining
                </Row>
                <Row>

                </Row>
            </Header>

            { cubesReceived && cubesReceived.length > 0 &&
                <CubeWrap>
                    {cubesReceived.map((cube:any, index:number) => (
                        <CubeReceived key={cube} 
                            cubeId={cube}  />
                    ))}
                </CubeWrap>
            }
        </Content>
    );
};


const Content = styled.div `
    ${MobileStyles(`

    `)};
`

const Header = styled.div `
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;

    padding: 10px 0px;

    > div {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    > div:nth-child(1) {
        flex: 1;
        justify-content: left;
        padding-left: 20px;
    }
    > div:nth-child(2) {
        width: 150px;
    }
    > div:nth-child(3) {
        width: 150px;
    }
    > div:nth-child(4) {
        width: 100px;
    }

    ${MobileStyles(`
        > div:nth-child(2) {
            display: none;
        }
        > div:nth-child(3) {
            display: none;
        }
    `)};
`

const Row = styled.div `

`

const CubeWrap = styled.div `

`

export default CubesReceived;
