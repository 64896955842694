import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'
import useiceWater from 'hooks/useIceWater';
import { IceWaterContext } from 'contexts/IceWaterProvider';
import TokenSymbol from 'components/TokenSymbol';
import { Desktop, Mobile } from "components/Responsive";

import numeral from 'numeral';
import TextChanged from 'components/TextChanged';
import Spacer from 'components/Spacer';

export interface tokenBalancesProps {
    h2o: string,
    ice: string,
    steam: string,
    cube: number,
    //vent: string,
    claimableH2OFromICE: string,
    //claimableH2OFromSTEAM: string,
    claimableH2O: number,
    //claimableVent: string
}

interface Props {

}

const AccountBalances: React.FC<Props> = ({

}) => {
    const { marketStats } = useContext(IceWaterContext)
    const iceWater = useiceWater()

    const [diff, setDiff] = useState<number | null>(null)
    const [belowTarget, setBelowTarget] = useState<boolean | null>(null)

    useEffect(() => {
        if ( marketStats ) {
            const d = marketStats.h2oTotalSupply - marketStats.h2oTargetSupply
            setDiff(d)
            setBelowTarget((d < 0) ? true : false)
        }
    }, [marketStats])

    return (
    <SupplyWrap>
        <Label>Total H2O Supply</Label>  
        <Row>  
            <Col className='h2o'>
              <TokenSymbol size={37} symbol="H2O" />
              <Token>
                <TokenType>Current</TokenType>
                  { marketStats.h2oTotalSupply ? (
                    <SupplyValue amount={marketStats.h2oTotalSupply} color='h2o' />
                  ) : (
                    <Value>--</Value>
                  )}
              </Token>
            </Col>

            <Col className='h2o right'>          
                <Token className='right'>
                    <TokenType className='right'>Target</TokenType>
                    { marketStats.h2oTargetSupply ? (
                        <SupplyValue amount={marketStats.h2oTargetSupply} color='h2o' />
                    ) : (
                        <Value>--</Value>
                    )}
                </Token>
                <TokenSymbol size={37} symbol="H2O" />
            </Col>     
        </Row>
        <Spacer size="sm" />
        {/* { diff != null &&
            <div className='diff'>
                { diff < 0 ? (
                    <>Below target by { numeral(diff).format('0,0.00') } H2O</>
                ) : (
                    <>Above target by +{ numeral(diff).format('0,0.00') } H2O</>
                )}
            </div>
        } */}
    </SupplyWrap>      
  )
}

export default AccountBalances

const SupplyWrap = styled.div`
    width: 100%;
    max-width: 625px;    
    margin-top: 0px;

    .diff {
        text-align: center;
    }

    ${MobileStyles(`
        width: 90%;
    `)};
`;

const Label = styled.div `
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
    opacity: .8;
    ${MobileStyles(`
        display: none;
    `)};
`

const Row = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
const Token = styled.div `
    margin-left: 10px;
    position: relative;
    &.right {
        text-align: right;

    }
    ${MobileStyles(`
        margin-left: 0px;
    `)};
    
`

const TokenType = styled.div `
    font-size: 14px;

    &.right {
        margin-right: 10px;
    }

    ${MobileStyles(`
        font-size: 12px;
    `)};
`

const Value = styled.div `
    margin-right: 10px;
    font-size: 20px;
    ${MobileStyles(`
        font-size: 18px;
    `)};
`

const Col = styled.div `
    display:flex;
    width: 50%;
    align-items: center;
    border-bottom: 4px solid;
    padding: 10px;
    margin: 0px 2.5px;

    &.right {
        justify-content: right;
    }

    
    span {
        vertical-align: middle;
    }

    &.h2o {
        border-color: ${(props) => props.theme.color.h2o};
    }
    &.ice {
        border-color: ${(props) => props.theme.color.ice};
    }
    &.steam {
        border-color: ${(props) => props.theme.color.steam};
    }
    ${MobileStyles(`
        img {
            width: 20px !important;
            height: 20px !important;
            display: none;
        }
    `)};
`


interface ValueProps {
    amount: number,
    color: 'h2o' | 'ice' | 'steam'
}

const SupplyValue: React.FC<ValueProps> = ({
    amount,
    color
}) => {  
    return (
        <SValue className='supplyValue'>
            <Desktop>
                <TextChanged text={numeral(amount).format('0,0.00')} color={color} />
            </Desktop>
            <Mobile>
                { ( amount > 10000) ? (
                    <TextChanged text={numeral(amount).format('0.00a')} color={color} />
                ) : (
                    <TextChanged text={numeral(amount).format('0,0.00')} color={color} />
                )}
            </Mobile>
        </SValue>
    )
}

const SValue = styled.div `
    margin-right: 10px;
    font-size: 20px;
    ${MobileStyles(`
        font-size: 18px;
    `)};
`