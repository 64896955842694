import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import TokenSymbol from 'components/TokenSymbol';
import MiddleEllipsis from "react-middle-ellipsis";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import StyledLink from 'components/Link'
import { EtherscanDomain, TokensClassName } from 'utils/utils';
import Tx from './Tx';
import TxInfo from './TxInfo';
import { WalletContext } from 'contexts/Account/WalletContext';
import { DateTime } from 'luxon';
import useiceWater from 'hooks/useIceWater';
import numeral from 'numeral';
import Web3 from 'web3';

interface Props {
    tx: any
}

const TxTransfer: React.FC<Props> = ({
    tx
}) => {
    const iceWater = useiceWater()
    const { address } = useContext(WalletContext)
    const [block, setBlock] = useState<any>(null) 
    const [label, setLabel] = useState<string>('Transfer') 
    const [summary, setSummary] = useState<string>('') 
    const [cubeRewards, setCubeRewards] = useState<any>(null) 

    const data = tx.returnValues
    const token = iceWater.getSymbolByAddress(tx.address)
    const amount = Web3.utils.fromWei(data.value)

    var niceAmount: string
    if ( data.from.toLowerCase() == address ) {
        niceAmount = `${numeral(amount).format('0,0.000')}`
    } else {
        niceAmount = `${numeral(amount).format('0,0.000')}`
    }

    const onHandleOpen = async (open:boolean) => {
        if ( open ) {
            setBlock(
                await iceWater.web3.eth.getBlock(tx.blockNumber)
            )
        }
    }  

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        if ( data.from.toLowerCase() == address ) {
            setSummary(`Sent ${niceAmount} ${token}`)
        } else {
            setSummary(`Received ${niceAmount} ${token}`)
        }
    }  

    const value = () => {
        if ( data.from.toLowerCase() == address ) {
            return (
                <>-{ niceAmount } { token }</>
            )
        } else {
            return (
                <>+{ niceAmount } { token }</>
            )
        }
    }
 
    return (
        <Tx tx={tx}
            label={label}
            icon={<TokenSymbol symbol={token} size={36} />}
            className={token.toLowerCase()}
            onOpen={onHandleOpen}
            value={value()}>      

            <TxInfo label='Summary'>
                { summary }
            </TxInfo>

            { block !== null &&
            <TxInfo label='Date'>
                {DateTime.fromSeconds(block.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
            </TxInfo> 
            }    

            { !cubeRewards &&
                <>
                { data.from.toLowerCase() == address ? (
                    <TxInfo label='To'>
                        { data.to }
                    </TxInfo> 
                ) : (
                    <TxInfo label='From'>
                        { data.from }
                    </TxInfo> 
                )} 
                </>
            }

            <TxInfo label='Transaction ID'>
                <MiddleEllipsis>
                    <span>{tx.transactionHash}</span>
                </MiddleEllipsis>
            </TxInfo>

            <TxInfo label='' className='etherscan'>
                <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                    Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                </StyledLink>
            </TxInfo>
        </Tx>
    )    
}

export default TxTransfer
