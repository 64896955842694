import { useCallback, useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import useIsWindowVisible from '../../hooks/useIsWindowVisible';
import { updateBlockNumber } from './actions';
import { WalletContext } from 'contexts/Account/WalletContext';

export default function Updater(): null {
  const { provider, web3, chainId } = useContext(WalletContext)

  const dispatch = useDispatch();

  const windowVisible = useIsWindowVisible();

  const [state, setState] = useState<{
    chainId: number | undefined;
    blockNumber: number | null;
  }>({
    chainId,
    blockNumber: null,
  });

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      setState((state) => {
        if (chainId === state.chainId) {
          if (typeof state.blockNumber !== 'number') return { chainId, blockNumber };
          return { chainId, blockNumber: Math.max(blockNumber, state.blockNumber) };
        }
        return state;
      });
    },
    [chainId, setState],
  );

  // attach/detach listeners
  useEffect(() => {
    if (!web3 || !chainId || !windowVisible) return undefined;
    setState({ chainId, blockNumber: null });

    web3.eth
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch((error:any) =>
        console.error(`Failed to get block number for chainId: ${chainId}`, error),
      );

    //web3.on('block', blockNumberCallback)
    // jspeirs commented line out below because it was causing a compiling error and
    // i'm not sure how it's being used anyway.
    //return () => provider.removeListener('block', blockNumberCallback);
  }, [dispatch, chainId, web3, blockNumberCallback, windowVisible]);

  const debouncedState = useDebounce(state, 100);

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !windowVisible) return;
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
      }),
    );
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId]);

  return null;
}
