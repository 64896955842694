import React, { useCallback } from 'react';
import styled from 'styled-components';

interface SideMenuSpacerProps {

}
  
export const SideMenuSpacer: React.FC<SideMenuSpacerProps> = ({

}) => {  

    return (
        <MenuSpacer/>
    )
}


const MenuSpacer = styled.div `
    height: 1px;
    background: ${props => props.theme.card.borderColor};
    margin: 20px 0px;
`
