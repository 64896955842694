import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Label from '../Label';
import Modal, { ModalProps } from '../Modal';
import ModalActions from '../ModalActions';
import ModalTitle from '../ModalTitle';
import { MobileStyles } from 'theme/Mixins';
import useiceWater from '../../hooks/useIceWater';
import { Configuration } from '../../config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { EtherscanDomain } from 'utils/utils';

interface NetworkInfoModalProps extends ModalProps {
    onConfirm: () => void;
    onDismiss: () => void;
}

interface cfgProps extends ModalProps {
    onConfirm: () => void;
    onDismiss: () => void;
}

interface tokenAddresses {
  ctr: string,
  h2o: string,
  ice: string,
  steam: string
}

const NetworkInfoModal: React.FC<NetworkInfoModalProps> = ({
  onConfirm,
  onDismiss }) => {

    /*
    const handleConfirm = useCallback(() => {
        onConfirm();
        // onDismiss();
      }, [onConfirm, onDismiss]);
    
    const handleDismiss = useCallback(() => onDismiss(), [onDismiss]);
    */

    const handleConfirm = () => {
      onConfirm();
      // onDismiss();
    }
    const handleDismiss = () => { onDismiss() }

    const iceWater = useiceWater()
    const cfg:Configuration = iceWater.getConfig()
    
    const [tokenAddresses, setTokenAddresses] = useState<tokenAddresses>({
      ctr: '',
      h2o: '',
      ice: '',
      steam: ''
    });

    const fetchAddresses = async () => {
      const [control, h2o, ice, steam] = await Promise.all([
        iceWater.getContractAddress('CTR'),
        iceWater.getContractAddress('H2O'),
        iceWater.getContractAddress('ICE'),
        iceWater.getContractAddress('STEAM'),
      ]);    
      setTokenAddresses({ 
        ctr: control, 
        h2o: h2o, 
        ice: ice,
        steam: steam
      });
    }

    /*
    const fetchAddresses = useCallback(async () => {
      const [control, h2o, ice, steam] = await Promise.all([
        iceWater.getContractAddress('CTR'),
        iceWater.getContractAddress('H2O'),
        iceWater.getContractAddress('ICE'),
        iceWater.getContractAddress('STEAM'),
      ]);    
      setTokenAddresses({ 
        ctr: control, 
        h2o: h2o, 
        ice: ice,
        steam: steam
      });
    }, [iceWater, setTokenAddresses]);
    */
  
    useEffect(() => {
      if (iceWater) {
        fetchAddresses().catch((err) => console.error(err.stack));
      }
    }, [iceWater]);  

    // https://docs.metamask.io/guide/rpc-api.html#wallet-watchasset
    /*
    const addH2OToMetaMask = () => {
        const config = iceWater.getTokenConfig()
        if ((window as any).ethereum) {
          (window as any).ethereum
            .request({
              method: 'wallet_watchAsset',
              params: {
                type: 'ERC20',
                options: {
                  address: config.address,
                  symbol: config.symbol,
                  decimals: config.decimals,
                  image: 'https://app.icewater.money/token.svg',
                },
              },
            })
            .then((success: any) => {
              if (success) {
                console.log('H2O successfully added to wallet!')
              } else {
                throw new Error('Something went wrong.')
              }
            })
            .catch(console.error)
        }
    }
    */
    
  return (
      <Content>
            <Modal >
                <ModalTitle text="IceWater Contracts" />
                <InputGrp>
                    <InputLabel>H2O</InputLabel>
                    <Flex>
                    <InputValue>{tokenAddresses.h2o}</InputValue>
                    <StyledLink href={`${EtherscanDomain()}/token/${tokenAddresses.h2o}`}
                                target="_blank">
                        <FontAwesomeIcon className="chevron" icon={faExternalLinkAlt} />
                    </StyledLink>
                    </Flex>
                </InputGrp>

                <InputGrp>
                    <InputLabel>ICE</InputLabel>
                    <Flex>
                    <InputValue>{tokenAddresses.ice}</InputValue>
                    <StyledLink href={`${EtherscanDomain()}/token/${tokenAddresses.ice}`}
                                target="_blank">
                        <FontAwesomeIcon className="chevron" icon={faExternalLinkAlt} />
                    </StyledLink>
                    </Flex>
                </InputGrp>

                <InputGrp>
                    <InputLabel>STM</InputLabel>
                    <Flex>
                    <InputValue>{tokenAddresses.steam}</InputValue>
                    <StyledLink href={`${EtherscanDomain()}/token/${tokenAddresses.steam}`}
                                target="_blank">
                        <FontAwesomeIcon className="chevron" icon={faExternalLinkAlt} />
                    </StyledLink>
                    </Flex>
                </InputGrp>

                <ModalActions layout='cols'>
                    <Button text="Close" variant="water_ice" onClick={handleDismiss} />
                    {/* <Button size='lg' text="Close" onClick={handleConfirm} />           */}
                </ModalActions>

            </Modal>
    </Content>
  )
}

export default NetworkInfoModal

const Content = styled.div `

`

const Flex = styled.div `
  display: flex;
  align-items: center;
`

const StyledLink = styled.a `
  display: block;
  width: 30px;
  opacity: .7;
  padding: 10px;

  svg { 
    color: #ffffff;
  }

  &:hover {
    opacity: 1;
  }
`

const Message = styled.div `
  text-align: center;
`

const InputGrp = styled.div`
    margin-bottom: 10px;
`
const InputLabel = styled.h4`
    margin-bottom: 5px;
`

const InputValue = styled.div`
    flex: 1;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: ${props => props.theme.borderRadius}px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px;

    ${MobileStyles(`
        font-size: 10px;
    `)};
`