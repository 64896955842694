import React from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins';

import Card from '../Card';
import CardContent from '../CardContent';
import Container from '../Container';

export interface ModalProps {
  style?: 'default' | 'glass',
  top?: string, // this is not passing correctly
  onDismiss?: () => void
}

const Modal: React.FC<ModalProps> = ({ 
  style = "glass", 
  top, 
  children 
}) => {
  return (
    <Container size="sm">
      <StyledModal className={style} top={top}>
        <Card variant={style}>
          <CardContent>{children}</CardContent>
        </Card>
      </StyledModal>
    </Container>
  );
};

interface StyledModalProps {
  top: string
}

// background-color: rgba(52, 53, 62, 0.92);
// background-color: rgba(47, 47, 54, 0.92);
const StyledModal = styled.div<StyledModalProps>`
  border-radius: 12px;  
  position: relative;
  box-shadow: 24px 24px 48px -24px ${(props) => props.theme.color.grey[900]};
  
  margin-top: ${props => props.top}px;

  &.default {
    background-color: red !important;
  }

  &.glass > div {
    background-color: rgba(56, 61, 66, 0.92);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);  
    -webkit-backdrop-filter: blur(5px);
  }

    .card {
        min-width: 500px;
    }

    ${MobileStyles(`
        margin-top: 0px;
        padding: 15px;
        .card {
            min-width: 250px;
        }
        .card > div {
            padding: 0px;
        }
    `)};
   
`;

export default Modal;
