import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import TokenSymbol from '../../../components/TokenSymbol';
import numeral from 'numeral';
import Spacer from '../../../components/Spacer';
import TextChanged from '../../../components/TextChanged';
import { AccountContext } from '../../../contexts/Account/AccountContext';
import { MobileStyles } from '../../../theme/Mixins'
import { WalletContext } from 'contexts/Account/WalletContext';
import BalanceValue from './BalanceValue';
import useiceWater from 'hooks/useIceWater';

export interface tokenBalancesProps {
    h2o: string,
    ice: string,
    steam: string
}

interface AccountBalancesProps {

}

const AccountBalances: React.FC<AccountBalancesProps> = ({}) => {
    const { balances, addTransaction, removeTransaction, refreshBalances } = useContext(AccountContext)
    const { address } = useContext(WalletContext)
    const iceWater = useiceWater()

    return (
    <BalancesWrap>
        <Label>Account Balances</Label>  

        <Balances>  
            <AccountBalance className='h2o'>
              <TokenSymbol size={37} symbol="H2O" />
              <Token>
                <TokenType>H2O</TokenType>
                  { balances.h2o ? (
                    <BalanceValue amount={balances.h2o} color='h2o' />
                  ) : (
                    <Value>--</Value>
                  )}
              </Token>
            </AccountBalance>

            <AccountBalance className='ice'>       
                <TokenSymbol size={37} symbol="ICE" />
                <Token>
                    <TokenType>ICE</TokenType>
                    <Value>
                    { balances.ice ? (
                        <BalanceValue amount={balances.ice} color='ice' />
                    ) : (
                        <Value>--</Value>
                    )}
                    </Value>
                </Token>
            </AccountBalance>

            <AccountBalance className='steam'>
                
                <TokenSymbol size={37} symbol="STEAM" />
                <Token>
                    <TokenType>STM</TokenType>
                    <Value>
                    { balances.steam ? (
                        <BalanceValue amount={balances.steam} color='steam' />
                    ) : (
                        <Value>--</Value>
                    )}
                    </Value>
                </Token>
            </AccountBalance>     
        </Balances>
        <Spacer size="sm" />
        </BalancesWrap>      
  )
}

export default AccountBalances

const BalancesWrap = styled.div`
    width: 100%;
    max-width: 625px;    
    margin-top: 0px;

    @media (max-width: 768px) {
        width: 90%;
    };
    ${MobileStyles(`
        width: 90%;
    `)};
`;

const DividendsWrap = styled.div `
    label {
        display: block;
        font-size: 13px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: center;
        display: none;
    }
`

const Dividends = styled.div `
    display: flex;
    align-items: center;
    width: 100%;
`

const DividendCenter = styled.div `
    width: 5px;
`

const Dividend = styled.div `
    flex: 1;
    display: flex;
    align-items: center;
    button {
        height: 40px;
        font-weight: normal;
    }
`

const Label = styled.div `
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
    opacity: .8;
    ${MobileStyles(`
        display: none;
    `)};
`

const Balances = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
const Token = styled.div `
    margin-left: 10px;
    position: relative;
    ${MobileStyles(`
        margin-left: 0px;
    `)};
`

const TokenType = styled.div `
    font-size: 14px;
    ${MobileStyles(`
        font-size: 12px;
    `)};
`

const Value = styled.div `
    margin-right: 10px;
    font-size: 20px;
    ${MobileStyles(`
        font-size: 18px;
    `)};
`

const AccountBalance = styled.div `
    display:flex;
    width: 32%;
    align-items: center;
    border-bottom: 4px solid;
    padding: 10px;
    margin: 0px 2.5px;

    span {
        vertical-align: middle;
    }

    &.h2o {
        border-color: ${(props) => props.theme.color.h2o};
    }
    &.ice {
        border-color: ${(props) => props.theme.color.ice};
    }
    &.steam {
        border-color: ${(props) => props.theme.color.steam};
    }
    ${MobileStyles(`
        img {
            width: 20px !important;
            height: 20px !important;
            display: none;
        }
    `)};
`