import config from "config"

//export function BrowserHasWeb3(): boolean {
export const BrowserHasWeb3 = () => {
    if ((window as any).ethereum) {
        return true
    } else if ((window as any).web3) {
        return true
    }
    return false
}

export const EtherscanDomain = () => {
    if ( config.chainId == 1 ) {
        return 'https://etherscan.io'
    }
    if ( config.chainId == 4 ) {
        return 'https://rinkeby.etherscan.io'
    }
    if ( config.chainId === 5 ) {
        return 'https://goerli.etherscan.io'
    }
    if ( config.chainId == 10 ) {
        return 'https://optimistic.etherscan.io'
    }
    return 'https://etherscan.io'
}

export const TokensClassName = (from:string, to:string) => {
    return from.toLocaleLowerCase() + '_' + to.toLocaleLowerCase()
}

// https://stackoverflow.com/a/175787/377205
export const isNumeric = (val:any) => {
    return /^-?\d+$/.test(val);
}