import React, { useContext, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import TokenSymbol from 'components/TokenSymbol';
import { MobileStyles } from 'theme/Mixins'
import { Desktop, Mobile } from "components/Responsive";
import Button from 'components/Button';
import { DateTime } from "luxon";
import numeral from 'numeral';
import useiceWater from 'hooks/useIceWater';
import { WalletContext } from 'contexts/Account/WalletContext';
import { EtherscanDomain } from 'utils/utils';
import StyledLink from 'components/Link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faChevronUp, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Web3 from 'web3';
import useInterval from 'hooks/useInterval';
import { AccountContext } from 'contexts/Account/AccountContext';
import useModal from 'hooks/useModal';
import SimpleModal from 'components/SimpleModal';
import { useAlert } from 'react-alert';
import { TxAlert } from 'components/Alert';

interface Props {
    cubeId: number
}

const CubeIssued: React.FC<Props> = ({
    cubeId
}) => {
    const iceWater = useiceWater()
    const { address } = useContext(WalletContext)
    const { refreshCubes, addTransaction, 
        removeTransaction } = useContext(AccountContext)
        
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)


    const [amount, setAmount] = useState<string | null>(); 
    const [redeemDate, setRedeemDate] = useState<number | null>(null); 
    const [daysRemaning, setDaysRemaning] = useState<number | null>(null); 
    const [redeemer, setRedeemer] = useState<string | null>(); 
    const [recipient, setRecipient] = useState<string | null>(); 
    const [rewardAmount, setRewardAmount] = useState<string | null>(); 
    const [rewardNumber, setRewardNumber] = useState<number>(); 

    const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

    const alert = useAlert()

    const [refreshInterval, setRefreshInterval] = useState<number>(10000); 

    useInterval(() => {
        fetchCubeData()
    }, refreshInterval ? refreshInterval : null)

    useEffect(() => {
        fetchCubeData()
    }, [])
    
    const fetchCubeData = async () => {
        setAmount(
            Web3.utils.fromWei(
                await iceWater.contracts.CUBE.methods.getAmount(cubeId).call()
            )
        )

        const date = await iceWater.contracts.CUBE.methods.getEndTime(cubeId).call()
        
        var endTime = parseInt(date)
        // must have millis
        if ( date.length === 10 ) {
            endTime *= 1000
        }
        setRedeemDate(endTime)

        var remaining = 1 + Math.floor(
            DateTime.fromMillis(endTime)
                 .diffNow('days').days
        )
        if ( remaining < 0 ) {
            remaining = 0
        }
        setDaysRemaning(remaining)

        var timeRemaining = DateTime.fromMillis(endTime).diffNow().milliseconds
        timeRemaining = timeRemaining <= 0 ? 0 : timeRemaining
        setTimeRemaining(timeRemaining)

        setRedeemer(
            await iceWater.contracts.CUBE.methods.getRedeemer(cubeId).call()
        )

        setRecipient(
            await iceWater.contracts.CUBE.methods.ownerOf(cubeId).call()
        )

        const r = Web3.utils.fromWei(
            await iceWater.contracts.CTR.methods
                .previewRewardsFromCube(cubeId).call()
        ) 
        setRewardNumber(parseFloat(r))
        setRewardAmount(r)
    }

    const Redeem = () => {
        setLoading(true)
        var txHash:string
        iceWater.contracts.CTR.methods
            .redeemIceCube(cubeId)
            .send({ from: address })
            .on('transactionHash', (hash:any) => {
                txHash = hash
                onTransactionHash(hash)
            })
            .on('error', function(err:any) {
                console.error("error", err)  
                showErrorCode(txHash)   
            })
            .then((receipt:any) => {
                const hash = receipt.transactionHash
                removeTransaction(hash)
                alert.show(<TxAlert 
                    message={`Success! Redeemed Cube`} 
                    hash={hash}/>)
                refreshCubes()
                return receipt
            })
    }

    async function onTransactionHash(hash:string) {
        setLoading(false)
        var summary = `Redeeming CUBE ${cubeId}`
        //const amount = Web3.utils.fromWei(amount)

        addTransaction({
            cubeId: cubeId,
            from: address,
            summary: summary,
            transactionHash: hash,
            fromToken: 'CUBE',
            toToken: 'ICE',
            fromAmount: amount,
            toAmount: amount,
            timestamp: DateTime.now().toSeconds(),
            status: 'pending',
            transactionType: 'redeem_cube'
        }) 
    }

    const showErrorCode = async (txHash:string) => {
        setLoading(false)
        if ( !txHash ) return
        const code = await iceWater.getRevertReason(txHash)
        removeTransaction(txHash)
        if ( code.includes('CannotredeemanactiveIceCube') ) {
            presentActiveErrorModal()
        } else if ( code.includes('IceCubealreadyredeemed') ) {
            presentAlreadyRedeemedModal()
        } else {
            presentErrorModal()
        }
    }

    const [presentErrorModal] = useModal(
        <SimpleModal title='Transaction Reverted' top='300'>
            The transaction was reverted because of an unkown error.
        </SimpleModal>
    )

    const [presentActiveErrorModal] = useModal(
        <SimpleModal title='Transaction Reverted' top='300'>
            You cannot redeem an active CUBE.
        </SimpleModal>
    )

    const [presentAlreadyRedeemedModal] = useModal(
        <SimpleModal title='Transaction Reverted' top='300'>
            The CUBE has already been redeemed.
        </SimpleModal>
    )

    const toggleShowInfo = () => {
        setShowInfo(!showInfo)
    }

    const RedeemButton = useMemo(() => {
        return (
            <Button text="Redeem"
                    variant='water_ice' 
                    size='sm' 
                    loading={loading}
                    onClick={() => {Redeem()}} />
        )
    }, [])

    return (
        <Content className={ showInfo ? 'open' : ''}>
            <Col>
                <Row className='id' onClick={toggleShowInfo}>
                    { showInfo ? (
                        <FontAwesomeIcon icon={faChevronDown} />
                        ) : (
                        <FontAwesomeIcon icon={faChevronRight}/>
                    )}
                    <TokenSymbol symbol='CUBE' size={28} /> 
                    <div>{ numeral(amount).format('0,0.0')} ICE</div>
                </Row>

                <Row onClick={toggleShowInfo}>
                    { redeemDate !== null ? (
                        <>
                        { DateTime.fromMillis(redeemDate).toFormat('MM/dd/yy') } 
                        </>  
                    ) : (
                        <>--</>
                    )}
                </Row>
                
                <Row onClick={toggleShowInfo}>
                    { daysRemaning !== null ? (
                        <>
                        { daysRemaning === 1 ? (
                            <>1 Day</>
                        ) : (
                            <>{ daysRemaning } Days </>
                        )}
                        </>  
                    ) : (
                        <>--</>
                    )}
                </Row>

                <Row className='redeem'>
                    { timeRemaining <= 0 ? (
                        <Button text="Redeem"
                            variant='water_ice' 
                            size='sm' 
                            loading={loading}
                            onClick={() => {Redeem()}} />
                    ) :(
                        <Button text="Redeem"
                            variant='disabled' 
                            size='sm' />
                        // <>--</>
                    )}
                </Row>
            </Col>
            { showInfo &&
                <Info>
                    <div className='col'>
                        <div className='label'>Cube ID</div>
                        <div className='value'>{ cubeId } </div>
                    </div>
                    <div className='col'>
                        <div className='label'>Size</div>
                        <div className='value'>{ numeral(amount).format('0,0.0000')} ICE</div>
                    </div>
                    <div className='col'>
                        <div className='label'>Reward</div>
                        { rewardNumber < 0.0001 &&
                            <div className='value'>
                                { numeral(rewardAmount).format('0,0.00000')} ICE
                            </div>
                        }
                        { rewardNumber > 0.0001 && rewardNumber < 0.001 &&
                            <div className='value'>
                                { numeral(rewardAmount).format('0,0.0000')} ICE
                            </div>
                        }
                        { rewardNumber > 0.001 &&
                            <div className='value'>
                                { numeral(rewardAmount).format('0,0.000')} ICE
                            </div>
                        }
                    </div>
                    <div className='col'>
                        <div className='label'>Issued To</div>
                        <div className='value'>{ recipient }</div>
                    </div>
                    <div className='col'>
                        <div className='label'>Maturity Date</div>
                        <div className='value'>
                        {DateTime.fromSeconds(redeemDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) } 
                        </div>
                    </div>
                </Info>
            }
        </Content>  
    )
    // return (
    //     <Content className={ showInfo ? 'open' : ''}>
    //         <Col>
    //             <Row className='id' onClick={toggleShowInfo}>
    //                 { showInfo ? (
    //                     <FontAwesomeIcon icon={faChevronDown} />
    //                     ) : (
    //                     <FontAwesomeIcon icon={faChevronRight}/>
    //                 )}
    //                 <TokenSymbol symbol='CUBE' size={28} /> 

    //                 <div className='idValue'>
    //                     <div>Size</div>
    //                     <div>{ numeral(Web3.utils.fromWei( cube.data.amount)).format('0,0.0')} ICE</div>
    //                 </div>
    //             </Row>

    //             <Row onClick={toggleShowInfo} className={timeRemaining <= 0 ? 'mature' : ''}>
    //                 { DateTime.fromMillis(endTime).toFormat('MM/dd/yy') } 
    //             </Row>

    //             <Row onClick={toggleShowInfo} className={timeRemaining <= 0 ? 'mature' : ''}>
    //                 <>{ daysRemaining } Days</> 
    //             </Row>

                // <Row className='redeem'>
                //     { timeRemaining <= 0 ? (
                //         <Button text="Redeem"
                //             variant='water_ice' 
                //             size='sm' 
                //             loading={loading}
                //             onClick={() => {Redeem()}} />
                //     ) :(
                //         // <Button text="Redeem"
                //         //     variant='disabled' 
                //         //     size='sm' />
                //         <>--</>
                //     )}
    //             </Row>
    //         </Col>
    //         { showInfo &&
    //             <Info>
    //                 <div className='col'>
    //                     <div className='label'>Cube ID</div>
    //                     <div className='value'>{ cube.data.tokenId } </div>
    //                 </div>
    //                 <div className='col'>
    //                     <div className='label'>Amount</div>
    //                     <div className='value'>{ numeral(Web3.utils.fromWei(cube.data.amount)).format('0,0.0000') } ICE</div>
    //                 </div>
    //                 <div className='col'>
    //                     <div className='label'>Issued To</div>
    //                     <div className='value'>{ cube.data.recipient } </div>
    //                 </div>
    //                 <div className='col'>
    //                     <div className='label'>Reedem Date</div>
    //                     <div className='value'>
    //                         {DateTime.fromSeconds(parseInt(cube.data.endTime)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }    
    //                     </div>
    //                 </div>
    //             </Info>
    //         }
    //     </Content>  
    // )
}

export default CubeIssued

const Content = styled.div`
    &.open {
        margin-bottom: 10px;
    }
 
    border-top: 1px solid rgba(255, 255, 255, 0.15);

    ${MobileStyles(`

    `)};

    .id {
        svg {
            opacity: .5;
            margin: 0px 10px;
            font-size: 12px;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
        } 
    }
    .idValue {
        display: inline-block;
        > div:first-child {
            opacity: .4;
            font-size: 90%;
        }
    }
`;


const Col = styled.div `
    display: flex;
    flex-direction: row;
    align-items: stretch;
    align-content: center;
    padding: 10px 0px;
    cursor: pointer;

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        img { 
            margin-right: 10px;
        }
        
    }
    > div:nth-child(1) {
        flex: 1;
        text-align: left;
        justify-content: left !important;
    }
    > div:nth-child(2) {
        width: 150px;
    }
    > div:nth-child(3) {
        width: 150px;
    }
    > div:nth-child(4) {
        width: 100px;
    }

    ${MobileStyles(`
        > div:nth-child(2), > div:nth-child(3) {
            display: none;
        }
        > div:nth-child(4) {
            padding-right: 10px;
        }
    `)};
`

const Row = styled.div `
    &.mature {
        opacity: .5;
    }
`

const Info = styled.div `  
    padding: 10px 10px 10px 50px;

    .col {
        display: flex;
        padding: 5px 0px;
    }

    .label {
        width: 125px;
        text-align: left;
    }
    .value {
        flex: 1;
        opacity: .5;
        text-align: right;
    }
    ${MobileStyles(`
        padding-left: 30px;
        .value {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    `)};
`   