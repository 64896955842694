import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'
import TokenSymbol from 'components/TokenSymbol';
import numeral from 'numeral';
import { AuctionProps } from './Auction';


interface Props {
    amount: number | null,
    active: boolean,
    auction: AuctionProps
}

const AuctionPayout: React.FC<Props> = ({
    amount,
    active,
    auction
}) => {
    return (
        <Content>
            <TokenSymbol size={37} symbol="H2O" />
            <div className='value'>
                { active && auction.type === 'positive' &&
                    <div>
                        Payout
                    </div>
                }

                { active && auction.type === 'negative' &&
                    <div>
                        Burn Amount
                    </div>
                }

                { !active && amount > 0 &&
                    <div>
                        Auction Limit
                    </div>
                }

                { !active && amount < 0 &&
                    <div>
                        Mint Payout
                    </div>
                }

                <div>
                    { amount == null ? (
                        <>--</>
                    ) : (
                        <>{numeral(Math.abs(amount)).format('0,0.00')} H2O</>
                    )}   
                </div>
            </div>
        </Content> 
    )
}

export default AuctionPayout

const Content = styled.div `
    display: flex;
    align-items: center;
    font-size: 20px;

    .value {
        margin-left: 10px;
        > div:first-child {
            font-size: 14px;
            opacity: .5;
        }
    }
`