// @ts-nocheck
import React, { useCallback, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import Spacer from '../../components/Spacer';
import useiceWater from '../../hooks/useIceWater';
import { WalletContext } from 'contexts/Account/WalletContext';
import MiddleEllipsis from "react-middle-ellipsis";
import Transaction from "./components/Transaction";
import AccountTransactions from './components/AccountTransactions';

const Transactions: React.FC = () => {
    const { address, connected } = useContext(WalletContext)  

    return (
        
    <Page>
        { connected ? (
        <>
            <Header>
                <h2>My Transactions</h2>
                <Address>
                    <MiddleEllipsis>
                        <span>{ address }</span>
                    </MiddleEllipsis>
                </Address>
            </Header>

            <Spacer size="lg" />

            <Content>
                <AccountTransactions />               
            </Content>
        </>
        ) : (
        <>
            <Spacer size="lg" />
            <h2>Please Connect Your Wallet</h2>
            <Spacer size="lg" />
        </>
        )}        
    </Page>
  );
};

export default Transactions;


const Content = styled.div`
    width: 100%;
    max-width: 600px;  
`;

const Header = styled.div `
    h2 {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`

const Address = styled.div `
    max-width: 300px; 
    margin: 0px auto;
    text-align: center;
    opacity: .5;
`



