import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import StyledLink from 'components/Link'
import { EtherscanDomain } from 'utils/utils';

interface Props {
    tx: any,
    className?: string,
    subClassName?: string,
    icon: React.ReactNode,
    label: string,
    value?: React.ReactNode | null,
    subValue?: React.ReactNode | null,
    children?: React.ReactNode,
    onOpen?: (open:boolean) => void
}

const Tx: React.FC<Props> = ({
    tx,
    className = '',
    subClassName = '',
    icon,
    label,
    value = null,
    subValue = null,
    children,
    onOpen
}) => {
    const [open, setOpen] = useState<boolean>(false)

    const toggleOpen = () => {
        const value = !open
        setOpen(value)
        if ( onOpen ) {
            onOpen(value)
        }
    }

    return (
        <Content className={open ? `${className} open` : className} >
            <Header onClick={toggleOpen}>
                <div className={`txIcon none`}>
                    <div className='faWrap'>
                        { icon }
                    </div>
                </div>
                
                <div className='txTypeDate'>
                    <div className='txType'>
                        { label }
                    </div>
                </div>

                <div className='txFlex'> </div>

                <div className='txChange'>

                    { value &&
                        <div className='txGain'>
                            { value }
                        </div>    
                    }

                    { subValue &&
                        <div className={`txLoss ${subClassName}`}>
                            { subValue }
                        </div>    
                    }

                    { value == null && subValue == null &&
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    }
                </div>
            </Header>

            { children && 
            <Details className='txDetails'>
                { children }
            </Details>
            }
        </Content> 
    )    
}

export default Tx

const Content = styled.div`
    .txDetails {
        display: none;
    }

    &.open {
        .txDetails {
            display: block;
        }
    }

    &.h2o {
        .txGain {
            color: ${props => props.theme.color.h2o};
        }
    }
    &.ice {
        .txGain {
            color: ${props => props.theme.color.ice};
        }
    }
    &.steam {
        .txGain {
            color: ${props => props.theme.color.steam};
        }
    }

    .txLoss {
        &.h2o {
            color: ${props => props.theme.color.h2o};
        }
        &.ice {
            color: ${props => props.theme.color.ice};
        }
        &.steam {
            color: ${props => props.theme.color.steam};
        }
    }

    ${MobileStyles(`

    `)};
`;


const Header = styled.div `
    cursor: pointer;
    display: flex;
    align-items: center;

    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);

    &:hover {
        
    }

    .txCube {
        margin: 0px 5px;
    }

    .txIcon {
        padding-right: 10px;
        .faWrap {
            width: 36px;
            height: 36px;
            background: red;
            background: #595c61;
            border-radius: 50%;
            text-align: center;
        }
        svg {
            border-radius: 50%;
            padding: 10px;
        }
        &.none {
            svg {
                //
            }
        }
    }

    .txTypeDate {
        width: 150px;
        font-weight: bold;
    }

    .txStatus {
        font-size: 12px;
        text-transform: uppercase;
    }

    .txFlex {
        flex: 1;
    }

    .txChange {
        text-align: right;
        font-weight: bold;
    }

    .txGain {
        font-weight: bold;
        filter: brightness(1.2);
    }

    .txDate {
        opacity: .4;
        font-size: 90%;
    }

    .txLoss {
        font-size: 80%;
    }

    .txFrom {

    }
`

const Details = styled.div `
    padding: 10px 15px 25px 60px; 
    ${MobileStyles(`
        padding-left: 15px;
    `)};

`