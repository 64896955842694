import { IceWaterContext } from 'contexts/IceWaterProvider/IceWaterProvider';
import { useContext } from 'react';


const useIceWater = () => {
  const { iceWater } = useContext(IceWaterContext);
  return iceWater;
};

export default useIceWater;
