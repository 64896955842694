import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import useModal from '../../../hooks/useModal';
import Button from '../../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AccountContext } from '../../../contexts/Account/AccountContext';
import { signOut } from "firebase/auth";
import { auth } from '../../../firebase';
import DisconnectModal from '../../DisconnectModal';
import { WalletContext } from 'contexts/Account/WalletContext';


interface AccountButtonProps {
  size?: any
}

const AccountButton: React.FC<AccountButtonProps> = ({ size="sm" }) => {
  const { connect, disconnect } = useContext(WalletContext)
  const { accountUser } = useContext(AccountContext)

  const [onPresentDisconnectModal, onDismissDisconnectModal] = useModal(
    <DisconnectModal 
      onConfirm={() => {
        signOut(auth).then(() => {
            disconnect()
        }).catch((error) => {
            // An error happened.
        });
        onDismissDisconnectModal()
      }}
      onDismiss={() => {
  
      }}
    />
  )

  const connectWallet = async (): Promise<void> => {
    connect()
  }

  const onSignOut = () => {
    onPresentDisconnectModal()
  }

  return (
    <StyledAccountButton>
          {!accountUser ? (
          <Button
            onClick={() => connectWallet()}           
            size={size}
            text="Connect Wallet"
            variant="water_ice_steam"
          />
        ) : (      
          <Button
            onClick={() => onSignOut()}           
            size={size}
            variant="empty">
              <FontAwesomeIcon icon={faUser} />
          </Button>
        )}         
    </StyledAccountButton>
  )
}

const StyledAccountButton = styled.div`

`

export default AccountButton