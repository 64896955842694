import React, { useCallback } from 'react';
import styled from 'styled-components';
import StyledLink from '../../components/Link';

interface SideMenuItemProps {
    title?: string,
    to?: string,
    href?: string,
    key?: string,
    activeClass?: string,
    icon?: React.ReactNode,
    children?: React.ReactNode,
    closeOnClick?: boolean,
    onClick?: () => void,
}
  
export const SideMenuItem: React.FC<SideMenuItemProps> = ({
    title = '',
    to,
    href,
    key = '',
    activeClass = 'active',
    icon,
    children,
    closeOnClick = false,
    onClick
  }) => {  

    // TODO: This solution is ghetto.. for some reason setIsOpen(false) is not working
    const closeMenu = () => {
        // setIsOpen(false)
        let element: HTMLElement = document.getElementsByClassName("bm-overlay")[0] as HTMLElement;
        element.click();
    }

    const onMenuItemClick = () => {
        if ( closeOnClick ) {
            closeMenu()
        }

        if ( onClick ) {
            onClick()
        }
    }

    /*
    const handleOnClick = useCallback(() => {
        onClick()
    }, [onClick]);
    */

    if ( to ) {
        return (
            <MenuItem>
                <StyledLink to={to} 
                    onClick={onMenuItemClick}
                    key={key} 
                    activeClassName={activeClass} >
                { icon }
                { title }
                { children }
                </StyledLink>
            </MenuItem>
        )
    }

    if ( href ) {
        return (
            <MenuItem>
                <StyledLink href={href} 
                    onClick={onMenuItemClick}
                    activeClassName={activeClass} 
                    key={key}>
                { icon }
                { title }
                { children }
                </StyledLink>
            </MenuItem>
        )
    }

    return (
        <MenuItem onClick={onClick}>
            { icon }
            { title }
            { children }
        </MenuItem>
    )
}

const MenuItem = styled.div `
    cursor: pointer;
    svg {
        margin-right: 20px;
        opacity: .9;
    }
`
