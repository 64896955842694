import React from 'react'
import styled from 'styled-components'
import { MobileStyles } from 'theme/Mixins'

import Nav from './components/Nav'

const Footer: React.FC = () => (
  <StyledFooter>
    <StyledFooterInner>
      <Nav />
    </StyledFooterInner>
  </StyledFooter>
)

const StyledFooter = styled.footer`

`
const StyledFooterInner = styled.div`
    display: flex;
    align-items: center;
    height: ${props => props.theme.botBarSize}px;
    max-width: ${props => props.theme.siteWidth}px;
    width: 100%;

    ${MobileStyles(`
        height: auto;
        padding-bottom: 30px;
    `)};
`

export default Footer