import { Configuration } from '../config';
import { Contract, ethers } from 'ethers';
import ERC20 from './ERC20';
import  Web3 from 'web3';
import numeral from 'numeral';
import { Contract as Web3Contract } from "web3-eth-contract";
import { isNumeric } from 'utils/utils';


var _ = require('lodash');


interface HoldersPayload {
    id: string,
    h2o: number,
    ice: number,
    stm: number,
    value: number,
    timestamp: number
}

/**
 * An API module of Ice Water contracts.
 * All contract-interacting domain logic should be defined in here.
 */
export class IceWater {
    account: string;  
    provider: any;
    signer?: ethers.Signer;
    config: Configuration;
    externalTokens: { [name: string]: ERC20 };
    multiplier: number;
    decimalsCount: number;
    tokenSymbol: string;
    tokenSymbols: string[];
    initialized: boolean;
    defaultProvider: string;
    contractEvents: { [name: string]: any };
    tokenJsons: { [name: string]: any };
    contractsData: { [name: string]: any };
    contracts: { [name: string]: Web3Contract };
    addressSymbols: { [name: string]: string};
    web3: any;

    constructor(cfg: Configuration ) {
        const { chainId } = cfg;
        const tokenSymbol: string = "H2O";
        const multiplier: number = 1000000000000000000;
        const decimalsCount: number = 18; 

        //this.contracts = {};
        this.contracts = {};
        this.contractsData = {};
        this.contractEvents = {};
        this.addressSymbols = {};
        this.tokenJsons = {};

        this.defaultProvider = cfg.defaultProvider

        this.tokenSymbols = ['CTR', 'H2O', 'ICE', 'CUBE'] // STEAM

        // gets the network that's active in metamask
        // const networkID = await web3.eth.net.getId()
        this.tokenJsons['CTR'] = require("ice-water/abis/Controller.json")
        this.tokenJsons['H2O'] = require("ice-water/abis/H2OToken.json")
        this.tokenJsons['ICE'] = require("ice-water/abis/IceToken.json")
        this.tokenJsons['STEAM'] = require("ice-water/abis/SteamToken.json")
        this.tokenJsons['CUBE'] = require("ice-water/abis/IceCube.json")

        this.config = cfg;
        this.tokenSymbol = tokenSymbol;
        this.multiplier = multiplier;
        this.decimalsCount = decimalsCount;
        this.initialized = false;  

        // This call is now happening in the IceWaterProvider
        //this.setWeb3(null)
    }

    async setWeb3(web3: any=null) {    
        if ( web3 ) {
            this.web3 = web3;
        } else if (window.ethereum) {
            this.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        }
        else if (this.web3) {
            this.web3 = new Web3(this.web3.currentProvider);
        }
        else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
            return false;
        }
        
        for (const symbol of this.tokenSymbols) {
            this.loadContract(symbol)
        }

        this.initialized = true;  
    }

    /**
     * @param string load the contract of the given symbol.
     */
    loadContract(symbol:string) {
        this.contractsData[symbol] = this.tokenJsons[symbol]
            .networks[this.config.chainId];

        if (this.contractsData[symbol]) {
            this.contracts[symbol] = new this.web3.eth.Contract(
                this.tokenJsons[symbol].abi, 
                this.contractsData[symbol].address
            );
            this.addressSymbols[this.contractsData[symbol].address] = symbol
        } else {
            console.log(symbol + ' contract not deployed to this network.');
            return;
        }
    }

    /**
     * @param provider From an unlocked wallet. (e.g. Metamask)
     * @param account An address of unlocked wallet account.
     */
    unlockWallet(account: string) {
        this.account = account;
    }

    /**
     * Get the config
     */
    getConfig(): Configuration {
        return this.config
    }

    getAccount() {
        return this.account
    }
    /**
     * Returns token symbol by address
     * @param address string
     * @return string | null
     */
    getSymbolByAddress = (address:string) => {
        if ( _.has(this.addressSymbols, address) ) {
            return this.addressSymbols[address]
        }
        return null
    }

    /**
     * Returns contract json by address
     * @param address string
     * @return object | null
     */
    getTokenJsonByAddress = (address:string) => {
        const symbol = this.getSymbolByAddress(address)
        if ( symbol ) {
            return this.tokenJsons[symbol]
        }
        return null
    }

    /**
     * Returns contract abi object by address
     * @param address string
     * @return object | null
     */
    getAbiByAddress = (address:string) => {
        const symbol = this.getSymbolByAddress(address)
        if ( symbol ) {
            return this.tokenJsons[symbol].abi
        }
        return null
    }

    /**
     * Returns all events in an abi
     * @param abi object
     * @return object
     */
    getContractEvents = (symbol:string) => {
        const abi = this.tokenJsons[symbol].abi
        var events:{[name: string]: any } = {}
        for ( const item in abi ) {
            if ( abi[item].type === 'event' ) {
                const sig = this.web3.eth.abi.encodeEventSignature(abi[item])
                events[sig] = abi[item]
            }
        }
        return events
    }

    /**
     * Returns the addres for the given contract
     * @param contract string
     * @return string
     */
    async getContractAddress(contract:string): Promise<string> {
        if ( _.has(this.contractsData, contract) ) {
            return this.contractsData[contract].address
        }
        return ''
    }


    /**
     * Expands the amount of the 
     * @param hex Bid price
     * @return number
     */
    expandNumber(amount: number) {
        return BigInt(amount) * BigInt(this.multiplier);      
    }

/** --------------------------------------------
 *  Controller
 */
    /**
     * Binds a function to the control contracts Swap event listener.
     * @param function
     */
    controlEvents() {
        return this.contracts.CTR.methods.events;
    }

    /**
     * Binds a function to the control contracts Swap event listener.
     * @param function
     */
    registerClaimH2OFromIceEventHandler(onClaimHandler: any ) : void {
        if ( !this.account ) {
            return
        }
        this.contracts.ICE.events.ClaimReward(
        { fromBlock: 'latest',
            filter: {account: this.account} 
        },
        onClaimHandler);   
    }

    registerClaimH2OFromSteamEventHandler(onClaimHandler: any ) : void {
        return
        /*
        if ( !this.account ) {
            return
        }
        this.contracts.STEAM.events.ClaimReward(
        { fromBlock: 'latest',
            filter: {account: this.account} 
        },
        onClaimHandler);   
        */
    }

    /**
     * Binds a function to the control contracts Swap event listener.
     * @param function
     */
    registerSwapEventHandler(onSwapHandler: any ) : void {
      if ( !this.account ) {
        return
      }
      this.contracts.CTR.events.Swap(
        { fromBlock: 'latest',
          filter: {account: this.account} 
        },
        onSwapHandler);         
    }

    // https://web3js.readthedocs.io/en/v1.2.6/web3-eth.html#getpendingtransactions
    async getPendingTransactions(): Promise<any> {
        this.web3.eth.getPendingTransactions().then(console.log);
    }


    /**
    * Get an array of account addresses that own H2O on the contract
    */
    async getRevertReason(txHash:string): Promise<any> {
        const tx:any = await this.web3.eth.getTransaction(txHash)
        try {
            var result:any = await this.web3.eth.call(tx)
        } catch (error:any) {
            // TODO: This is ghetto, there must be a better way.
            //console.log(error)
            var msg = error.message
            msg = msg.replace(/ /g,'') // remove spaces
            msg = msg.replace(/(\r\n|\n|\r)/gm, '') // remove line breaks
            const reason = msg.substring(
                msg.indexOf(':') + 1,
                msg.indexOf('{'),
            )
            return reason
        }
        return null
    }

    getContract(type:string): any {
      return this.contracts[type]
    }



  /**
   * Preview amount for swaping tokens will return the estimated amount for the toToken
   * @param fromToken "H2O" | "ICE" | "STEAM"
   * @param toToken   "H2O" | "ICE" | "STEAM"
   * @param amount    unexpanded value
   */
   async previewSwapTokens(fromToken:string, toToken:string, amount:number): Promise<string> {
      //const expandedAmount:BigInt = this.expandNumber(amount);
      const weiAmount = Web3.utils.toWei(amount.toString())
      // H2O ➔ ICE 
      if (fromToken === "H2O" && 
            toToken === "ICE") {
          return Web3.utils.fromWei(
              await this.contracts.CTR.methods.previewSwapH2OForICE(weiAmount).call()
          )

      // H2O ➔ STEAM
      } else if (fromToken === "H2O" && 
                   toToken === "STEAM") {
          return Web3.utils.fromWei(
              await this.contracts.CTR.methods.previewSwapH2OForSTM(weiAmount).call()
          )

      // ICE ➔ H2O
      } else if (fromToken === "ICE" 
                && toToken === "H2O") {
          return Web3.utils.fromWei(
              await this.contracts.CTR.methods.previewSwapICEForH2O(weiAmount).call()
          )

      // STEAM ➔ H2O
      } else if (fromToken === "STEAM" 
                && toToken === "H2O") {
        return Web3.utils.fromWei(
            await this.contracts.CTR.methods.previewSwapSTMForH2O(weiAmount).call()
        )
      }
      return 
  }


    /**
    * Get an array of account addresses that own H2O on the contract
    */
    async getAllAccountsWithH2O(): Promise<string[]> {
        var addrsSet = new Set<string>();
        await this.contracts.H2O.getPastEvents(
            'Transfer',
            {fromBlock: 0, toBlock: 'latest'},
            (error:any, events:any) => {
                if ( error ) {
                console.log(error)
                }
                if (!error) {
                    events.forEach(function (e:any) {
                    addrsSet.add(e.returnValues.from);
                    addrsSet.add(e.returnValues.to);
                    }); 
                }
            }
        )
        return Array.from(addrsSet);
    }

    /**
    * Get account Transactions from the protocol
    */
    async getAccountTransactions(address:string): Promise<any> {
        var Txs: any[] = []

        // TODO: possibly use topics and filters instead of the decodedLogs trick
        // See CubesIssued for example.
        
        await this.contracts.H2O.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        if ( e.returnValues.to.toLowerCase() 
                            == address.toLowerCase()) {
                            Txs.push(e)
                        }
                    })
                }
            }
        )

        // ICE Transfer
        await this.contracts.ICE.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        if ( e.returnValues.to.toLowerCase() 
                            == address.toLowerCase()) {
                            Txs.push(e)
                        }
                    })
                }
            }
        )

        // CUBE Transfer (Swap)
        await this.contracts.CUBE.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        if ( e.returnValues.to.toLowerCase() == address.toLowerCase()) {
                            Txs.push(e)
                        }
                    })
                }
            }
        )

        // Claim H2O Ice dividend
        await this.contracts.ICE.getPastEvents(
            'Reward',
            {fromBlock: 0, toBlock: 'latest'},
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        if ( e.returnValues.account.toLowerCase() == address.toLowerCase()) {
                            Txs.push(e)
                        }
                    })
                }
            }
        )

        // Claim H2O Steam dividend
        /*
        await this.contracts.STEAM.getPastEvents(
            'Reward',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        if ( e.returnValues.account.toLowerCase()
                            == address.toLowerCase()) {
                            Txs.push(e)
                        }
                    })
                }
            }
        )
        */


        const uniqTxs =  _.uniqBy(Txs, 'transactionHash')

        var transactions = []
        for ( const Tx of uniqTxs ) {
            transactions.push(await this.getTransactionReceipt(Tx['transactionHash']))
        }

        return _.orderBy(transactions, ['timestamp'], ['desc'])
    }

    /**
     * Returns a human readable code for the transaction type.
     * @param decodedLogs object
     * @return object
     */
    _getTransactionType = (decodedLogs:any) => {

        // Swap
        if ( this._matchLogEvents(decodedLogs, [
            ['Swap', 'CTR']
        ]) ) {
            return 'Swap'
        }

        // Mint CUBE
        if ( this._matchLogEvents(decodedLogs, [
            ['MintCube', 'CTR'],
            //['Transfer', 'CUBE']
        ]) ) {
            return 'mint_cube'
        }

        // Redeem Cube
        if ( this._matchLogEvents(decodedLogs, [
            ['RedeemCube', 'CTR']
        ]) ) {
            return 'redeem_cube'
        }

        // Claim CUBE Reward
        if ( this._matchLogEvents(decodedLogs, [
            //['Transfer', 'H2O'],
            ['ClaimRewardsFromCube', 'CTR']
        ]) ) {
            return 'claim_cube_rewards'
        }

        // Claimabled Reward: Melt
        if ( this._matchLogEvents(decodedLogs, [
            ['ClaimReward', 'ICE'],
            //['Transfer', 'H2O']
        ]) ) {
            return 'reward_dividends'
        }

        // Claimable Reward: Condenstaion
        if ( this._matchLogEvents(decodedLogs, [
            ['ClaimReward', 'STEAM'],
            //['Transfer', 'H2O']
        ]) ) {
            return 'reward_dividends'
        }

        // Transfer
        if ( this._matchLogEvents(decodedLogs, [
            ['Transfer', 'H2O']
        ]) ) {
            return 'transfer_h2o'
        }

        // Transfer
        if ( this._matchLogEvents(decodedLogs, [
            ['Transfer', 'ICE']
        ]) ) {
            return 'transfer_ice'
        }

        return null
    }

    /**
     * Returns matches for any set of event/tokens from a decodedLog
     * @param decodedLog object
     * @param EventTokenSets [event, token]
     * @return boolean
     */
    _matchLogEvents = (decodedLogs:any, EventTokenSets:string[][]) => {
        const numSets = EventTokenSets.length
        var numMatches = 0
        var eventMatches: string[] = []
        var eventMatchesFound: string[] = []

        // Create two arrays:
        // 1. All of the passed event strings
        // 2. All of the found event strings
        for ( const log of decodedLogs ) {
            for ( const eventTokenSet of EventTokenSets ) {
                eventMatches.push(eventTokenSet[0])
                if ( this._matchLogEventsToSymbol(
                    log, 
                    eventTokenSet[0], 
                    eventTokenSet[1])) {
                        eventMatchesFound.push(eventTokenSet[0])
                }
            } 
        }

        // Make sure each array only contains each event string once.
        var eventMatchesSet = eventMatches.filter((item, index) => eventMatches.indexOf(item) === index);
        var eventMatchesFoundSet = eventMatchesFound.filter((item, index) => eventMatchesFound.indexOf(item) === index);

        // Compare the arrays, if they contain the same events 
        // then there is a match
        let areArraysEqual = (array1: any[], array2: any[]) => {
            if (array1.length !== array2.length) {
                return false;
            }
            let sortedArray1 = array1.sort();
            let sortedArray2 = array2.sort();
            return sortedArray1.every((element, index) => element === sortedArray2[index]);
        };

        if ( areArraysEqual(eventMatchesSet, eventMatchesFoundSet) ) {
            return true
        }
        return false

        /*
        // This was the old way of doing it. Keeping legacy around for a bit.
        const numSets = EventTokenSets.length
        var numMatches = 0
        for ( const log of decodedLogs ) {
            for ( const eventTokenSet of EventTokenSets ) {
                if ( this._matchLogEventsToSymbol(
                    log, 
                    eventTokenSet[0], 
                    eventTokenSet[1])) {
                    numMatches += 1
                }
            } 
        }
        //if ( numSets === numMatches ) {
        if ( numMatches >= numSets ) {
            return true
        }
        return false
        */
    }

    /**
     * Returns true/false if the decoded log matches the event name & symbol
     * @param decodedLog object
     * @param eventName string
     * @param symbol string
     * @return object
     */
    _matchLogEventsToSymbol = (
        decodedLog:any, eventName:string, symbol:string) => {
        if ( decodedLog.event.toLowerCase() == eventName.toLowerCase() 
            && decodedLog.token.toLowerCase() == symbol.toLowerCase()) {
            return true
        }
        return false
    }

    /**
     * Returns the decoded log by eventName and symbol
     * @param decodedLog object
     * @param eventName string
     * @param symbol string
     * @return object
     */
    _getLogByEventSymbol = (
        decodedLog:any, eventName:string, symbol:string) => {
        for ( const log of decodedLog ) {
            if ( this._matchLogEventsToSymbol(log, eventName, symbol) ) {
                return log
            }
        }
        return null
    }

    async getDecodedLogs(hash:string): Promise<any> {
        const tx = await this.web3.eth.getTransactionReceipt(hash)
            .then((res:any) => {     
                return res     
            })
        
        var decodedLogs = []
        for (const log of tx.logs) {
            const symbol = this.getSymbolByAddress(log.address)

            if ( !symbol ) {
                console.error(`${symbol} not found`)
                continue
            }
            
            const events = this.getContractEvents(symbol)
    
            if ( log.topics.length === 0 ) {
                continue
            }

            // The first topic must match one of the event signatures
            if ( !_.has(events, log.topics[0]) ) continue
    
            const txEvent = events[log.topics[0]]
            var d = this.web3.eth.abi.decodeLog(
                txEvent.inputs,
                log.data,
                log.topics.slice(1)
            )
            d.address = log.address;
    
            var data:{[name: string]: any} = {}
            for (let key in d) {
                if ( !isNumeric(key) && key !== '__length__' )  {
                    data[key] = d[key]
                }
            }
            
            decodedLogs.push({
                event: txEvent.name,
                token: symbol,
                data: data
            })
        }
        return decodedLogs
    }

    async getTransactionReceipt(hash:string): Promise<any> {
        const tx = await this.web3.eth.getTransactionReceipt(hash)
            .then((res:any) => {     
                return res     
            })
        
        var decodedLogs = []
        for (const log of tx.logs) {
            const symbol = this.getSymbolByAddress(log.address)

            if ( !symbol ) {
                console.error(`${symbol} not found`)
                continue
            }
            
            const events = this.getContractEvents(symbol)
    
            if ( log.topics.length === 0 ) {
                continue
            }

            // The first topic must match one of the event signatures
            if ( !_.has(events, log.topics[0]) ) continue
    
            const txEvent = events[log.topics[0]]
            var d = this.web3.eth.abi.decodeLog(
                txEvent.inputs,
                log.data,
                log.topics.slice(1)
            )
            d.address = log.address;
    
            var data:{[name: string]: any} = {}
            for (let key in d) {
                if ( !isNumeric(key) && key !== '__length__' )  {
                    data[key] = d[key]
                }
            }
            
            decodedLogs.push({
                event: txEvent.name,
                token: symbol,
                data: data
            })
        }

        const txType = this._getTransactionType(decodedLogs)

        // if ( !txType ) {
        //     console.log("no txType")
        //     console.log(tx)
        //     console.log(decodedLogs)
        // }

        const block =  await this.web3.eth.getBlock(tx.blockNumber)
            .then((res:any) => {     
                return res     
            })
 
        var txData:any = {
            by: tx.from,
            logs: decodedLogs,
            receipt: tx,
            transactionHash: tx.transactionHash,
            transactionType: txType,
            timestamp: block.timestamp,
            //block: block,
        }

        const account = tx.from.toLowerCase()
        
        // Swap
        if ( txType === 'Swap' ) {
            var transfers = decodedLogs.filter(obj => {
                return obj.event === "Transfer"
            })
        
            const from = transfers.find(obj => {
                return obj.data.from.toLowerCase() == account
            })
        
            const to = transfers.find(obj => {
                return obj.data.to.toLowerCase() == account
            })

            const fromToken = this.getSymbolByAddress(from.data.address)
            const toToken = this.getSymbolByAddress(to.data.address)
            const fromAmount = Web3.utils.fromWei(from.data.value)
            const toAmount = Web3.utils.fromWei(to.data.value)
            const fromAmountSummary = numeral(fromAmount).format('0,0.00')
            const toAmountSummary = numeral(toAmount).format('0,0.00')

            txData = {
                ...txData,
                summary: `Swap ${fromAmountSummary} ${fromToken} for ${toAmountSummary} ${toToken}.`,
                from: from.data.address,
                to: to.data.address,
                fromToken: fromToken,
                toToken: toToken,
                fromAmount: fromAmount,
                toAmount: toAmount,
            }
        }

        // transfer_h2o
        if ( txType == 'transfer_h2o' ) {           
            var xfer = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'H2O')


            const fromToken = this.getSymbolByAddress(xfer.data.address)
            const toToken = this.getSymbolByAddress(xfer.data.address)

            const amount = Web3.utils.fromWei(xfer.data.value)
            const amountSummary = numeral(amount).format('0,0.00')

            txData = {
                ...txData,
                summary: `${amountSummary} H2O Transfer`,
                from: xfer.data.from,
                to: xfer.data.to,
                fromToken: fromToken,
                toToken: fromToken,
                amount: amount
            }
        }

        // transfer_ice
        if ( txType == 'transfer_ice' ) {           
            var xfer = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'ICE')


            const fromToken = this.getSymbolByAddress(xfer.data.address)
            const toToken = this.getSymbolByAddress(xfer.data.address)

            const amount = Web3.utils.fromWei(xfer.data.value)
            const amountSummary = numeral(amount).format('0,0.00')

            txData = {
                ...txData,
                summary: `${amountSummary} ICE Transfer`,
                from: xfer.data.from,
                to: xfer.data.to,
                fromToken: fromToken,
                toToken: fromToken,
                amount: amount
            }
        }

        if ( txType == 'mint_cube' ) {
            var from = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'ICE')

            var to = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'CUBE')

            const fromToken = this.getSymbolByAddress(from.data.address)
            const toToken = this.getSymbolByAddress(to.data.address)

            const fromAmount = Web3.utils.fromWei(from.data.value)
            const fromAmountSummary = numeral(fromAmount).format('0,0.00')

            //const ventValue = numeral(log.value).format('0,0.00')
            txData = {
                ...txData,
                summary: `Minted ${fromAmountSummary} CUBES from ICE`,
                from: from.data.address,
                to: to.data.address,
                fromToken: fromToken,
                toToken: toToken,
                fromAmount: fromAmount,
                toAmount: fromAmount,
                cubeId: parseInt(to.data.tokenId)
            }

            const mint = decodedLogs.find((i:any) => {return i.event === 'MintCube'})
            if ( mint ) {
                txData = {
                    ...txData,
                    data: mint.data
                }
            }
        }

        if ( txType == 'redeem_cube' ) {           
            var from = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'ICE')

            var to = this._getLogByEventSymbol(
                decodedLogs, 'RedeemCube', 'CTR')

            const fromToken = this.getSymbolByAddress(from.data.address)
            const toToken = this.getSymbolByAddress(to.data.address)

            const fromAmount = Web3.utils.fromWei(from.data.value)
            const fromAmountSummary = numeral(fromAmount).format('0,0.00')

            const cubeId = parseInt(to.data.tokenId)
            const owner = await this.contracts.CUBE.methods.ownerOf(cubeId).call()
            const date = await this.contracts.CUBE.methods.getEndTime(cubeId).call()
            
            var endTime = parseInt(date)
            if ( date.length === 10 ) {
                endTime *= 1000
            }
            
            txData = {
                ...txData,
                summary: `Redeemed CUBE ${to.data.tokenId}`,
                from: from.data.address,
                to: to.data.address,
                fromToken: fromToken,
                toToken: toToken,
                fromAmount: fromAmount,
                toAmount: fromAmount,
                data: {
                    ...to.data,
                    recipient: owner,
                    endTime: endTime
                },
                cubeId: cubeId
            }
        }

        if ( txType == 'claim_cube_rewards' ) {
            var from = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'H2O')

            var to = this._getLogByEventSymbol(
                decodedLogs, 'ClaimRewardsFromCube', 'CTR')

            const fromToken = this.getSymbolByAddress(from.data.address)
            const toToken = this.getSymbolByAddress(to.data.address)

            const fromAmount = Web3.utils.fromWei(from.data.value)
            const fromAmountSummary = numeral(fromAmount).format('0,0.00')

            const reedemer = await this.contracts.CUBE.methods.getRedeemer(parseInt(to.data.tokenId)).call()

            //const ventValue = numeral(log.value).format('0,0.00')
            txData = {
                ...txData,
                summary: `Claimed ~${fromAmountSummary} H2O from CUBE rewards`,
                from: from.data.address,
                to: to.data.address,
                fromToken: fromToken,
                toToken: toToken,
                fromAmount: fromAmount,
                toAmount: fromAmount,
                data: {
                    ...to.data, 
                    owner: reedemer
                },
                cubeId: parseInt(to.data.tokenId)
            }
        }

        // reward_divedends
        if ( txType == 'reward_dividends' ) {
            var iceReward = this._getLogByEventSymbol(
                decodedLogs, 'ClaimReward', 'ICE')

            var steamReward = this._getLogByEventSymbol(
                decodedLogs, 'ClaimReward', 'STEAM')

            var transferReward = this._getLogByEventSymbol(
                decodedLogs, 'Transfer', 'H2O')

            if ( iceReward ) {
                txData = {
                    ...txData,
                    iceAmount: Web3.utils.fromWei(iceReward.data.amount)
                }
            }
            if ( steamReward ) {
                txData = {
                    ...txData,
                    steamAmount: Web3.utils.fromWei(steamReward.data.amount)
                }
            }
            if ( transferReward ) {
                txData = {
                    ...txData,
                    to: transferReward.data.to,
                    amount: Web3.utils.fromWei(transferReward.data.value),
                    summary: `Claimed H2O dividends.`
                }
            }
        } 

        return txData 
    }

    /**
     * Returns all H2O token holders and their balances.
     * 
     * @return array
     */
    async getHolders(): Promise<HoldersPayload[]> {
        const addrs = await this.getAllAccountsWithH2O()

        //const contracts = this.contracts
        const contracts = this.contracts
        const icePrice = await this.getIcePrice()
        const steamPrice = await this.getSteamPrice()


        let balances:HoldersPayload[] = []
        await Promise.all(addrs.map(async (addr) => {            
            // H2O
            const h2o:string = await Web3.utils.fromWei(
                await contracts.H2O.methods.balanceOf(addr).call()
            )

            // ICE
            const ice:string = await Web3.utils.fromWei(
                await contracts.ICE.methods.balanceOf(addr).call()
            )

            // STEAM
            // const steam:string = await Web3.utils.fromWei(
            //     await contracts.STEAM.methods.balanceOf(addr).call()
            // )

            const steam = '0'

            // Total Value = H2O + ICE*ICE_PRICE + STM*STM_PRICE
            let totalValue = parseFloat(h2o) + 
                            (parseFloat(ice) * parseFloat(icePrice)) + 
                            (parseFloat(steam) * parseFloat(steamPrice))

            balances.push({
                id: addr.toLowerCase(),
                h2o: parseFloat(h2o),
                ice: parseFloat(ice),
                stm: 0, // parseFloat(steam),
                value: parseFloat(totalValue.toFixed(3)),
                timestamp: Date.now()
            })
        }));

        balances = balances.sort((a, b) => (a.value < b.value) ? 1 : -1)
        return balances
    }

    /**
     * Get the H2O supply
     * @return Promise
     */
    async getH2OTotalSupply(): Promise<string> {
        return Web3.utils.fromWei(
            await this.contracts.H2O.methods
                .totalSupply().call())
    }

    /**
     * Get the Target H2O supply
     * @return Promise
     */
    async getTargetH2OSupply(): Promise<string> {
        return Web3.utils.fromWei(
            await this.contracts.CTR.methods
                .dTargetH2OSupply().call())
    } 

  /**
   * Claim H20
   */
  async claimH2OFromIce(): Promise<void> {
        return await this.contracts.CTR.methods.claimRewards(true, false)
            .send({ from: this.account })
            .on('transactionHash', (hash:any) => {
                console.log("claim melt")
            }).on('receipt', (receipt:any) => { 
                console.log("Reciept")
                console.log(receipt)
                return receipt
            }).then((receipt:any) => {
                return receipt
            })
  }
  
  /**
   * Claim H20
   */
    async claimH2OFromSteam(): Promise<void> {
        return await this.contracts.CTR.methods.claimRewards(false, true)
        .send({ from: this.account })
        .on('transactionHash', (hash:any) => {
            console.log("claim condensation")
        }).on('receipt', (receipt:any) => { 
                console.log("Reciept")
                console.log(receipt)
                return receipt
        }).then((receipt:any) => {
                return receipt
        })
  }

    /**
     * Claim the users Rewards: both H2O from ICE & H2O from STEAM
     * @return Promise
     */
    async claimH2ORewards(): Promise<void> {
        return await this.contracts.CTR.methods.claimRewards(true)
            .send({ from: this.account })
            .on('transactionHash', (hash:any) => {
                console.log("claim rewards")
            })
            .on('receipt', (receipt:any) => { 
                console.log("Reciept")
                console.log(receipt)
                return receipt
            }).then((receipt:any) => {
                return receipt
            })
    }


    /**
     * Get the annual Condensation Rate
     * @return number
     */
// TODO: Remove
    async getAnnualCondensationRate(): Promise<number> {
        return 0
        // const rate = Web3.utils.fromWei(
        //     await this.contracts.CTR.methods
        //         .annualCondensationRate().call()
        // )
        // return parseFloat(rate)
    }

    /**
     * Get the Condensation Rate
     * @return number
     */
// TODO: Remove
     async getCubeRewardRate(): Promise<number> {
        return 0
        // const rate = Web3.utils.fromWei(
        //     await this.contracts.CTR.methods
        //         .getCubeRewardRate().call()
        // )
        // return parseFloat(rate)
    }

    /**
     * Get the Ice Price
     */
    async getIcePrice(): Promise<string> {
        return Web3.utils.fromWei(
            await this.contracts.CTR.methods.getICEPrice().call()
        )
    }

    /**
     * Get the Steam Price
     */
    async getSteamPrice(): Promise<string> {
        return Web3.utils.fromWei(
            await this.contracts.CTR.methods.getSTMPrice().call()
        )
    }

    /**
     * Get the Steam Price from Sushi
     */
    async getSteamPriceSushi(): Promise<string> {
        const addr = '0xc7e768f5718583dd6d677cbd5956ff46b12e94b7'
        
        const h2o:string = await Web3.utils.fromWei(
            await this.contracts.H2O.methods.balanceOf(addr).call()
        )

        // const steam:string = await Web3.utils.fromWei(
        //     await this.contracts.STEAM.methods.balanceOf(addr).call()
        // )

        const steam = '0'
        return (parseFloat(h2o)/parseFloat(steam)).toString()
    }

  /**
   * Get the Sushi WIN price
   */
    async getWinPrice(): Promise<string> {
        const addr = '0xcd677e10de587b9aedcef9048849a31145dd2a23'      
        const h2o:string = await Web3.utils.fromWei(
            await this.contracts.H2O.methods.balanceOf(addr).call()
        )
        const win:string = await Web3.utils.fromWei(
            await this.contracts.WIN.methods.balanceOf(addr).call()
        )
        return (parseFloat(win)/parseFloat(h2o)).toString()
    }

    /**
     * Get the Target ICE Price
     * @return string
     */
    async getTargetIcePrice(): Promise<string> {
        return "0"
        return Web3.utils.fromWei(
            await this.contracts.CTR.methods.dTargetICEPrice().call()
        )
    }

/** --------------------------------------------
 *  Utils
 */

  /**
   * Formats a number string using the 
   * @param numberString a number as a string 
   * @param precision the number of decimal places desired
   */
  formatNumber(numberString:string, format:'0,0.00'): string {
    return numeral(numberString).format(format)
  }

  /**
   * @param numberString a number as a string 
   * @param precision the number of decimal places desired
   */
  numberStringPrecision(numberString:string, precision:number=4): string {
    if ( precision == 0 ) return numberString
    return parseFloat(numberString).toFixed(precision)
  }

  get isUnlocked(): boolean {
    return !!this.account;
  }

  get isInitialized(): boolean {
    return this.initialized
  }
  

}
