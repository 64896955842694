import React, { useContext, useState, useMemo, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'
import Button from 'components/Button';
import useiceWater from 'hooks/useIceWater';
import { WalletContext } from 'contexts/Account/WalletContext';
import Web3 from 'web3';
import CubeIssued from './CubeIssued';
import { AccountContext } from 'contexts/Account/AccountContext';
  
interface Props {

}

const CubesIssued: React.FC<any> = forwardRef(({

}, refreshRef) => {
    const iceWater = useiceWater()
    const { cubesIssued } = useContext(AccountContext)
    const { address } = useContext(WalletContext)
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [cubes, setCubes] = useState<any>(null);
    
    useImperativeHandle(refreshRef, () => ({
        refresh
    }));

    const refresh = () => {

    }

    /*
    useEffect(() => {
        if ( !iceWater ) return  
        if ( iceWater.initialized && address ) {
            fetchCubesIssued()
        }
    }, [iceWater?.isInitialized, address])

    useImperativeHandle(refreshRef, () => ({
        refresh
    }));

    const refresh = () => {
        fetchCubesIssued()
    }

    const fetchCubesIssued = async () => {
        if ( !address ) return

        const balance = await iceWater.contracts.CUBE.methods
            .getCreatorBalanceOf(address).call()
    
        var cubeIds = []
        for (let i = 0; i < balance; i++) {
            const id = await iceWater.contracts.CUBE.methods
                .getCreatorCubeIdByIndex(address, i).call()
            const isRedeemed = await iceWater.contracts.CUBE.methods
                .isRedeemed(id).call()
            if ( !isRedeemed ) {
                cubeIds[i] = parseInt(id)
            }
        }
        setCubes(cubeIds)
    }
    */
    
    /*
    const fetchCubesIssued = async () => {
        if ( !address ) return

        var Txs: any[] = []           
        
        var RedeemedCubeIds: string[] = []
        await iceWater.contracts.CTR.getPastEvents(
            'RedeemCube',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    topics: [Web3.utils.keccak256("RedeemCube(address,uint256,uint256)")],
                    redeemer: address
                }
            },
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        RedeemedCubeIds.push(e.returnValues.tokenId)
                    })
                }
            }
        )

        // INFO: a topic is a hash string the name of the event followed by it's
        // input types which can be found in the contract ABI.
        // You can then filter by one of the event attribute names as seen below
        await iceWater.contracts.CTR.getPastEvents(
            'MintCube',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    topics: [Web3.utils.keccak256("MintCube(address,address,uint256,uint256,uint256)")],
                    senderAccount: address
                }
            },
            (error:any, events:any) => {
                if ( error ) {
                    console.error(error)
                }
                if ( !error ) {
                    events.forEach(function (e:any) {
                        if (!RedeemedCubeIds.includes(e.returnValues.tokenId)) {
                            Txs.push(e)
                        }
                    })
                }
            }
        )
       
        var transactions = []
        for ( const Tx of Txs ) {
            var t = await iceWater.getTransactionReceipt(Tx['transactionHash'])
            var mint = t.logs.find((i:any) => {return i.event === 'MintCube'})
            transactions.push({...t, data: mint.data})
        }
    
        transactions = transactions.sort((a:any, b:any) => {
            var aEndTime = parseInt(a.data.endTime)
            if ( a.data.endTime.length === 10 ) {
                aEndTime *= 1000
            }
            var bEndTime = parseInt(b.data.endTime)
            if ( b.data.endTime.length === 10 ) {
                bEndTime *= 1000
            }
            return ( aEndTime > bEndTime ) ? 1 : -1
        })
      
        setCubes(transactions)
    }
    */

    const onCubeRedeemed = () => {
        //fetchCubesIssued()
    }

    return (
        <Content>
        { (cubesIssued && cubesIssued.length > 0) ? (
            <>
                <Header>
                    <Row>
                        Cubes Issued
                    </Row>
                    <Row>
                        Redeem Date
                    </Row>
                    <Row>
                        Time Remaining
                    </Row>
                    <Row>
                        &nbsp;
                    </Row>
                </Header>

                <Trans>
                    {cubesIssued.map((cube:any, index:number) => (
                        <CubeIssued 
                            key={`cube-${index}`} 
                            cubeId={cube}  />
                    ))}
                </Trans>
            </>
                
        ) : (
            <>

            </>
        )}
        </Content>
    );
});

export default CubesIssued;

const Content = styled.div `
    ${MobileStyles(`

    `)};
`

const Header = styled.div `
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;

    padding: 10px 0px;

    > div {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    > div:nth-child(1) {
        flex: 1;
        justify-content: left;
        padding-left: 20px;
    }
    > div:nth-child(2) {
        width: 150px;
    }
    > div:nth-child(3) {
        width: 150px;
    }
    > div:nth-child(4) {
        width: 100px;
    }

    ${MobileStyles(`
        > div:nth-child(2) {
            display: none;
        }
        > div:nth-child(3) {
            display: none;
        }
    `)};
`

const Row = styled.div `

`

const Trans = styled.div `

`
