import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import config from 'config';
import { EtherscanDomain } from 'utils/utils';


interface AlertTemplateProps {
    message: any,
    options: any,
    style: any,
    close: any
}
const AlertTemplate: React.FC<AlertTemplateProps> = ({ message, options, style, close }) => {
    return (
    <AlertBox>
        <ContentWrapper>
            <Left>
                <FontAwesomeIcon icon={faCheckCircle}/>
            </Left>
            
            <Message>{message}</Message>

            <Right>
                <FontAwesomeIcon icon={faTimesCircle} onClick={close}/>
            </Right>
        </ContentWrapper>
    </AlertBox>
    )
}

export default AlertTemplate;

interface TxAlertProps {
    message: string,
    hash: string
}
export const TxAlert: React.FC<TxAlertProps> = ({ message, hash }) => {
    return (
    <TxAlertWrap>
        <a target='_blank' href={`${EtherscanDomain()}/tx/${hash}`}>{message}</a>
    </TxAlertWrap>
    )
}

const TxAlertWrap = styled.span `
    a {
        color: #fff;
        text-decoration: none;
    }
`

const AlertBox = styled.div `
    background-color: #333333;
    color: #fff;
    border-radius: 10px;
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    width: 500px;
    box-sizing: border-box;
    z-index: 25;
    font-size: 20px;
    position: relative;
`
  
const ContentWrapper = styled.div`
    padding: 10px 60px 10px 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`

const Left = styled.div `
    flex: 0 0 50px;
    svg {
        color: ${(props) => props.theme.color.h2o};
    }
`

const Right = styled.div `
    flex: 0 0 50px;
    svg {
        opacity: .5;
        &:hover {
            opacity: 1;
        }
    }
`

const Message = styled.div `
    flex: 1 1 auto;
`

  