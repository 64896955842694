import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import IceWater from '../../ice-water';
import config from '../../config';
import { WalletContext } from 'contexts/Account/WalletContext';
import { css } from 'highcharts';
import Web3 from 'web3';
import useInterval from 'hooks/useInterval';

export interface MarketStatsDataProps {
    priceTargetIce: string,
    priceIce: string,
    yieldTargetIce: string,
    yeildIce: string,
    cubeRewardRate: number,
    h2oTotalSupply: number,
    h2oTargetSupply: number
}

const MarketStatsInitialData = {
    priceTargetIce: '',
    priceIce: '',
    yieldTargetIce: '',
    yeildIce: '',
    cubeRewardRate: 0,
    h2oTotalSupply: 0,
    h2oTargetSupply: 0
}
  

export interface IceWaterContext {
    iceWater?: IceWater;
    marketStats: MarketStatsDataProps;
    refreshMarketStats: () => void;
}

export const IceWaterContext = createContext<IceWaterContext>({ 
    iceWater: null,
    marketStats: MarketStatsInitialData,
    refreshMarketStats: () => null,
});

export const IceWaterProvider: React.FC = ({ children }) => {
    const { address, provider, connected, web3 } = useContext(WalletContext)
    const [iceWater, setIceWater] = useState<IceWater>(); 
    const [marketStats, setMarketStats] = useState<MarketStatsDataProps>(MarketStatsInitialData);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        var ice = null
        if ( iceWater ) {    
            ice = iceWater 
        } else {
            ice = new IceWater(config)
            setIceWater(ice)
        }
        setupWeb3(ice)
        if ( address ) {
            ice.unlockWallet(address)
        }
    }, [address, web3]);

    const setupWeb3 = async (ice:any) => {
        await ice.setWeb3()
        setLoaded(true)
    }

    useEffect(() => {
        if (loaded) {
            fetchMarketStats().catch((err) =>
                console.error(`Failed to fetch market stats data: ${err.stack}`),
            )
        }
    }, [loaded])

    useInterval(() => {
        fetchMarketStats()
    }, loaded ? config.refreshInterval : null)
    
    const fetchMarketStats = async () => {
        const secondsInYear = 31536000

        // const meltRate = await iceWater.contracts.CTR.methods
        //         .meltRate().call()

        const meltRate = await iceWater.contracts.CTR.methods
                .annualMeltRate().call()
   
        const [
            icePrice, 
            targetIcePrice, 
            cubeRewardRate, 
            h2oTotalSupply, 
            h2oTargetSupply
        ] = await Promise.all([
                iceWater.getIcePrice(),
                iceWater.getTargetIcePrice(),
                iceWater.getCubeRewardRate(),
                iceWater.getH2OTotalSupply(),
                iceWater.getTargetH2OSupply()
            ])

        const yeildIce = (parseFloat(meltRate)) / (1e16 * parseFloat(icePrice))

        const yieldTargetIce = parseFloat(targetIcePrice) 
            ? (100/parseFloat(targetIcePrice)).toString() : '0'

        const stats = {
            priceTargetIce: targetIcePrice,
            priceIce: icePrice,
            yieldTargetIce: yieldTargetIce,
            yeildIce: yeildIce.toString(),
            cubeRewardRate: cubeRewardRate,
            h2oTotalSupply: parseFloat(h2oTotalSupply),
            h2oTargetSupply: parseFloat(h2oTargetSupply)
        }
        setMarketStats(stats)
    }

    const refreshMarketStats = useCallback(() => {
        fetchMarketStats()
    }, [marketStats, setMarketStats]);

    return <IceWaterContext.Provider value={{ 
        iceWater, 
        marketStats, 
        refreshMarketStats }}>
            {children}
        </IceWaterContext.Provider>;
};
