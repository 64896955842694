import React from 'react'
import styled from 'styled-components'
import { MobileStyles } from 'theme/Mixins'

interface ModalActionProps {
  children?: React.ReactNode,
  layout?: 'rows' | 'cols'
}

const ModalActions: React.FC<ModalActionProps> = ({ children, layout='cols' }) => {
  const l = React.Children.toArray(children).length
  return (
    <StyledModalActions className={layout}>
      {React.Children.map(children, (child, i) => (
        <>
          <StyledModalAction>
            {child}
          </StyledModalAction>
          {i < l - 1 && <StyledSpacer />}
        </>
      ))}
    </StyledModalActions>
  )
}

const StyledModalActions = styled.div`
  padding: 0 ${props => props.theme.spacing[4]}px;
  
  &.cols {
    align-items: center;
    background-color: ${props => props.theme.color.grey[100]}00;
    display: flex;
    height: 96px;
    margin: ${props => props.theme.spacing[4]}px ${props => -props.theme.spacing[4]}px ${props => -props.theme.spacing[4]}px;
  }

    ${MobileStyles(`
        &.cols {
            flex-direction: column-reverse;
            > div {
                width: 100%;
            }
            height: auto;
            margin-bottom: 0px;
        }
    `)};

  &.rows {
    display: block;
  }

  img + span {
    margin-left: 12px;
  }
`

const StyledModalAction = styled.div`
  flex: 1;
`

const StyledSpacer = styled.div`
  width: ${props => props.theme.spacing[4]}px;
  height: ${props => props.theme.spacing[2]}px;
`

export default ModalActions