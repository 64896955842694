import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Footer from '../Footer'
import Spacer from '../../components/Spacer';
import NavBar from '../NavBar';
import { MobileStyles } from 'theme/Mixins';

type PageProps = {
  className?: 'default' | 'dark' | 'light' | 'water' | 'ocean' | 'ice' | 'steam';
};

const Page: React.FC<PageProps> = ({ className = "default", children }) => {

return (
      <>
        <NavBar />
        <StyledPage id='page' className={className}>
            <StyledMain>
                {children}
            </StyledMain>
            <Footer />
        </StyledPage>
      </>
  )
}

const LogoW = styled.div `

`

  
// CSS Ocean
// https://codepen.io/tedmcdo/pen/PqxKXg
// linear-gradient(180deg,#34b291,#0a6997 50%,#432b7c)

// padding-top: 70px;
// padding-top: 140px;
// height: ${props => props.theme.topBarSize}px;
const StyledPage = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${props => props.theme.topBarSize + 50}px;

  ${MobileStyles(`
        padding-top: 75px;
  `)};

  &.fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
  }
  
  &.default {
    background: rgb(59,59,59);
    background: linear-gradient(180deg, rgba(47,53,56,1) 0%, rgba(16,16,16,1) 100%); 
  }
`

const StyledMain = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${props => props.theme.topBarSize}px - ${props => props.theme.botBarSize}px - ${props => props.theme.subTopBarSize}px);  
`

// padding-bottom: ${props => props.theme.spacing[5]}px;

export default Page