import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import TextChanged from '../../../components/TextChanged';
import { AccountContext } from '../../../contexts/Account/AccountContext';
import { MobileStyles } from '../../../theme/Mixins'
import { Desktop, Mobile } from "components/Responsive";


interface Props {
    amount: string,
    color: 'h2o' | 'ice' | 'steam'
}

const BalanceValue: React.FC<Props> = ({
    amount,
    color
}) => {  
    return (
        <Value>
            <Desktop>
                <TextChanged text={numeral(amount).format('0,0.00')} color={color} />
            </Desktop>
            <Mobile>
                { (parseFloat(amount) > 10000) ? (
                    <TextChanged text={numeral(amount).format('0.00a')} color={color} />
                ) : (
                    <TextChanged text={numeral(amount).format('0,0.00')} color={color} />
                )}
            </Mobile>
        </Value>
    )
}

export default BalanceValue

const Value = styled.div `
    margin-right: 10px;
    font-size: 20px;
    ${MobileStyles(`
        font-size: 18px;
    `)};
`