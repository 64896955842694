import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import useModal from '../../hooks/useModal';
import { MobileStyles } from 'theme/Mixins'
import { WalletContext } from '../../contexts/Account/WalletContext';
import Spacer from 'components/Spacer';
import AuctionForm from './AuctionForm';
import AuctionPayout from './AuctionPayout';
import useiceWater from 'hooks/useIceWater';
import H2OSupply from './H2OSupply';
import AuctionStats from './AuctionStats';
import SimpleModal from 'components/SimpleModal';
import { IceWaterContext } from 'contexts/IceWaterProvider';
import numeral from 'numeral';
import Web3 from 'web3';
import BigNumber from "bignumber.js";
import { useAlert } from 'react-alert';
import { TxAlert } from 'components/Alert';
import { DateTime } from 'luxon';
import { AccountContext } from 'contexts/Account/AccountContext';
import config from 'config';
import Card from 'components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';
import AccountButton from 'components/AccountButton';

type Props = {

}

export type AuctionProps = {
    active: boolean,
    type: 'positive' | 'negative' | null,
    h2o: number | null,
    eth: number | null,
    endTime: number | null,
    timeRemaining: any,
    leadingBidder: string | null,
    raiseLimit: number | null
}

const AuctionInitial:AuctionProps = {
    active: false,
    type: null,
    h2o: null,
    eth: null,
    endTime: null,
    timeRemaining: null,
    leadingBidder: null,
    raiseLimit: null
}

const Auction: React.FC<Props> = () => {
    const { address, isOnChain } = useContext(WalletContext)
    const { marketStats, refreshMarketStats } = useContext(IceWaterContext)
    const { addTransaction, removeTransaction } = useContext(AccountContext)
    const iceWater = useiceWater()
    const alert = useAlert()

    const [active, setActive] = useState<boolean | null>(null)
    const [endTime, setEndTime] = useState<number | null>(null)
    const [hasEnded, setHasEnded] = useState<boolean>(false)
    const [surplus, setSurplus] = useState<number | null>(null)
    const [isBalanced, setIsBalanced] = useState<boolean | null>(null)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [auction, setAuction] = useState<AuctionProps>(AuctionInitial)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if ( marketStats.h2oTargetSupply ) {
            fetchStats() // this will refersh every config.interval
        }
    }, [marketStats])

    useEffect(() => {
        if (iceWater?.isInitialized) {
            fetchStats()
        }
    }, [iceWater?.isInitialized]) 

    const fetchStats = async () => {
        const p = await iceWater.contracts.CTR.methods
            .isPositiveAuctionActive().call()

        const n = await iceWater.contracts.CTR.methods
            .isNegativeAuctionActive().call()

        const surplusAmt = marketStats.h2oTotalSupply - marketStats.h2oTargetSupply
        setSurplus(surplusAmt)
        setIsBalanced(surplusAmt === 0 ? true : false)
        var active = (p || n ) ? true : false

        if ( !active ) {
            setAuction(AuctionInitial)
            setActive(false)
            setLoaded(true)
            return
        }

        const auctionType = p ? 'positive' : 'negative'
        setActive(active)

        const startTime = parseInt(
            await iceWater.contracts.CTR.methods
                .iLastAuctionTime().call()
        )
        const endTime = DateTime.fromSeconds(startTime).plus({days: 30})
        setEndTime(endTime.toSeconds())
        
        var timeRemaining = endTime.diffNow(['days', 'hours', 'minutes']).toObject()
 
        const h2o = Web3.utils.fromWei(
            await iceWater.contracts.CTR.methods
                .dAuctionH2OAmount().call())

        const eth = Web3.utils.fromWei(
            await iceWater.contracts.CTR.methods
                .dLeadingBid().call())

        const bidder = await iceWater.contracts.CTR.methods
            .leadingBidder().call()

        var raiseLimit = surplusAmt + parseFloat(h2o) 
        if ( surplusAmt && auctionType == 'negative' ) {
            raiseLimit = surplusAmt + parseFloat(h2o) 
        }

        setAuction({
            active: active,
            type: auctionType,
            h2o: parseFloat(h2o),
            eth: parseFloat(eth),
            endTime: endTime.toSeconds(),
            timeRemaining: timeRemaining,
            leadingBidder: bidder,
            raiseLimit: raiseLimit            
        })

        setLoaded(true)
    }

    useEffect(() => {
        if ( endTime != null ) {
            const now = DateTime.now().toSeconds()
            setHasEnded((endTime < now) ? true : false)
        }
    }, [endTime])

    const submit = (data:any) => {
        if ( !address ) return
        setLoading(true)
        const amount = parseFloat(data.amount)
        const amountBN = new BigNumber(amount)
        const amountWei = Web3.utils.toWei(amountBN.toString())
        const amountSummary = numeral(amount).format('0,0.000')
        const h2oAmount = parseFloat(data.h2oAmount)
        const h2oAmountBN = new BigNumber(h2oAmount)
        const h2oAmountWei = Web3.utils.toWei(h2oAmountBN.toString())
        var h2oAmountSummary = numeral(h2oAmount).format('0,0.000')
        if ( auction.active ) {
            h2oAmountSummary = numeral(h2oAmount + auction.h2o).format('0,0.000')
        }
        const payoutSummary = numeral(surplus).format('0,0.000')
        var txHash:string, summary:string
 
        if ( active && auction.type === 'positive' ) {
            summary = `Mint H2O Auction bid. ${amountSummary} ETH.`
            iceWater.contracts.CTR.methods.makePositiveAuctionBid()
                .send({ 
                    from: address, 
                    value: amountWei })
                .on('transactionHash', (hash:any) => {
                    txHash = hash
                    console.log(hash)
                    onTransactionHash(hash, amount, summary, 'auction_pos_bid')
                })
                .on('error', function(err:any) {
                    showErrorCode(txHash)
                })
                .then((receipt:any) => {
                    onTransactionComplete(receipt.transactionHash, summary)
                    return receipt
                })
        }

        if ( active && auction.type === 'negative' ) {
            summary = `Auction to Burn H2O (${amountSummary} ETH → ${h2oAmountSummary} H2O)`
            iceWater.contracts.CTR.methods
                .makeNegativeAuctionBid(h2oAmountWei, amountWei)
                .send({ 
                    from: address
                })
                .on('transactionHash', (hash:any) => {
                    txHash = hash
                    console.log(hash)
                    onTransactionHash(hash, amount, summary, 'auction_neg_bid')
                })
                .on('error', function(err:any) {
                    showErrorCode(txHash)
                })
                .then((receipt:any) => {
                    onTransactionComplete(receipt.transactionHash, summary)
                    return receipt
                })
        }

        if ( !active && surplus < 0 ) {
            summary = `Mint H2O Auction started. ${payoutSummary} H2O for ${amountSummary} ETH.`
            iceWater.contracts.CTR.methods.initiatePositiveAuction()
                .send({ 
                    from: address, 
                    value: amountWei })
                .on('transactionHash', (hash:any) => {
                    txHash = hash
                    onTransactionHash(hash, amount, summary, 'auction_pos_start')
                })
                .on('error', function(err:any) {
                    showErrorCode(txHash)
                })
                .then((receipt:any) => {
                    onTransactionComplete(receipt.transactionHash, summary)
                    return receipt
                })
        }

        if ( !active && surplus > 0 ) {
            summary = `Burn H2O Auction started. ${h2oAmountSummary} H2O for ${amountSummary} ETH.`
            console.log(summary)
            iceWater.contracts.CTR.methods
                .initiateNegativeAuction(h2oAmountWei, amountWei)
                .send({ 
                    from: address
                })
                .on('transactionHash', (hash:any) => {
                    txHash = hash
                    onTransactionHash(hash, amount, summary, 'auction_neg_start')
                })
                .on('error', function(err:any) {
                    showErrorCode(txHash)
                })
                .then((receipt:any) => {
                    onTransactionComplete(receipt.transactionHash, summary)
                    return receipt
                })
        }
    }

    const end = () => {
        var txHash:string, summary: string
        if ( active && auction.type === 'positive' ) {
            summary = `Ended H2O auction.`
            iceWater.contracts.CTR.methods.terminatePositiveAuction()
                .send({ 
                    from: address })
                .on('transactionHash', (hash:any) => {
                    txHash = hash
                    onTransactionHash(hash, 0, summary, 'auction_pos_end')
                })
                .on('error', function(err:any) {
                    showErrorCode(txHash)
                })
                .then((receipt:any) => {
                    onTransactionComplete(receipt.transactionHash, summary)
                    return receipt
                })
        }

        if ( active && auction.type === 'negative' ) {
            summary = `Ended H2O auction.`
            iceWater.contracts.CTR.methods.terminateNegativeAuction()
                .send({ 
                    from: address })
                .on('transactionHash', (hash:any) => {
                    txHash = hash
                    onTransactionHash(hash, 0, summary, 'auction_neg_end')
                })
                .on('error', function(err:any) {
                    showErrorCode(txHash)
                })
                .then((receipt:any) => {
                    onTransactionComplete(receipt.transactionHash, summary) 
                    return receipt
                })
        }
    }

    async function onTransactionHash(hash:string, 
            amount:number, summary:string, type:string) {
        addTransaction({
            from: address,
            summary: summary,
            transactionHash: hash,
            fromToken: 'ETH',
            toToken: 'H2O',
            fromAmount: amount,
            toAmount: amount,
            timestamp: DateTime.now().toSeconds(),
            status: 'pending',
            transactionType: type
        }) 

        if ( formResetRef.current ) {
            formResetRef.current.resetForm()
        }
    }

    async function onTransactionComplete(hash:string, summary:string) {
        fetchStats()
        removeTransaction(hash)
        setLoading(false)
        alert.show(<TxAlert 
            message={summary} 
            hash={hash}/>)
        refreshMarketStats() 
        if ( statsResetRef.current ) {
            statsResetRef.current.refresh()
        }
    }

    // TODO: Need to fill out with the error codes 
    const showErrorCode = async (txHash:string) => {
        setLoading(false)
        if ( !txHash ) return
        const code = await iceWater.getRevertReason(txHash)
        console.error(code)
        removeTransaction(txHash)
        if ( code.includes('CannotredeemanactiveIceCube') ) {
            presentErrorModal()
        } else {
            presentErrorModal()
        }
    }

    const [presentErrorModal] = useModal(
        <SimpleModal title='Transaction Reverted' top='300'>
            The transaction was reverted because of an unkown error.
        </SimpleModal>
    )

    const [showAboutModal] = useModal(
        <SimpleModal title='About Auctions' top='0'>
            <p>
                Auctions are designed to keep H2O stable by increasing or decreasing the amount of H2O supply through auctioning ETH.
            </p>

            <h4>Burn H2O Auction</h4>
            <p>
                When the supply of H2O is higher than the target supply, the protocol will auction some of its ETH to burn H2O. In this case, making a bid will remove the amount of H2O being auctioned from the bidder's wallet until a lower ETH bid is made or the auction is ended. 
            </p>
            <p>
                When the auction ends, whichever address has the current lowest ETH bid will be given that amount of ETH from the protocol. 
            </p>

            <h4>Mint H2O Auction</h4>
            <p>
                When the supply of H2O is lower than the target supply, the protocol will auction newly minted H2O for ETH. The highest eth bid will acquire the newly minted H2O. When a bid is made, the ETH will be transferred to the IceWater protocol until a higher bid is made or the auction is ended. 
            </p>

            <p>
                Auctions can be started whenever there is a difference between target and actual supply, and will run for at least 30 days.  After 30 days, there is a 1 day grace period for anyone to provide a better bid and end the auction, and after that anyone can end the current auction.
            </p>
        </SimpleModal>
    )

    const statsResetRef:any = useRef()
    const formResetRef:any = useRef()

    return (
        <Page>
            <Content>

                { loaded ? ( 
                    <>
                    <div className='supply'>
                        <H2OSupply />       
                    </div>
                    <Spacer size="lg" />

                    {/* { !isBalanced && !active &&
                        <Head>
                            <div className='payoutWrapTop'>
                                <AuctionPayout amount={payout} active={active} />
                            </div>
                        </Head>
                    } */}

                    { isBalanced && !active ? (
                        <Card variant='glass'>
                            <div>
                                <FontAwesomeIcon icon={faBalanceScale}/>
                            </div>
                            <div>
                                <h2>H2O Supply Balanced</h2>
                                <div>There is no auction at this time.</div>
                            </div>
                        </Card>
                    ) : (
                        <>    
                        <Auctions className={active ? "active" : "inactive"}>
                            <AuctionForm active={active} 
                                hasEnded={hasEnded} 
                                onSubmit={submit}
                                onEnd={end}
                                ref={formResetRef}
                                surplus={surplus}
                                loading={loading}
                                auction={auction} />
                                
                            <AuctionStats 
                                active={active} 
                                surplus={surplus}
                                auction={auction}
                                ref={statsResetRef} />
    
                            { !active &&
                            <div className='payoutWrap'>
                                <AuctionPayout amount={surplus}
                                    auction={auction} 
                                    active={false} />
                            </div>
                            }
                        </Auctions>
    
                        { active && config.chainId === 5 &&
                        <div className='earlyEnd' onClick={end}>
                            End Auction Now - Testnet only
                        </div>
                        }
                        </>
                    ) }


                    { isBalanced && !active && 
                        <div className='about' onClick={showAboutModal}>
                            <div>
                                About Auctions
                            </div>
                        </div>
                    }
                    </>
                ) : (
                    <Loading>
                        { address ? (
                            <>Loading ...</>
                        ) : (
                            <Card variant='glass'>
                                <AccountButton size='md'>
                                    Connect Wallet
                                </AccountButton>
                            </Card>
                        )}
                    </Loading>
                )}

                <Spacer size="lg" />
            </Content>
        </Page>
    );
};

const Loading = styled.div `
    text-align: center;
`

const Head = styled.div `

`

const Content = styled.div`
    .card {
        width: 350px;
        display: flex;
        flex-direction: row;

        > div:first-child {
            width: 70px;
            svg {
                position: relative;
                top: 8px;
                font-size: 36px;
                color: ${props => props.theme.color.h2o};
            }
        }
        > div:last-child {
            flex: 1;
            div {
                margin-top: 2px;
                opacity: .5;
            }
        }
        h2 {
            margin: 0px;
            font-size: 20px;
        }
    }

    .supply {
        > div {
            margin: 0px auto;
        }
    }

    .payoutWrapTop {
        flex: 1;
        padding: ${(props) => props.theme.spacing[3]}px;
        color: ${(props) => props.theme.color.white};
        -webkit-border-top-right-radius: 15px;
        -webkit-border-top-left-radius: 15px;
        -moz-border-radius-topright: 15px;
        -moz-border-radius-topleft: 15px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        background-color: rgba(100, 100, 100, 0.1);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px); 
        -webkit-backdrop-filter: blur(5px); 
    }

    .payoutWrap {
        flex: 1;
        padding: ${(props) => props.theme.spacing[3]}px;
        color: ${(props) => props.theme.color.white};
        -webkit-border-bottom-right-radius: 15px;
        -webkit-border-bottom-left-radius: 15px;
        -moz-border-radius-bottomright: 15px;
        -moz-border-radius-bottomleft: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        background-color: rgba(100, 100, 100, 0.1);
        box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px); 
        -webkit-backdrop-filter: blur(5px); 
    }

    .about {
        display: flex;
        justify-content: center;
        margin-top: 55px;

        ${MobileStyles(`
            margin-top: 25px;
        `)};

        > div {
            padding-right: 10px;
            cursor: pointer;
            svg {
                opacity: .6;
            }
            &:hover {
                filter: brightness(1.5);
            }
        }
    }

    .earlyEnd {
        padding: 20px;
        cursor: pointer;
        text-align: center;
        &:hover {
            filter: brightness(1.5);
        }
    }
`;

const Auctions = styled.div `
    display: block; 
    max-width: 400px;
    margin: 0px auto;

    &.inactive {
        .stats {
            display: none;
        }
    }
    
    ${MobileStyles(`
        display: block;
        width: 90%;
    `)};
`


export default Auction;
