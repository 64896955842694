import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import TokenSymbol from 'components/TokenSymbol';
import MiddleEllipsis from "react-middle-ellipsis";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import StyledLink from 'components/Link'
import { EtherscanDomain, TokensClassName } from 'utils/utils';
import Tx from './Tx';
import TxInfo from './TxInfo';
import { WalletContext } from 'contexts/Account/WalletContext';
import { DateTime } from 'luxon';
import useiceWater from 'hooks/useIceWater';
import numeral, { _ } from 'numeral';
import Web3 from 'web3';

interface Props {
    tx: any
}

const TxSwap: React.FC<Props> = ({
    tx
}) => {
    const iceWater = useiceWater()
    const { address } = useContext(WalletContext)
    const [block, setBlock] = useState<any>(null) 

    const onHandleOpen = async (open:boolean) => {
        if ( open ) {
            setBlock(
                await iceWater.web3.eth.getBlock(tx.blockNumber)
            )
        }
    }  

    const data = tx.returnValues
    const amountFrom = Web3.utils.fromWei(data.amountFrom)
    const amountTo = Web3.utils.fromWei(data.amountTo)
    const tokenFrom = iceWater.getSymbolByAddress(data.tokenFrom)
    const tokenTo = iceWater.getSymbolByAddress(data.tokenTo)
    const className = TokensClassName(tokenFrom, tokenTo)

    var niceAmountFrom: string,
        niceAmountTo: string, 
        summary: string

    niceAmountTo = numeral(amountTo).format('0,0.00')
    niceAmountFrom = numeral(amountFrom).format('0,0.00')

    summary = `Swap ${niceAmountFrom} ${tokenFrom} for ${niceAmountTo} ${tokenTo}`
 
    return (
        <Tx tx={tx}
            label={`Swap`}
            icon={<FontAwesomeIcon icon={faExchangeAlt} className={`txIcon ${className}_bg`} />}
            onOpen={onHandleOpen}
            value={`+ ${niceAmountTo}`}
            subValue={`- ${niceAmountFrom}`}
            className={tokenTo.toLowerCase()}
            subClassName={tokenFrom.toLowerCase()}>

            { block !== null &&
            <TxInfo label='Date'>
                {DateTime.fromSeconds(block.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
            </TxInfo> 
            }

            <TxInfo label='Summary'>
                { summary }
            </TxInfo>

            <TxInfo label={`${tokenTo}`}>
                +{ numeral(amountTo).format('0,0.0000') }
            </TxInfo> 

            <TxInfo label={`${tokenFrom}`}>
                -{ numeral(amountFrom).format('0,0.0000') }
            </TxInfo>  

              

            <TxInfo label='Account'>
                <MiddleEllipsis>
                    <span>{ data.account }</span>
                </MiddleEllipsis>
            </TxInfo>  

            <TxInfo label='Transaction ID'>
                <MiddleEllipsis>
                    <span>{tx.transactionHash}</span>
                </MiddleEllipsis>
            </TxInfo>

            <TxInfo label='' className='etherscan'>
                <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                    Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                </StyledLink>
            </TxInfo>
        </Tx>
    )    
}

export default TxSwap
