import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

export interface TextChangedProps {
    text: string,
    className?: string
    color?: 'h2o' | 'ice' | 'steam',
}

function usePrevious(value:string) {
    const ref:any = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

// https://www.robinwieruch.de/react-useeffect-only-on-update
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
const TextChanged: React.FC<TextChangedProps> = ({ text, color='h2o' }) => {
    const [className, setClassName] = useState<string>('')
    /*
    const prevValue = usePrevious(text); 
    console.log('prevValue', prevValue)
    console.log("text", text)
    */

    useEffect(() => {   
        if ( text ) {
            setClassName('highlight')
            setTimeout(function() {
                setClassName('')
            }, 1000)
        }
    }, [text]);
  
    return (
        <Text className={color + " " + className}>
        {text}
        </Text>
    )
}


const Text = styled.span`
    -webkit-transition: color 200ms, transform 200ms;
    transition: color 200ms, transform 200ms;

    

    &.highlight.h2o {
        color: ${props => props.theme.color.h2o}
    }

    &.highlight.ice {
        color: ${props => props.theme.color.ice}
    }

    &.highlight.steam {
        color: ${props => props.theme.color.steam}
    }
   
`
/*
    &.highlight {
        transform: scale(1.3);
    }

    &.highlight {
        color: ${({color, theme}) => {
            if ( color === 'h2o' ) return theme.color.h2o;
            if ( color === 'ice' ) return theme.color.ice;
            if ( color === 'steam' ) return theme.color.steam;
            return 'inherit'
        }};
    }
*/

export default TextChanged