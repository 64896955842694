import React, { MouseEvent, useState, useEffect, useContext, useCallback, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import Button from '../../components/Button';
import AccountButton from '../../components/TopBar/components/AccountButton';
import TokenSymbol from '../../components/TokenSymbol';
import Spacer from '../../components/Spacer';
import useModal from '../../hooks/useModal';
import TokenPickerModal from './TokenPickerModal';
import useIceWater from '../../hooks/useIceWater';
import useDebounce from '../../hooks/useDebounce';
import numeral from 'numeral';

import { MobileStyles } from '../../theme/Mixins'
import { DateTime } from "luxon";

import SimpleModal from 'components/SimpleModal';

// https://mdbootstrap.com/docs/react/content/icons-list/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faArrowDown, faArrowRight, faExclamationTriangle, faCog, faSlidersH, faInfoCircle, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { WalletContext } from 'contexts/Account/WalletContext';

var _ = require('lodash');

interface onSubmitProps {
    fromToken: string,
    toToken: string,
    fromTokenAmount: number,
    maxDelay: string,
    maxSlippage: string,
    deadline: number,
    slippage: number,
    estimate: string
}

interface SwapFormProps {
    tokenBalances: any,
    onSubmit: (data:onSubmitProps) => void
}

const Defaults:any = {
    maxSlippage: 1,
    maxDelay: 30
}

type Inputs = {
    fromAmount: string,
    fromToken: string,
    fromTokenAmount: number,
    toToken: string,
    maxSlippage: number,
    maxDelay: number,
    insufficentBalance: any,
    invalid: any,
    invalidForm: any
};

// const SwapForm: React.FC<SwapFormProps> = forwardRef(({
// TODO: passing the resetRef with forwardRef is giving a type error so
// As a work around I'm changing the the component prop types to "any"
const SwapForm: React.FC<any> = forwardRef(({
    tokenBalances,
    onSubmit
}, resetRef) => {
    const { address, connected } = useContext(WalletContext)
    const iceWater = useIceWater();
    const [fromToken, setFromToken] = useState<string>("H2O");
    const [fromTokenAmount, setFromTokenAmount] = useState<number>(0);
    const [toToken, setToToken] = useState<string>("ICE");
    const [isQueryingEstimate, setIsFetchingReceiveEstimate] = useState<boolean>(false);
    const [willReceiveEstimate, setWillReceiveEstimate] = useState<string>(""); 
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [maxSlippageValue, setMaxSlippageValue] = useState<number>(Defaults.maxSlippage);
    const [maxDelayValue, setMaxDelayValue] = useState<number>(Defaults.maxDelay);
    
    var onClaimH2ORegistered = false

    useImperativeHandle(resetRef, () => ({
        resetForm,
    }));

    // useForm()
    const { register,
            handleSubmit,
            formState: { isSubmitSuccessful, errors },
            setError,
            setValue,
            reset,
            clearErrors} = useForm<Inputs>();  

    /**
     * Submit form
     */
    const submitForm = useCallback((data: onSubmitProps) => {   
        // Monkeybutt's implementation
        const deltaTime = parseInt(data.maxDelay) * 60;
        data.deadline = Math.round(+new Date()/1000) + deltaTime;

        data.slippage = parseFloat(data.maxSlippage) * 0.01;
        onSubmit(data)
    }, [onSubmit]);

    /**
     * Registering and assigning a handle for the iceWater ctr.events.swap event
     */
    const onSwap = async (data: any) => {
        //console.log("onSwap event callback")
        //console.log(data)
    }

    useEffect(() => {   
        if (typeof iceWater !== 'undefined' && !onClaimH2ORegistered ) {
            iceWater.registerSwapEventHandler(onSwap) 
            onClaimH2ORegistered = true
        }
    }, [iceWater, iceWater?.isUnlocked]);
    
    /**
     * Reset the form
     */
     const resetForm = () => {
        //setToToken('H2O')
        //setFromToken('SELECT')
        reset() 
        setFromTokenAmountValue(0)
        setFromTokenAmountValue(0)
    }
 

    /**
     * Sets the fromToken string
     * @param fromToken (string) "H2O" | "ICE" | "STEAM" | "SELECT"
     */
    const setFromTokenValue = (fromToken:string) => {
        setFromToken(fromToken)
        // UseForm().setValue to update form validation
        setValue('fromToken', fromToken, {
            shouldValidate: true
        })
    }

    /**
     * Sets the toToken string
     * @param toToken (string) "H2O" | "ICE" | "STEAM" | "SELECT"
     */
    const setToTokenValue = (toToken:string) => {
        setToToken(toToken)
        // UseForm().setValue to update form validation
        setValue('toToken', toToken, {
            shouldValidate: true
        })
    }

    /**
     * Sets the fromTokenAmount number
     * @param amount (number)
     */
    const setFromTokenAmountValue = (amount:number) => {
        setValue('fromTokenAmount', amount, {
            shouldValidate: true
        })
        setFromTokenAmount(amount)
    }

    /**
     * Fetch the amount of $fromToken that the user will get for swaping $toToken
     */
    const fetchRecieveEstimate = async (): Promise<void> => {
        if ( !fromTokenAmount ) return        

        // console.log("fetchRecieveEstimate: " + fromToken + " " + toToken + " " + fromTokenAmount)
        if ( fromToken === "SELECT" || toToken === "SELECT" || fromTokenAmount <= 0 ) {
            setWillReceiveEstimate('')
            return
        }

        if ( fromTokenAmount <= 1 ) {
            return
        }

        setIsFetchingReceiveEstimate(true)
        const estimate = await iceWater.previewSwapTokens(
            fromToken, toToken, fromTokenAmount)

        setWillReceiveEstimate(estimate)
        setIsFetchingReceiveEstimate(false)
    }

    // 
    /**
     * Debounce the fetchRecieve estimate so the function is only called if
     * the fromTokenAmount hasn't been updated in 500ms. (Limits the frequency of calls).
     */
    const debouncedEstimateQuery = useDebounce(fromTokenAmount, 500);
    useEffect(() => {
        if (debouncedEstimateQuery) {
        fetchRecieveEstimate()
        } else {
        setWillReceiveEstimate('')
        setIsFetchingReceiveEstimate(false);
        }
    },[debouncedEstimateQuery]);

    
    /**
     * UI:Event:onClick
     * SwapTokens function for swapping the toToken and fromToken strings
     */
    //const swapToFromTokens = (e: MouseEvent<HTMLButtonElement>) => {
    const swapToFromTokens = () => {
        setFromTokenValue(toToken)
        setToTokenValue(fromToken)
        clearErrors()
    }
    
    /**
     * UI:Event:onBlur
     * input[name='fromAmount'] function that formats the fromAmount input text to numbers with commas.
     */
    const onFromAmountBlur = (e: MouseEvent<HTMLButtonElement>) => {
        //const value:string = (e.currentTarget.value).replace(/\D/g, "")
        const value:string = (e.currentTarget.value).replace(/[^0-9\.]/g, "")
        const amount:number = parseFloat(value);
        setValue('fromAmount', numeral(amount).format('0,0.00'), {
            shouldValidate: true // this used to be false, why?
        })
    };


    /**
     * UI:Event:onChange
     * input[name='fromAmount'] function that updates the fromTokenAmount
     */
    const onFromAmountChange = (e: MouseEvent<HTMLButtonElement>) => {
        //const value:string = (e.currentTarget.value).replace(/\D/g, "")
        const value:string = (e.currentTarget.value).replace(/[^0-9\.]/g, "")
        setFromTokenAmountValue(parseFloat(value))
    };

    /**
     * UI:Event:onChange
     * input[name='maxSlippage'] function that updates the fromTokenAmount
     */
    const onMaxSlippageChange = (e: MouseEvent<HTMLButtonElement>) => {
        const value:string = (e.currentTarget.value).replace(/[^0-9\.]/g, "")
        setMaxSlippageValue(parseFloat(value))
    };

    /**
     * UI:Event:onChange
     * input[name='maxDelay'] function that updates the fromTokenAmount
     */
     const onMaxDelayChange = (e: MouseEvent<HTMLButtonElement>) => {
        const value:string = (e.currentTarget.value).replace(/[^0-9\.]/g, "")
        setMaxDelayValue(parseInt(value))
    };

    /**
     * UI:Event:onClick
     * Determine what to do when the fromToken TokenPicker is clicked on.
     */
     const onClickTokenPickerFrom = () => {
        swapToFromTokens()
        return
        //onPresentTokenPickerModalFrom()
    }

    const [onPresentSlippageModal] = useModal(
        <SimpleModal title='Max Slippage'>
            Your transaction will revert if the price changes by more than the provided Max Slippage percentage.
        </SimpleModal>
    )

    const [onPresentDelayModal] = useModal(
        <SimpleModal title='Max Delay'>
            Your transaction will revert if it's pending for longer than the provided Max Delay.
        </SimpleModal>
    )


    /**
     * UI:Modal
     * Launches the TokenPickerModal
     * onConfirm sets the selected token as the fromTokenValue
     */
    const [onPresentTokenPickerModalFrom, onDismissTokenPickerModalFrom] = useModal(
        <TokenPickerModal 
            title="Swap From"
            direction="from"
            fromToken={fromToken}
            onConfirm={(token) => {
                clearErrors('fromToken')
                clearErrors('invalid')
                setFromTokenValue(token)
                if ( token == 'ICE' || token == 'STEAM' ) {
                    setToTokenValue('H2O')
                }
                if ( token == 'H2O' ) {
                    setToTokenValue('SELECT')
                }
                onDismissTokenPickerModalFrom()
            }}
        />
    )  

    /**
     * UI:Event:onClick
     * Determine what to do when the toToken TokenPicker is clicked on.
     */
     const onClickTokenPickerTo = () => {
        swapToFromTokens()
        return
        // if ( fromToken == 'H2O' ) {
        //     onPresentTokenPickerModalTo()
        // }
    }

    /**
     * UI:Modal
     * Launches the TokenPickerModal
     * onConfirm sets the selected token as the toTokenValue
     */
    const [onPresentTokenPickerModalTo, onDismissTokenPickerModalTo] = useModal(
        <TokenPickerModal 
            title="Swap To"
            direction='to'
            fromToken={fromToken}
            onConfirm={(token) => {
                clearErrors('toToken')
                clearErrors('invalid')
                setToTokenValue(token)
                if ( token == fromToken ) {
                    setFromTokenValue('SELECT')
                }
                onDismissTokenPickerModalTo()
            }}
        />
    )

    // useEffect(() => {
    //     console.log('errors', errors)
    // }, [errors])

    /**
     * UI:Validation
     * Determine if the user has sufficent balance for the transaction.
     */
    useEffect(() => {
        fetchRecieveEstimate()
        if ( connected && fromToken ) {
            if ( fromToken == "H2O" ) {
                if ( fromTokenAmount > parseFloat(tokenBalances.h2o) ) {
                    setError("insufficentBalance", {
                        type: "manual",
                        message: "You don't have enough H2O."
                    })
                } else {
                    clearErrors('insufficentBalance')
                }
            }

            if ( fromToken == "ICE" ) {
                if ( fromTokenAmount > parseFloat(tokenBalances.ice) ) {
                    setError("insufficentBalance", {
                        type: "manual",
                        message: "You don't have enough ICE."
                    })
                } else {
                    clearErrors('insufficentBalance')
                }
            }

            if ( fromToken == "STEAM" ) {
                if ( fromTokenAmount > parseFloat(tokenBalances.steam) ) {
                    setError("insufficentBalance", {
                        type: "manual",
                        message: "You don't have enough STEAM."
                    })
                } else {
                    clearErrors('insufficentBalance')
                }
            }

            if ( fromToken == "SELECT" ) {
                clearErrors('insufficentBalance')
            }
            
        } else {
            clearErrors('insufficentBalance')
            clearErrors('invalid')
        }
    }, [address, fromToken, fromTokenAmount, toToken, tokenBalances]);

    


    /**
     * UI:Style
     * Update the Sumbit Button style and text whenever the fromToken and toToken change
     */
    const ButtonStyle = useMemo(() => {
        if ( fromToken == 'H2O' && toToken == 'ICE' ) {
            return <Button
                type="submit"
                size="md"
                variant='water_ice'
                >Swap {fromToken} <FontAwesomeIcon icon={faArrowRight} /> {toToken}</Button> 
        } else if ( fromToken == 'H2O' && toToken == 'STEAM' ) {
            return <Button
                type="submit"
                size="md"
                variant='water_steam'
                >Swap {fromToken} <FontAwesomeIcon icon={faArrowRight} /> {toToken}</Button> 
        } else if ( fromToken == 'ICE' && toToken == 'H2O' ) {
            return <Button
                type="submit"
                size="md"
                variant='ice_water'
                >Swap {fromToken} <FontAwesomeIcon icon={faArrowRight} /> {toToken}</Button>  
        } else if ( fromToken == 'ICE' && toToken == 'STEAM' ) {
            return <Button
                type="submit"
                size="md"
                variant='ice_steam'
                >Swap {fromToken} <FontAwesomeIcon icon={faArrowRight} /> {toToken}</Button>  
        } else if ( fromToken == 'STEAM' && toToken == 'H2O' ) {
            return <Button
                type="submit"
                size="md"
                variant='steam_water'
                >Swap {fromToken} <FontAwesomeIcon icon={faArrowRight} /> {toToken}</Button> 
        } else if ( fromToken == 'STEAM' && toToken == 'ICE' ) {
            return <Button
                type="submit"
                size="md"
                variant='steam_ice'
                >Swap {fromToken} <FontAwesomeIcon icon={faArrowRight} /> {toToken}</Button>  
        } else {
            return <Button
                type="submit"
                size="md"
                variant='water_ice_steam'
            ><span style={{opacity: .7}}>Swap</span></Button>
        }
    }, [fromToken, toToken])

    const fromAmountRef = useRef<HTMLInputElement | null>(null);
    const fromAmountReg = register("fromAmount", { 
        required: true,     
        pattern: /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)/              
    })

    const maxSlippageRef = useRef<HTMLInputElement | null>(null);
    const maxSlippageReg = register("maxSlippage", { 
        required: "The Max Slippage is required.",
        // min: .01,
        // max: 50,
        // pattern: /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)/
    })

    const maxDelayRef = useRef<HTMLInputElement | null>(null);
    const maxDelayReg = register("maxDelay", { 
        // min: 1,
        // max: 1440,
        // pattern: /^[1-9]\d*$/   
        required: "Max delay is required",
    })


    const onAddMaxFromAmount = () => {
        const token = fromToken.toLowerCase()
        const amount = tokenBalances[token]
        fromAmountRef.current.value = amount
        setFromTokenAmountValue(amount)
        setValue('fromAmount', amount, {
            shouldValidate: false
        })
    }

    const resetMaxSlippage = () => {
        maxSlippageRef.current.value = Defaults.maxSlippage.toString() 
        setValue('maxSlippage', Defaults.maxSlippage, {
            shouldValidate: true
        })
        setMaxSlippageValue(Defaults.maxSlippage)
    }

    const resetMaxDelay = () => {
        maxDelayRef.current.value = Defaults.maxDelay.toString()
        setValue('maxDelay', Defaults.maxDelay, {
            shouldValidate: true
        })
        setMaxDelayValue(Defaults.maxDelay)
    }

    // https://react-hook-form.com/ts
    // https://regexr.com/5ck92 (Positive Decimals with Max Place)    
    return (
        <Styles className={showSettings ? 'showSettings' : ''}> 
            { connected &&         
            <Max>
                <div>
                <Button type='button' variant='empty' size='sm' 
                    onClick={onAddMaxFromAmount}> + MAX</Button>
                </div>
            </Max>
            }

            <Advanced onClick={() => setShowSettings(!showSettings)}>
                <FontAwesomeIcon icon={faSlidersH} />
            </Advanced>

            <Spacer size='md'/>


        <form onSubmit={handleSubmit(submitForm)}>
            {/* { _.isEmpty(errors) && 
                <SwapTokensButton onClick={swapToFromTokens}>
                    <FontAwesomeIcon className="chevron" icon={faExchangeAlt} />
                </SwapTokensButton>
            } */}
            
            <div className="inputGrps">
                <div className="inputGrp">
                    <InputFlexGroup>
                        <TokenPicker onClick={onClickTokenPickerFrom}>
                            { fromToken == 'SELECT' ? (        
                                <SelecetSlug>SELECT</SelecetSlug>
                            ) : (
                                <>
                                <TokenSymbol symbol={fromToken} />  
                                <span>{fromToken}</span>
                                </>
                            )}
                            <FontAwesomeIcon className="chevron" icon={faChevronDown} />
                        </TokenPicker>
                        <InputWrap className='sqLeft'>
                            <input type='hidden'
                                value={fromToken}
                                {...register("fromToken", { 
                                    required: true,   
                                    pattern: /^(?!.*SELECT).*$/                        
                                })} />

                            <input type='hidden'
                                {...register("fromTokenAmount", { 
                                    required: true,   
                                    pattern: /^([0-9]*[.])?[0-9]+$/ 
                                    //pattern: /^(\d+)?(\.{1}\d{0,6})?$/                       
                                })} />

                            <input placeholder="0.0"                        
                                type='text'
                                autoComplete="false"    
                                {...fromAmountReg}
                                ref={(e) => {
                                    fromAmountReg.ref(e)
                                    fromAmountRef.current = e
                                }}
                                onChange={(e:any) => onFromAmountChange(e) }
                                onBlur={(e:any) => onFromAmountBlur(e) }
                            />
                        </InputWrap>
                    </InputFlexGroup> 

                    { errors.insufficentBalance && fromToken &&
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                { errors.insufficentBalance.message }
                            </div>
                        </InputError>       
                    }         

                    { errors.fromToken && 
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                Select the token you want to trade.
                            </div>
                        </InputError>       
                    }

                    { errors.fromAmount && !errors.fromToken &&
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                Enter the amount of {fromToken} you want to trade.
                            </div>
                        </InputError>       
                    } 
                </div>   

                <Spacer size="sm" />

                <div className="inputGrp">   
                    <InputFlexGroup>
                        <TokenPicker onClick={onClickTokenPickerTo}>
                            { toToken == 'SELECT' ? (        
                                <SelecetSlug>SELECT</SelecetSlug>
                            ) : (
                                <>
                                <TokenSymbol symbol={toToken} />  
                                <span>{toToken}</span>
                                </>
                            )}
                            <FontAwesomeIcon className="chevron upsidedown" icon={faChevronDown} />
                        </TokenPicker>
                        <InputWrap className="readonly sqLeft">
                            <input type='hidden'
                                value={toToken}
                                {...register("toToken", { 
                                    required: true,   
                                    pattern: /^(?!.*SELECT).*$/                        
                                })} 
                            />

                            { toToken != "SELECT" && willReceiveEstimate &&
                                <Estimate className='number'>
                                    ~{numeral(willReceiveEstimate).format('0,0.00')}
                                </Estimate>
                            } 
                        </InputWrap>
                    </InputFlexGroup>

                    { errors.toToken && 
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                Select the token you want to receive.
                            </div>
                        </InputError>       
                    }
                </div> 
            </div>

            <Spacer size='sm' />


            { errors.invalidForm &&
                <InputError>
                    <div className="error">{errors.insufficentBalance.message}</div>
                </InputError>       
            } 

            { errors.invalid && fromToken &&
                <InputError>
                    <div className="error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        { errors.invalid.message }
                    </div>
                </InputError>       
            }

            <Settings className='settings'>  
                <SettingsRow>
                    <InputTypeSuffix>
                        <label>
                            Max Slippage 
                            <span onClick={onPresentSlippageModal}>
                                <FontAwesomeIcon icon={faInfoCircle} />    
                            </span> 
                        </label>
                        <div className='inputTypeWrap'>
                            { maxSlippageValue != Defaults.maxSlippage &&
                                <div className='inputReset' onClick={resetMaxSlippage}>
                                    RESET
                                </div>
                            }
                            <div className='inputTypeInput'>
                                <input                        
                                    type='text'
                                    autoComplete="false"   
                                    defaultValue={Defaults.maxSlippage}
                                    {...maxSlippageReg}
                                    ref={(e) => {
                                        maxSlippageReg.ref(e)
                                        maxSlippageRef.current = e
                                    }}
                                    onChange={(e:any) => onMaxSlippageChange(e) }
                                />
                            </div>
                            <div className='inputType'>
                                %
                            </div>
                        </div>
                    </InputTypeSuffix>

                    <InputTypeSuffix>
                        <label>
                            Max Delay
                            <span onClick={onPresentDelayModal}>
                                <FontAwesomeIcon icon={faInfoCircle} />    
                            </span>
                        </label>
                        <div className='inputTypeWrap'>
                            { maxDelayValue != Defaults.maxDelay &&
                                <div className='inputReset' onClick={resetMaxDelay}>
                                    RESET
                                </div>
                            }

                            <div className='inputTypeInput'>
                                <input                         
                                    type='text'
                                    autoComplete="false"  
                                    defaultValue={Defaults.maxDelay} 
                                    {...maxDelayReg}
                                    ref={(e) => {
                                        maxDelayReg.ref(e)
                                        maxDelayRef.current = e
                                    }}
                                    onChange={(e:any) => onMaxDelayChange(e) }
                                />
                            </div>
                            <div className='inputType'>
                                Mins
                            </div>
                        </div>
                    </InputTypeSuffix>
                </SettingsRow>
                <Spacer size='md' />
            </Settings>

            { errors.maxSlippage && 
                <InputError>
                    <div className="error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        Max Slippage requires a number [0.01-50] %.
                    </div>
                </InputError>       
            }

            { errors.maxDelay && 
                <InputError>
                    <div className="error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        Max Delay requires a number [1-1440] mins.
                    </div>
                </InputError>       
            }
            
            { connected ? (        
                <SubmitButton>
                    {ButtonStyle}
                </SubmitButton>
            ) : (
                <AccountButton size="md" />
            )}
            

            
            
        </form>
        </Styles>
    )
})

const Advanced = styled.div `
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
`


const Max = styled.div `
    position: absolute;
    top: 2px;
    right: 40px;
    padding: 5px;

    button {
        position: relative;
        right: -5px;
    }
`

const SubmitButton = styled.span`
    button svg {
        margin: 0px 10px;
    }
`

const SelecetSlug = styled.span`
    margin-left: 10px;
    opacity: .5;
`

const InputFlexGroup = styled.div` 
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
`

const Settings = styled.div `
    
`

const SettingsRow = styled.div `
    display: flex;
    > div:nth-child(1) {
        margin-right: 10px;
    }
    > div {
        width: 50%;
    }
`


const InputTypeSuffix = styled.div`

    label {
       margin-bottom: 5px;

       span {
           margin-left: 5px;
           cursor: pointer;
           opacity: .3;
           transition: opacity 0.2s ease;
           &:hover {
               opacity: .8;
           }
       }
    }

    .inputTypeWrap {
        position: relative;

        display: flex;

        .inputTypeInput {
            flex: 1;
            padding-right: 10px;
        }

        .inputReset {
            position: absolute;
            left: 10px;
            top: 17px;
            font-size: 12px;
            opacity: .5;
            cursor: pointer;
            padding: 5px;
            transition: opacity .2s ease;
            &:hover {
                opacity: 1;
            }
        }

        input {
            width: 100%;
            margin: 0px;
            padding: 0px;
            text-align: right;
            padding-right: 10px;
            background-color: rgba(255, 255, 255, 0.1);
            
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            //border-right: 1px solid rgba(255, 255, 255, 0.2);
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-left: 1px solid rgba(0, 0, 0, 0.2);

            border-top-left-radius: ${props => props.theme.borderRadius}px;
            border-bottom-left-radius: ${props => props.theme.borderRadius}px;
        }

        .inputType {
            background-color: rgba(255, 255, 255, 0.15);
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 0px 15px;
            border-top-right-radius: ${props => props.theme.borderRadius}px;
            border-bottom-right-radius: ${props => props.theme.borderRadius}px;
        }
    }

`

const InputError = styled.div` 
    margin-bottom: 25px;
`


const TokenPicker = styled.div`  
    width: 110px;
    height: 56px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-top-left-radius: ${props => props.theme.borderRadius}px;
    border-bottom-left-radius: ${props => props.theme.borderRadius}px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    position: relative;

    &:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }

    img {
        width: 35px;
        height: 35px;
        display: inline;
        vertical-align: middle;
        margin-right: 10px;
    }

    span {
        font-weight: bold;
    }

    svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0%, -50%);
    }

    svg.upsidedown {
        transform: rotate(180deg);
        margin-top: -8px;
    }
`

const Estimate = styled.div`
    color: rgba(255, 255, 255, 0.5); 
    font-size: 24px;
    line-height: 61px;
    text-align: right;

    ${MobileStyles(`
        font-size: 18px;
    `)};

    &.number {
        font-family: 'Roboto Mono', monospace;
        color: ${props => props.theme.color.white}; 
        //opacity: .8;
    }
`

const InputWrap = styled.div` 
    flex: 1;
    background-color: rgba(255, 255, 255, 0.1);
    // backdrop-filter: blur(5px);
    // -webkit-backdrop-filter: blur(5px);

    -webkit-border-radius: ${props => props.theme.borderRadius}px;
    -moz-border-radius: ${props => props.theme.borderRadius}px;
    border-radius: ${props => props.theme.borderRadius}px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 10px;

    &.sqLeft {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }


    &.readonly {
        background-color: rgba(125, 125, 125, 0.15);
        border: 0;
        cursor: default;

        input {
            cursor: default;
        }
    }

    input {    
        background: none;
        border: 0;
        color: ${props => props.theme.color.white};
        font-size: 24px !important;
        height: 61px !important;
        margin: 0;
        padding: 0;
        outline: none;
        text-align: right;
        width: 100%;

        ${MobileStyles(`
            font-size: 18px !important;
        `)};
     }
    
     input::placeholder {
         color: rgba(255, 255, 255, 0.5);
     }
    
     
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type=number] {
        -moz-appearance: textfield;
    }
`

// const TokenPicker = styled.div`  
//     border-right: 1px solid #fff;
//     width: 125px;
//     position: absolute;
//     top: 5px;
//     left: 10px;
//     height: 45px;

//     img {
//         width: 35px;
//         height: 35px;
//         display: inline;
//         vertical-align: middle;
//         margin-right: 10px;
//     }

//     label {
//         display: inline !important;
//     }
// `

const InputFauxValue = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    height: 61px;
    color: red;
    text-align: right;
    font-family: 'Roboto Mono', monospace;
    font-size: 24px;

`

const Styles = styled.div`

    .settings {
        display: none;
    }
    &.showSettings {
        .settings {
            display: block;
        }
    }

    div.inputGrps {
        position: relative;
        input {
            font-family: 'Roboto Mono', monospace;
        }
    }

 div.inputGrp {
    position: relative;
 }

 div.inputWrap {
    align-items: center;
    border-radius: ${props => props.theme.borderRadius}px;
    display: flex;
    padding: 0px 20px 0px 125px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
 }

 div.inputWrap span {
    opacity: .5;
    text-transform: uppercase;
 }

 input {    
    background: none;
    border: 0;
    color: ${props => props.theme.color.white};
    font-size: 24px;
    flex: 1;
    height: 56px;
    margin: 0;
    padding: 0;
    outline: none;
    text-align: right;
    ${MobileStyles(`
        font-size: 18px;
    `)};
 }

 input::placeholder {
     color: rgba(255, 255, 255, 0.5);
 }

 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

 label {
   display: block;
   font-weight: bold;
   margin: 0px 0px 10px 3px;
 }

 .error {
   color: ${props => props.theme.color.h2o};      
   margin: 10px 0px 0px 3px;

   svg {
       margin-right: 10px;
   }
 }
`;



export default SwapForm