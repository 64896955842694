import React, { useContext, useState} from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import TextChanged from 'components/TextChanged';
import { MobileStyles } from 'theme/Mixins'
import useiceWater from 'hooks/useIceWater';
import { IceWaterContext } from 'contexts/IceWaterProvider/IceWaterProvider';
import Spacer from 'components/Spacer';

var _ = require('lodash');

interface MarketStatsProps {
    
}

const MarketStats: React.FC<MarketStatsProps> = ({}) => {
    const { marketStats } = useContext(IceWaterContext)
    const [showAdvanced, setShowAdvanced] = useState<boolean>(false)

  return (
    <MarketCard>
        <TokenCardHeader className="ice">
            <h4>Ice Market</h4>
        </TokenCardHeader>        

        <Stats>
            <VTRow>
                

                { showAdvanced ? (
                    <>
                        <VTCol2>
                            <VTLabel>PRICE</VTLabel>
                            { marketStats.priceIce ? (
                                <>
                                <TextChanged text={numeral(marketStats.priceIce).format('0,0.00')} color='ice' /> <H2oToken>H2O</H2oToken>
                                </>
                            ) : (
                                <span>--</span>
                            )}
                        </VTCol2>
                        <VTCol2 className='low'>
                            <VTLabel>TARGET PRICE</VTLabel>
                            { marketStats.priceTargetIce ? (
                                <>
                                    <TextChanged text={numeral(marketStats.priceTargetIce).format('0,0.00')} color='ice' /> <H2oToken>H2O</H2oToken>
                                </>
                            ) : (
                                <span>--</span>
                            )}
                        </VTCol2>
                    </>
                ):(
                    <VTCol>
                        <VTLabel>PRICE</VTLabel>
                        { marketStats.priceIce ? (
                            <>
                            <TextChanged text={numeral(marketStats.priceIce).format('0,0.00')} color='ice' /> <H2oToken>H2O</H2oToken>
                            </>
                        ) : (
                            <span>--</span>
                        )}
                    </VTCol>
                )}
            </VTRow>
            <VTRow>

                { showAdvanced ? (
                    <>
                        <VTCol2>
                            <VTLabel>YIELD</VTLabel>
                            { marketStats.yeildIce ? (
                            <TextChanged text={numeral(marketStats.yeildIce).format('0,0.00') + '%'} color='ice' />
                            ) : (
                                <span>--</span>
                            )}
                        </VTCol2>

                        <VTCol2 className='low'>
                            <VTLabel>TARGET YIELD</VTLabel>
                            { marketStats.yieldTargetIce ? (
                            <TextChanged text={numeral(marketStats.yieldTargetIce).format('0,0.00') + '%'} color='ice' />
                            ) : (
                                <span>--</span>
                            )}
                        </VTCol2>   
                    </>
                ) : ( 
                    <VTCol>
                        <VTLabel>YIELD</VTLabel>
                        { marketStats.yeildIce ? (
                        <TextChanged text={numeral(marketStats.yeildIce).format('0,0.00') + '%'} color='ice' />
                        ) : (
                            <span>--</span>
                        )}
                    </VTCol>
                )}                  
            </VTRow>
        </Stats>

        {/* <Spacer size="md" />

        <TokenCardHeader className="steam">
            <h4>Steam Market</h4>
        </TokenCardHeader>
        <Stats>      
            <VTRow>
                <VTCol>
                    <VTLabel>PRICE</VTLabel>
                    { marketStats.priceSteam ? (
                        <>
                            <TextChanged text={numeral(marketStats.priceSteam).format('0,0.00')} color='steam' /> <H2oToken>H2O</H2oToken>
                        </>
                    ) : (
                        <span>--</span>
                    )}
                </VTCol>
            </VTRow>
            <VTRow>
                <VTCol>
                    <VTLabel>YIELD</VTLabel>
                    { marketStats.yeildSteam ? (
                    <TextChanged text={numeral(marketStats.yeildSteam).format('0,0.00') + '%'} color='steam' />
                    ) : (
                        <span>--</span>
                    )}
                </VTCol>
            </VTRow>
        </Stats> */}

    </MarketCard>
  )
}

/*
    <MarketCard>
        <TokenCardHeader className="ice">
            <h4>Ice Market</h4>
        </TokenCardHeader>        

        <Stats>
            <VTRow>
                <Stat label='PRICE' value={iceWaterStats.priceIce} theme='ice' />
                <Stat label='TARGET PRICE' value={iceWaterStats.priceTargetIce} theme='ice' className='low' />
            </VTRow>
            <VTRow>
                <Stat label='YIELD' value={iceWaterStats.yeildIce} suffix='%' theme='ice' />
                <Stat label='TARGET YIELD' value={iceWaterStats.yieldTargetIce} theme='ice' className='low' suffix='%' />
            </VTRow>
        </Stats>

        <Spacer size="md" />

        <TokenCardHeader className="steam">
            <h4>Steam Market</h4>
        </TokenCardHeader>

        <Stats>      
            <VTRow>
                <Stat label='PRICE' value={iceWaterStats.priceSteam} theme='steam' format='0,0.00'/>
            </VTRow>
            <VTRow>
                <Stat label='YIELD' value={iceWaterStats.yeildSteam} theme='steam' suffix='%' />
            </VTRow>
        </Stats>

    </MarketCard>
*/
export default MarketStats



const MarketCard = styled.div `
    flex: 1;
    padding: ${(props) => props.theme.spacing[3]}px;
    color: ${(props) => props.theme.color.white};
    -webkit-border-top-right-radius: 15px;
    -webkit-border-bottom-right-radius: 15px;
    -moz-border-radius-topright: 15px;
    -moz-border-radius-bottomright: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(100, 100, 100, 0.1);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 

    ${MobileStyles(`
        background: none;
        border: none;
        background-color: none;
        box-shadow: none;
        backdrop-filter: none; 
        -webkit-backdrop-filter: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
    `)};

    h4 {
        font-weight: bold;
    }
`

const TokenCard = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 10px;
  padding: 20px;
`

const TokenCardHeader = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 3px;

  &.ice {
    border-bottom: 2px solid ${props => props.theme.color.ice}; 
  }

  &.steam {
    border-bottom: 2px solid ${props => props.theme.color.steam};
  }

  h4 {
    font-weight: normal;
    display: inline-block;
    margin: 0px 0px 5px 0px;
    padding: 0px;
  }
`

const VTLabel = styled.div `
  font-size: 12px;
  text-transform: uppercase;
`
const VTRow = styled.div `
  font-size: 18px;
  display: flex;
  margin-bottom: 10px;
`

const H2oToken = styled.span `
//   font-size: 12px;
//   position: relative;
//   top: -2px;
`

const VTCol = styled.span `
  &.low {
    opacity: .8;
  }


  span.tiny {
    font-size: 12px;
    line-height: 15px;
    a {
      font-size: 12px;
      line-height: 15px;
      display: inline-block;
    }
  }
`

const VTCol2 = styled.span `
  width: 50%;
  &.low {
    opacity: .8;
  }


  span.tiny {
    font-size: 12px;
    line-height: 15px;
    a {
      font-size: 12px;
      line-height: 15px;
      display: inline-block;
    }
  }
`


const TokenCardLabel = styled.h4 `

`

const Stats = styled.div `

`
