import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import TokenSymbol from 'components/TokenSymbol';
import Spacer from '../../../components/Spacer';
import { MobileStyles } from 'theme/Mixins'
import { Desktop, Mobile } from "components/Responsive";
import numeral from 'numeral';
import { DateTime } from "luxon";
import MiddleEllipsis from "react-middle-ellipsis";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faTrophy, faExternalLinkAlt, faArrowRight, faQuestion } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';
import { faDiscord, faEthereum } from '@fortawesome/free-brands-svg-icons';
import StyledLink from 'components/Link'
import { EtherscanDomain, TokensClassName } from 'utils/utils';

interface Props {
    tx: any,
    address?: string,
    isOpen?: boolean
}

const Transaction: React.FC<Props> = ({
    tx,
    address,
    isOpen=false
}) => {
    const [open, setOpen] = useState<boolean>(isOpen)

    // VENT
    if ( tx.transactionType == 'reward_vent' ) {
        return (
        <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
            <Header>
                <div className='txIcon'>
                    <TokenSymbol symbol='STEAM' size={36} />
                </div>
                
                <div className='txTypeDate'>
                    <div className='txType'>
                        Vent Dividends
                    </div>
                    <div className='txDate'>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                    </div>
                    { tx.status === 'pending' &&
                        <div className='txStatus'>
                            Pending
                        </div>
                    }
                </div>
                
                <div className='txFlex'> </div>

                <div className='txChange'>
                    <div className={`txGain steam`}>
                        +{ numeral(tx.amount).format('0,0.000')  } STM
                    </div>
                </div>
            </Header>

            <Details className='txDetails'>

                <Detail>
                    <label>Summary</label>
                    <div>
                        { tx.summary }
                    </div>
                </Detail>

                <Detail>
                    <label>Date</label>
                    <div>
                    {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                    </div>
                </Detail>

                <Detail>
                    <label>Account</label>
                    <div>
                        <MiddleEllipsis>
                            <span>{tx.by}</span>
                        </MiddleEllipsis>
                    </div>
                </Detail>            

                <Detail>
                    <label>Transaction ID</label>
                    <div>
                        <MiddleEllipsis>
                            <span>{tx.transactionHash}</span>
                        </MiddleEllipsis>
                    </div>
                </Detail>
                <Detail>
                    <label></label>
                    <div className='etherscan'>
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    </div>
                </Detail>
            </Details>
        </Content> 
        )
    }

    // REWARDS
    else if ( tx.transactionType == 'reward_dividends' ) {
        return (
        <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
            <Header>
                <div className='txIcon'>
                    <TokenSymbol symbol='H2O' size={36} />
                </div>
                
                <div className='txTypeDate'>
                    <div className='txType'>
                        H2O Rewards
                    </div>
                    <div className='txDate'>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                    </div>
                    { tx.status === 'pending' &&
                        <div className='txStatus'>
                            Pending
                        </div>
                    }
                </div>
                
                <div className='txFlex'> </div>

                <div className='txChange'>
                    <div className={`txGain h2o`}>
                        +{ numeral(tx.amount).format('0,0.000')  } H2O
                    </div>
                </div>
            </Header>

            <Details className='txDetails'>

                <Detail>
                    <label>Summary</label>
                    <div>
                        { tx.summary }
                    </div>
                </Detail>

                <Detail>
                    <label>Date</label>
                    <div>
                    {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                    </div>
                </Detail>

                <Detail>
                    <label>Account</label>
                    <div>
                        <MiddleEllipsis>
                            { tx.by ? (
                                <span>{tx.by}</span>
                            ) : (
                                <span>{tx.from}</span>
                            )}
                        </MiddleEllipsis>
                    </div>
                </Detail>            

                <Detail>
                    <label>Transaction ID</label>
                    <div>
                        <MiddleEllipsis>
                            <span>{tx.transactionHash}</span>
                        </MiddleEllipsis>
                    </div>
                </Detail>
                <Detail>
                    <label></label>
                    <div className='etherscan'>
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    </div>
                </Detail>
            </Details>
        </Content> 
        )
    }

    // distribution H2O
    else if ( tx.transactionType == 'distribution_h2o' ) {
        return (
        <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
            <Header>
                <div className={`txIcon`}>
                    <TokenSymbol symbol='H2O' size={36} />
                </div>
                
                <div className='txTypeDate'>
                    <div className='txType'>
                        Distribution
                    </div>
                    <div className='txDate'>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                    </div>
                </div>

                <div className='txFlex'> </div>

                <div className='txChange'>
                    { (address && tx.to[address]) &&
                        <div className={`txGain h2o`}>
                            +{ numeral(tx.to[address].amount).format('0,0.000') } H2O
                        </div>
                    }
                </div>
            </Header>
            <Details className='txDetails'>

                <Detail>
                    <label>Summary</label>
                    <div>
                        { tx.summary }
                    </div>
                </Detail>

                <Detail>
                    <label>Date</label>
                    <div>
                    {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                    </div>
                </Detail>

                <Detail>
                    <label>Account</label>
                    <div>
                        <MiddleEllipsis>
                            { tx.by ? (
                                <span>{tx.by}</span>
                            ) : (
                                <span>{tx.from}</span>
                            )}
                        </MiddleEllipsis>
                    </div>
                </Detail>            

                <Detail>
                    <label>Transaction ID</label>
                    <div>
                        <MiddleEllipsis>
                            <span>{tx.transactionHash}</span>
                        </MiddleEllipsis>
                    </div>
                </Detail>
                <Detail>
                    <label></label>
                    <div className='etherscan'>
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    </div>
                </Detail>
            </Details>
        </Content>  
        )
    }

    // distribution ICE
    else if ( tx.transactionType == 'distribution_ice' ) {
        return (
        <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
            <Header>
                <div className={`txIcon`}>
                    <TokenSymbol symbol='ICE' size={36} />
                </div>
                
                <div className='txTypeDate'>
                    <div className='txType'>
                        Distribution
                    </div>
                    <div className='txDate'>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                    </div>
                </div>

                <div className='txFlex'> </div>

                <div className='txChange'>
                    { (address && tx.to[address]) &&
                    <div className={`txGain ice`}>
                        +{ numeral(tx.to[address].amount).format('0,0.000') } ICE
                    </div>
                    }
                </div>
            </Header>
            <Details className='txDetails'>

                <Detail>
                    <label>Summary</label>
                    <div>
                        { tx.summary }
                    </div>
                </Detail>

                <Detail>
                    <label>Date</label>
                    <div>
                    {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                    </div>
                </Detail>

                <Detail>
                    <label>Account</label>
                    <div>
                        <MiddleEllipsis>
                            { tx.by ? (
                                <span>{tx.by}</span>
                            ) : (
                                <span>{tx.from}</span>
                            )}
                        </MiddleEllipsis>
                    </div>
                </Detail>            

                <Detail>
                    <label>Transaction ID</label>
                    <div>
                        <MiddleEllipsis>
                            <span>{tx.transactionHash}</span>
                        </MiddleEllipsis>
                    </div>
                </Detail>
                <Detail>
                    <label></label>
                    <div className='etherscan'>
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    </div>
                </Detail>
            </Details>
        </Content>  
        )
    }

    // Swap
    else if ( tx.transactionType === 'Swap' ) {
        const iceWaterClass = TokensClassName(tx.fromToken, tx.toToken)
        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className={`txIcon`}>
                        <FontAwesomeIcon icon={faExchangeAlt} className={`txIcon ${iceWaterClass}_bg`} />
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            Swap
                        </div>
                        <div className='txDate'>
                            {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                        </div>
                    </div>

                    <div className='txFlex'> </div>

                    <div className='txChange'>
                        <div className={`txGain ${tx.toToken.toLowerCase()}`}>
                            { tx.status == 'pending' ? (
                                <span>~</span>
                            ) :(
                                <span>+</span>
                            )}
                            <span>{ numeral(tx.toAmount).format('0,0.000')  } { tx.toToken }</span>
                        </div>
                        <div className={`txLoss`}>
                            -{ numeral(tx.fromAmount).format('0,0.000')  } { tx.fromToken }
                        </div>
                    </div>
                </Header>
                <Details className='txDetails'>

                    <Detail>
                        <label>Summary</label>
                        <div>
                            { tx.summary }
                        </div>
                    </Detail>

                    <Detail>
                        <label>Date</label>
                        <div>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                        </div>
                    </Detail>

                    <Detail>
                        <label>Account</label>
                        <div>
                            <MiddleEllipsis>
                                { tx.by ? (
                                    <span>{tx.by}</span>
                                ) : (
                                    <span>{tx.from}</span>
                                )}
                            </MiddleEllipsis>
                        </div>
                    </Detail>            

                    <Detail>
                        <label>Transaction ID</label>
                        <div>
                            <MiddleEllipsis>
                                <span>{tx.transactionHash}</span>
                            </MiddleEllipsis>
                        </div>
                    </Detail>
                    <Detail>
                        <label></label>
                        <div className='etherscan'>
                            <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledLink>
                        </div>
                    </Detail>
                </Details>
            </Content>  
            )
    }
    
    // buy_cubes
    else if ( tx.transactionType === 'mint_cube' ) {

        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className={`txIcon txCube`}>
                        <TokenSymbol symbol='CUBE' size={28} />
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            Minted Cubes
                        </div>
                        <div className='txDate'>
                            {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                        </div>
                        { tx.status === 'pending' &&
                            <div className='txStatus'>
                                Pending
                            </div>
                        }
                    </div>

                    <div className='txFlex'> </div>

                    <div className='txChange'>
                        <div className={`txGain ice`}>
                            -{ numeral(tx.fromAmount).format('0,0.000')  } { tx.fromToken }
                        </div>
                    </div>
                </Header>
                <Details className='txDetails'>

                    <Detail>
                        <label>Summary</label>
                        <div>
                            { tx.summary }
                        </div>
                    </Detail>

                    <Detail>
                        <label>Date</label>
                        <div>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                        </div>
                    </Detail>

                    { tx.data.recipient &&
                    <Detail>
                        <label>Recipient</label>
                        <div>
                                {tx.data.recipient}
                        </div>
                    </Detail>  
                    }
                    
                    { tx.data.endTime &&
                    <Detail>
                        <label>End Date</label>
                        <div>
                        {DateTime.fromSeconds(parseInt(tx.data.endTime)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                        </div>
                    </Detail>
                    }          

                    <Detail>
                        <label>Transaction ID</label>
                        <div>
                            <MiddleEllipsis>
                                <span>{tx.transactionHash}</span>
                            </MiddleEllipsis>
                        </div>
                    </Detail>
                    <Detail>
                        <label></label>
                        <div className='etherscan'>
                            <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledLink>
                        </div>
                    </Detail>
                </Details>
            </Content>  
            )
    } 
    // redeem_cube
    else if ( tx.transactionType === 'redeem_cube' ) {

        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className={`txIcon txCube`}>
                        <TokenSymbol symbol='CUBE' size={28} />
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            Redeemed Cubes
                        </div>
                        <div className='txDate'>
                            {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                        </div>
                        { tx.status === 'pending' &&
                            <div className='txStatus'>
                                Pending
                            </div>
                        }
                    </div>

                    <div className='txFlex'> </div>

                    <div className='txChange'>
                        <div className={`txGain ice`}>
                            +{ numeral(tx.fromAmount).format('0,0.000')  } { tx.fromToken }
                        </div>
                    </div>
                </Header>
                <Details className='txDetails'>

                    <Detail>
                        <label>Summary</label>
                        <div>
                            { tx.summary }
                        </div>
                    </Detail>

                    <Detail>
                        <label>Date</label>
                        <div>
                        {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                        </div>
                    </Detail>

                    { tx.data && tx.data.recipient && 
                    <Detail>
                        <label>Recipient</label>
                        <div>
                            {/* <MiddleEllipsis> */}
                                {tx.data.recipient}
                            {/* </MiddleEllipsis> */}
                        </div>
                    </Detail>  
                    }
                    
                    { tx.data && tx.data.endTime &&
                    <Detail>
                        <label>End Date</label>
                        <div>
                        {DateTime.fromSeconds(parseInt(tx.data.endTime)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                        </div>
                    </Detail>
                    }          

                    <Detail>
                        <label>Transaction ID</label>
                        <div>
                            <MiddleEllipsis>
                                <span>{tx.transactionHash}</span>
                            </MiddleEllipsis>
                        </div>
                    </Detail>
                    <Detail>
                        <label></label>
                        <div className='etherscan'>
                            <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledLink>
                        </div>
                    </Detail>
                </Details>
            </Content>  
            )
    } 
    // claim_cube_rewards
    else if ( tx.transactionType === 'claim_cube_rewards' ) {

        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className='txIcon'>
                        <TokenSymbol symbol='H2O' size={36} />
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            Cube Rewards
                        </div>
                        { tx.timestamp &&
                            <div className='txDate'>
                                {DateTime.fromSeconds(tx.timestamp).toLocaleString() }
                            </div>
                        }
                    </div>

                    <div className='txFlex'> </div>

                    <div className='txChange'>
                        <div className={`txGain h2o`}>
                            +{ numeral(tx.fromAmount).format('0,0.000')  } { tx.fromToken }
                        </div>
                    </div>
                </Header>

                <Details className='txDetails'>
                    { tx.summary &&
                        <Detail>
                            <label>Summary</label>
                            <div>
                                { tx.summary }
                            </div>
                        </Detail>
                    }

                    { tx.timestamp &&
                        <Detail>
                            <label>Date</label>
                            <div>
                            {DateTime.fromSeconds(tx.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                            </div>
                        </Detail>   
                    }

                    { tx.cubeId &&
                        <Detail>
                            <label>Cube Id</label>
                            <div>
                                { tx.cubeId }
                            </div>
                        </Detail> 
                    }

                    { tx.data && tx.data.owner &&
                        <Detail>
                            <label>Issued From</label>
                            <div>
                                { tx.data.owner }
                            </div>
                        </Detail>      
                    }

                    { tx.transactionHash &&
                        <Detail>
                            <label>Transaction ID</label>
                            <div>
                                <MiddleEllipsis>
                                    <span>{tx.transactionHash}</span>
                                </MiddleEllipsis>
                            </div>
                        </Detail>
                    }

                    { tx.transactionHash &&
                        <Detail>
                            <label></label>
                            <div className='etherscan'>
                                <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                    Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </StyledLink>
                            </div>
                        </Detail>
                    }
                </Details>
            </Content>  
            )
    }
    else if ( tx.transactionType === 'transfer_h2o' ) {
        const iceWaterClass = TokensClassName(tx.fromToken, tx.toToken)
        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className={`txIcon`}>
                        <div className='faWrap'>
                            <FontAwesomeIcon icon={faExchangeAlt} 
                                className={`txIcon ${iceWaterClass}_bg`} />
                        </div>
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            { tx.from.toLowerCase() == address ? (
                                <>
                                Send H2O
                                </>
                            ) : (
                                <>
                                Received H2O
                                </>
                            )}
                        </div>
                    </div>
    
                    <div className='txFlex'> </div>
    
                    <div className='txChange'>
                        <div className={`txGain h2o`}>
                            { tx.from.toLowerCase() == address ? (
                                <>
                                -{ numeral(tx.amount).format('0,0.000')  } { tx.fromToken }
                                </>
                            ) : (
                                <>
                                +{ numeral(tx.amount).format('0,0.000')  } { tx.fromToken }
                                </>
                            )}
                        </div>
                    </div>
                </Header>
                <Details className='txDetails'>
                    <Detail>
                        <label>Summary</label>
                        <div>
                            { tx.summary }
                        </div>
                    </Detail>

                    { tx.from.toLowerCase() == address ? (
                        <Detail>
                            <label>To</label>
                            <div>
                                { tx.to }
                            </div>
                        </Detail> 
                    ) : (
                        <Detail>
                            <label>From</label>
                            <div>
                                { tx.from }
                            </div>
                        </Detail> 
                    )}    

                    <Detail>
                        <label>Transaction ID</label>
                        <div>
                            <MiddleEllipsis>
                                <span>{tx.transactionHash}</span>
                            </MiddleEllipsis>
                        </div>
                    </Detail>
                    <Detail>
                        <label></label>
                        <div className='etherscan'>
                            <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledLink>
                        </div>
                    </Detail>
                </Details>
            </Content> 
        )
    }
    else if ( tx.transactionType === 'transfer_ice' ) {
        const iceWaterClass = TokensClassName(tx.fromToken, tx.toToken)
        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className={`txIcon`}>
                        <div className='faWrap'>
                            <FontAwesomeIcon icon={faExchangeAlt} 
                                className={`txIcon ${iceWaterClass}_bg`} />
                        </div>
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            { tx.from.toLowerCase() == address ? (
                                <>
                                Send ICE
                                </>
                            ) : (
                                <>
                                Received ICE
                                </>
                            )}
                        </div>
                    </div>
    
                    <div className='txFlex'> </div>
    
                    <div className='txChange'>
                        <div className={`txGain ice`}>
                            { tx.from.toLowerCase() == address ? (
                                <>
                                -{ numeral(tx.amount).format('0,0.000')  } { tx.fromToken }
                                </>
                            ) : (
                                <>
                                +{ numeral(tx.amount).format('0,0.000')  } { tx.fromToken }
                                </>
                            )}
                        </div>
                    </div>
                </Header>
                <Details className='txDetails'>
                    <Detail>
                        <label>Summary</label>
                        <div>
                            { tx.summary }
                        </div>
                    </Detail>

                    { tx.from.toLowerCase() == address ? (
                        <Detail>
                            <label>To</label>
                            <div>
                                { tx.to }
                            </div>
                        </Detail> 
                    ) : (
                        <Detail>
                            <label>From</label>
                            <div>
                                { tx.from }
                            </div>
                        </Detail> 
                    )}

                    <Detail>
                        <label>Transaction ID</label>
                        <div>
                            <MiddleEllipsis>
                                <span>{tx.transactionHash}</span>
                            </MiddleEllipsis>
                        </div>
                    </Detail>
                    <Detail>
                        <label></label>
                        <div className='etherscan'>
                            <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledLink>
                        </div>
                    </Detail>
                </Details>
            </Content> 
        )
    } else if (['auction_pos_bid', 
                'auction_neg_bid', 
                'auction_pos_start', 
                'auction_neg_start', 
                'auction_pos_end', 
                'auction_neg_end'].includes(tx.transactionType)) {
        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className='txIcon'>
                        <TokenSymbol symbol='H2O' size={36} />
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            Auction Transaction
                        </div>
                    </div>
    
                    {/* <div className='txFlex'> </div>
    
                    <div className='txChange'>
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    </div> */}
                </Header>
                <Details className='txDetails'>
                    <Detail>
                        <label>Summary</label>
                        <div>
                            { tx.summary }
                        </div>
                    </Detail>
                    <Detail>
                        <label></label>
                        <div className='etherscan'>
                            <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                                Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </StyledLink>
                        </div>
                    </Detail>
                </Details>
            </Content> 
        )
    }
     else {
        return (
            <Content className={open ? 'open' : ''} onClick={() => {setOpen(!open)}}>
                <Header>
                    <div className={`txIcon none`}>
                        <div className='faWrap'>
                            <FontAwesomeIcon icon={faQuestion} />
                        </div>
                    </div>
                    
                    <div className='txTypeDate'>
                        <div className='txType'>
                            Unknown Transacton
                        </div>
                    </div>
    
                    <div className='txFlex'> </div>
    
                    <div className='txChange'>
                        <StyledLink href={`${EtherscanDomain()}/tx/${tx.transactionHash}`}>
                            Etherscan <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </StyledLink>
                    </div>
                </Header>
            </Content> 
        )
    }

    
}

export default Transaction

const Content = styled.div`
    cursor: pointer;
    div {
        cursor: pointer;
    }

    .txDetails {
        display: none;
    }

    &.open {
        .txDetails {
            display: block;
        }
    }

    .h2o {
        color: ${props => props.theme.color.h2o};
    }
    .ice {
        color: ${props => props.theme.color.ice};
    }
    .steam {
        color: ${props => props.theme.color.steam};
    }

    ${MobileStyles(`

    `)};
`;


const Header = styled.div `
    display: flex;
    align-items: center;

    padding: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);

    &:hover {
        
    }

    .txCube {
        margin: 0px 5px;
    }

    .txIcon {
        padding-right: 10px;
        .faWrap {
            width: 36px;
            height: 36px;
            background: red;
            background: #595c61;
            border-radius: 50%;
            text-align: center;
        }
        svg {
            border-radius: 50%;
            padding: 10px;
        }
        &.none {
            svg {
                //
            }
        }
    }

    .txTypeDate {
        width: 150px;
    }

    .txStatus {
        font-size: 12px;
        text-transform: uppercase;
        color: ${props => props.theme.color.ice};
    }

    .txFlex {
        flex: 1;
    }

    .txChange {
        text-align: right;
    }

    .txGain {
        font-weight: bold;
        filter: brightness(1.2);
    }

    .txDate, .txLoss {
        opacity: .4;
        font-size: 90%;
    }

    .txFrom {

    }
`

const Status = styled.div `

`

const Details = styled.div `
    padding: 10px 15px 25px 60px; 
    ${MobileStyles(`
        padding-left: 15px;
    `)};
`


const Detail = styled.div `
    margin-bottom: 10px;
    label {
        display: block;
        width: 150px;
        float: left;
    }
    > div {
        overflow: hidden;
        text-align: right;
        opacity: .5;
    }
    :after {
        content: "";
        clear: both;
    }
    ${MobileStyles(`
        font-size: 90%;
        label {
            width: 100px;
        }
    `)};
    .etherscan {
        opacity: 1;
        svg {
            margin-left: 5px;
            font-size: 14px;
        }
    }
`