//import './NavBar.css';
import React, { useContext } from 'react';
import { useLocation } from "react-router-dom";
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {  } from '@fortawesome/free-regular-svg-icons';

import TxPendingCount from './TxPendingCount';

import Logo from '../Logo'
import AccountButton from '../AccountButton'
import { AccountContext } from '../../contexts/Account/AccountContext'

import SideMenu, { SideMenuItem, SideMenuHeader } from 'components/SideMenu';
import { Desktop, Mobile } from "components/Responsive";

import { WalletContext } from 'contexts/Account/WalletContext';
import { MobileStyles } from 'theme/Mixins';
import useiceWater from '../../hooks/useIceWater';
import TabBar from './TabBar';
import Button from 'components/Button';

import config from 'config'

interface NavBarProps {
    children?: React.ReactNode
}
const NavBar: React.FC<NavBarProps> = ({
    children
}) => {
    const { transactions, refreshBalances } = useContext(AccountContext); 
    const { address, chainId } = useContext(WalletContext)
    const iceWater = useiceWater()

    return (
        <>
        <SideMenu />

        <Content>
            <NavBarWrap>
                <NavBarLeft>
                    { children ? (
                        <>{ children }</>
                    ):(
                        <Logo to='/' />
                    )}
                </NavBarLeft>
                <NavBarCenter>
                    <TabBar />
                </NavBarCenter>
                <NavBarRight>
                    { (address && transactions.length > 0 ) &&
                        <Pending>
                            <TxPendingCount/>
                        </Pending>
                    }

                    { address ? (
                        <>
                        <Desktop>
                            <NavButton className='nav-button'>
                                <Button variant='empty' to='/account' size='sm' 
                                    text={<AccountAddress>
                                        <FontAwesomeIcon icon={faCircle} /> 
                                        { address }
                                    </AccountAddress>}>
                                </Button>
                            </NavButton>
                        </Desktop>
                        </>
                    ):(
                        <>
                            <AccountButton />
                        </>
                    )}
                </NavBarRight>
            </NavBarWrap>
            { chainId !== config.chainId &&
                <div className='wrongChain'>
                    Wrong Chain. Switch to Optimism.
                </div>
            }
        </Content>
        </>
    )
}
export default NavBar


const Pending = styled.div `
    margin-right: 15px;
`

const NavButton = styled.div `
    button {
        padding-left: 10px;
        padding-right: 10px;
    }
    &.rspace {
        margin-right: 15px;
    }
`

const Content = styled.div `
    z-index: 15;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    color: ${(props) => props.theme.color.white};
    .wrongChain {
        padding: 10px;
        width: 100%;
        text-align: center;
        font-weight: bold;
        background: ${props => props.theme.color.ice};
    }
`

const NavBarWrap = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0px 20px;
    background-color: ${props => props.theme.nav.backgroundColor};
`

const NavBarLeft = styled.div `
   ${MobileStyles(`
        .logo img {
            height: 26px !important;
        }
        padding-left: 30px;
    `)};
`

const NavBarCenter = styled.div `
    
`

const NavBarRight = styled.div `
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
        margin-left: 10px;
    }
`

const AccountAddress = styled.div `    
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px 5px;
    display: inline-block;
    
    svg {
        position: relative;
        top: -1px;
        font-size: 10px;
        color: ${(props) => props.theme.color.h2o};
        margin-left: 0px;
        margin-right: 8px;
    }
    ${MobileStyles(`
        width: 85px;
    `)};
`