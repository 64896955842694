import React, { MouseEvent, useState, useEffect, useContext, useCallback, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'
import Button from 'components/Button';
import TokenSymbol from 'components/TokenSymbol';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Spacer from 'components/Spacer';
import useModal from 'hooks/useModal';
import SimpleModal from 'components/SimpleModal';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WalletContext } from 'contexts/Account/WalletContext';

import numeral from 'numeral';
import { AccountContext } from 'contexts/Account/AccountContext';
import AccountButton from 'components/AccountButton';

type Inputs = {
    amount: string,
    address: string,
    redeemDate: any, // javascript date object
    insufficentBalance: any
};

interface Props {
    onSubmit: (data:Inputs) => void,
}

const CubesForm: React.FC<any> = forwardRef(({
    onSubmit
}, resetRef) => {
    const { address, connected } = useContext(WalletContext)
    const { balances } = useContext(AccountContext)
    const [amount, setAmount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const { register,
            handleSubmit,
            formState: { isSubmitSuccessful, errors },
            control,
            reset,
            setValue,
            setError,
            clearErrors} = useForm<Inputs>();  
            
    
    const submitForm = useCallback((data: Inputs) => {  
        const seconds = Math.floor(data.redeemDate.getTime()/1000)
        onSubmit({
            amount: parseFloat(data.amount),
            address: data.address,
            redeemDate: seconds
        })
        setLoading(true)
    }, [onSubmit]);

    useEffect(() => {
        if ( amount > parseFloat(balances.ice) ) {
            setError("insufficentBalance", {
                type: "manual",
                message: "You don't have enough ICE."
            })
        } else {
            clearErrors('insufficentBalance')
        }
    }, [address, connected, amount]);

    useImperativeHandle(resetRef, () => ({
        resetForm, resetLoading
    }));

    const resetForm = () => {
        reset() 
        clearErrors()
        setLoading(false)
    }

    const resetLoading = () => {
        setLoading(false)
    }

    const OpenCal = () => {
        redeemDateRef.current.setFocus(true);
    }
    
    const amountRef = useRef<HTMLInputElement | null>(null);
    const amountReg = register("amount", { 
        required: "Enter the amount of H2O you want to freeze."             
    })

    const redeemDateRef = useRef<any>(null);
    const redeemDateReg = register("redeemDate", { 
        required: "Redemption date is required."             
    })

    const addressRef = useRef<HTMLInputElement | null>(null);
    const addressReg = register("address", { 
        required: "Recipient wallet address is required."             
    })

    const [onPresentRedeemDateModal] = useModal(
        <SimpleModal title='Redemption Date'>
            The date that you can redeem your ICE CUBE.
        </SimpleModal>
    )

    const onAmountChange = (e: MouseEvent<HTMLButtonElement>) => {
        const value:string = (e.currentTarget.value).replace(/[^0-9\.]/g, "")
        setAmount(parseFloat(value))
    }

    const onAmountBlur = (e: MouseEvent<HTMLButtonElement>) => {
        const value:string = (e.currentTarget.value).replace(/[^0-9\.]/g, "")
        const amount:number = parseFloat(value);
        setValue('amount', numeral(amount).format('0,0.00'), {
            shouldValidate: true // this used to be false, why?
        })
    }

    return (
    <Content>
        <SwapCardWrap>  
            <Card>                            
                <form onSubmit={handleSubmit(submitForm)}>

                    <Row>
                        <Col>
                            <StyledInputLabel>Issue Cubes</StyledInputLabel>
                            <InputFlexGroup>
                                <TokenPicker>
                                    <TokenSymbol symbol='ICE' />  
                                    <span>ICE</span>
                                </TokenPicker>
                                <InputWrap className='sqLeft'>
                                    <input type='text'
                                        {...amountReg}
                                        ref={(e) => {
                                            amountReg.ref(e)
                                            amountRef.current = e
                                        }}
                                        onChange={(e:any) => onAmountChange(e) }
                                        onBlur={(e:any) => onAmountBlur(e) }
                                        />
                                </InputWrap>
                            </InputFlexGroup>
                        </Col>
                        <Col>
                            <DatePickerWrap>
                                <div className='datepicker-label'>
                                    <h4>Redemption Date</h4>
                                    <span onClick={onPresentRedeemDateModal}>
                                        <FontAwesomeIcon icon={faInfoCircle} />    
                                    </span> 
                                </div>
                                <div className='datepicker-content'>
                                    <div className='datepicker'>
                                        <InputTypeSuffix>
                                            <div className='inputTypeWrap'>
                                                <div className='inputType' onClick={OpenCal}>
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </div>
                                                <div className='inputTypeInput'>
                                                    <Controller
                                                    control={control}
                                                    name="redeemDate"
                                                    render={({ field }:any) => (
                                                        <ReactDatePicker
                                                            selected={field.value}
                                                            onChange={(e:any) => field.onChange(e)}
                                                            minDate={new Date()}
                                                            dateFormat="MM/dd/yyyy"
                                                            ref={redeemDateRef}
                                                        />
                                                    )}
                                                />
                                                </div>
                                            </div>
                                        </InputTypeSuffix>
                                    </div>
                                    {/* <div className='daysAway'>
                                        35 days from today
                                    </div> */}
                                </div>
                            </DatePickerWrap>
                        </Col>
                    </Row>

                    <Spacer size='sm' />

                    <StyledInputLabel>Recipient Address</StyledInputLabel>
                    <InputWrap>
                        <input type='text' className='address'
                            {...addressReg}
                            ref={(e) => {
                                addressReg.ref(e)
                                addressRef.current = e
                            }}
                            />
                    </InputWrap>

                    <Spacer size='md' />

                    { errors.insufficentBalance &&
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                { errors.insufficentBalance.message }
                            </div>
                        </InputError>       
                    }
                    
                    { errors.amount &&
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                {errors.amount.message}
                            </div>
                        </InputError>       
                    } 

                    { errors.redeemDate &&
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                {errors.redeemDate.message}
                            </div>
                        </InputError>       
                    } 

                    { errors.address &&
                        <InputError>
                            <div className="error">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                {errors.address.message}
                            </div>
                        </InputError>       
                    }

                    { address ? (
                        <>
                            { Object.keys(errors).length > 0 ? (
                                <Button type='button' disabled={true} variant={'disabled'}>
                                    Mint Cubes
                                </Button> 
                            ) : (
                                <Button type={!loading ? 'submit' : 'button'} 
                                        variant={'water_ice'} 
                                        loading={loading} >
                                    Mint Cubes
                                </Button>   
                            )}
                        </>
                    ) : (
                        <AccountButton size='md' />
                    ) }
                </form>
            </Card>
        </SwapCardWrap> 
    </Content>
  )
})

export default CubesForm

const Row = styled.div `
    display: flex;
    ${MobileStyles(`
        display: block;
    `)};
`
const Col = styled.div `
    &:first-child {
        margin-right: 10px;
        flex: 1;
    }
    &:last-child {
        margin-left: 10px;
        width: 200px;
    }
    ${MobileStyles(`
        &:last-child {
            margin-left: 0px;
        }
    `)};
`

const Content = styled.div `
    h4 {
        margin: 0 0 10px 0;  
    } 
`

const StyledInputLabel = styled.h4`    
  margin: 0 0 10px 0;  
`

const SwapCardWrap = styled.div `
    display: block;
    width: 500px;
    margin: 0px auto;
    ${MobileStyles(`
        display: block;
        width: 90%;
    `)};
`

interface StyleProps {

}

const Card = styled.div<StyleProps>`  
    position: relative;
    padding: ${(props) => props.theme.spacing[3]}px;
    color: ${(props) => props.theme.color.white};
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 
    ${MobileStyles(`
        width: auto;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        margin-bottom: 25px;
    `)};

    form {
        button.unavailable {
            color: #aaa;
            background: rgb(34,34,34);
            background: linear-gradient(180deg,rgb(25 25 25) 0%,rgb(23 23 23) 100%)
        }

        .error {
            color: ${props => props.theme.color.h2o};      
            margin: 10px 0px 0px 3px;
         
            svg {
                margin-right: 10px;
            }
        }
        input.address {
            text-align: left;
            margin-left: 5px;
            //font-size: 16px !important;
        }
        input {  
            text-align: right;  
            background: none;
            border: 0;
            color: ${props => props.theme.color.white};
            font-size: 21px !important;
            height: 61px !important;
            margin: 0;
            padding: 0;
            outline: none;
            width: 100%;
    
            ${MobileStyles(`
                font-size: 18px !important;
            `)};
         }
        
        input::placeholder {
            color: rgba(255, 255, 255, 0.5);    
        }
         
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
`;

const MarketCard = styled.div `
    flex: 1;
    padding: ${(props) => props.theme.spacing[3]}px;
    color: ${(props) => props.theme.color.white};
    -webkit-border-top-right-radius: 15px;
    -webkit-border-bottom-right-radius: 15px;
    -moz-border-radius-topright: 15px;
    -moz-border-radius-bottomright: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: rgba(100, 100, 100, 0.1);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 

    ${MobileStyles(`
        background: none;
        border: none;
        background-color: none;
        box-shadow: none;
        backdrop-filter: none; 
        -webkit-backdrop-filter: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
    `)};

    h4 {
        font-weight: bold;
    }
`


const InputFlexGroup = styled.div` 
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
`


const TokenPicker = styled.div`  
    width: 80px;
    height: 56px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: default;
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-top-left-radius: ${props => props.theme.borderRadius}px;
    border-bottom-left-radius: ${props => props.theme.borderRadius}px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    position: relative;

    // &:hover {
    //     background-color: rgba(255, 255, 255, 0.2);
    // }

    img {
        width: 35px;
        height: 35px;
        display: inline;
        vertical-align: middle;
        margin-right: 10px;
    }

    span {
        font-weight: bold;
    }

    svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0%, -50%);
    }

    svg.upsidedown {
        transform: rotate(180deg);
        margin-top: -8px;
    }
`


const InputWrap = styled.div` 
    flex: 1;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-border-radius: ${props => props.theme.borderRadius}px;
    -moz-border-radius: ${props => props.theme.borderRadius}px;
    border-radius: ${props => props.theme.borderRadius}px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 10px;
    padding-left: 10px;

    &.sqLeft {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    
`

const InputError = styled.div` 
    margin-bottom: 25px;
`

const DatePickerWrap = styled.div `
    .datepicker-label {
        display: block;
       margin-bottom: 10px;
       h4 {
            display: inline;
       }
    
       span {
           margin-left: 5px;
           cursor: pointer;
           opacity: .3;
           transition: opacity 0.2s ease;
           &:hover {
               opacity: .8;
           }
       }
    }

    .datepicker-content {
        display: flex;
        .datepicker {
            width: 200px;
        }
        input {
            text-align: right !important;
        }
        .daysAway {
            flex: 1;
            padding-left: 20px;
            display: flex;
            align-items: center;
        }
    }
`


const InputTypeSuffix = styled.div`


    .inputTypeWrap {
        position: relative;

        display: flex;

        .inputTypeInput {
            flex: 1;
            padding-right: 10px;
        }

        input {
            margin: 0px;
            padding: 0px;
            padding-right: 10px;
            text-align: right;
            background-color: rgba(255, 255, 255, 0.1);
            
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-left: 1px solid rgba(0, 0, 0, 0.2);

            border-top-right-radius: ${props => props.theme.borderRadius}px;
            border-bottom-right-radius: ${props => props.theme.borderRadius}px;
        }

        .inputType {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.15);
            border: 3px solid rgba(255, 255, 255, 0.1);
            display: flex;
            align-items: center;
            justify-content: left;
            padding: 0px 15px;
            border-top-left-radius: ${props => props.theme.borderRadius}px;
            border-bottom-left-radius: ${props => props.theme.borderRadius}px;
            &:hover {
                background-color: rgba(255, 255, 255, 0.35);
            }
        }
    }

`
