import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AccountProvider } from './contexts/Account/AccountContext';
import { WalletContext, WalletProvider } from './contexts/Account/WalletContext';

import IceWaterProvider from './contexts/IceWaterProvider';
import ModalsProvider from './contexts/Modals';
import Swap from './views/Swap';
import store from './state';
import theme from './theme';
import Updaters from './state/Updaters';
import Popups from './components/Popups';

import firebaseApp from './firebase';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'components/Alert';
import TagManager from 'react-gtm-module';

// Firebase Emulator Requirements
// import { getApp } from "firebase/app";
// import { getDatabase, connectDatabaseEmulator } from "firebase/database";
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { getAuth, connectAuthEmulator } from "firebase/auth";
import Cubes from 'views/Cubes';
import Auction from 'views/Auction';
import Account from 'views/Account/Account';
import Transactions from 'views/Transactions/Transactions';
import Docs from 'views/Docs/Docs';

if ( window.location.hostname === 'localhost' ) {
    /*
    const auth = getAuth()
    connectAuthEmulator(auth, "http://localhost:9099")

    const db = getDatabase()
    connectDatabaseEmulator(db, "localhost", 9000);

    const functions = getFunctions(getApp());
    connectFunctionsEmulator(functions, "localhost", 5001);
    */
} else {
    const tagManagerArgs = {
        gtmId: 'GTM-K7QJBZ9'
    }
    TagManager.initialize(tagManagerArgs)
}

const App: React.FC = () => {
    return (
    <Providers>
        <Switch>
          <Route path="/" exact>
              <Swap />
          </Route>
          <Route path="/account" exact>
              <Account />
          </Route>
          <Route path="/auction" exact>
            <Auction />
          </Route>
          <Route path="/cubes" exact>
            <Cubes />
          </Route>
          <Route path="/docs" exact>
            <Docs />
          </Route>
          <Route path="/transactions" exact>
            <Transactions />
          </Route>
        </Switch>
    </Providers>
  );
};


const Providers: React.FC = ({ children }) => {
  const firebase = firebaseApp

  const options = {
    position: positions.BOTTOM_CENTER,
    timeout: 10000,
    ///offset: '80px',
    transition: transitions.SCALE,
    containerStyle: {}
  }

  return (
    <ThemeProvider theme={theme}>
        <WalletProvider>
            <AlertProvider template={AlertTemplate} {...options}>
            <Provider store={store}>
                <Updaters />
                <IceWaterProvider>
                    <ModalsProvider>
                        <AccountProvider>
                        <>
                            <Popups />
                            {children}
                        </>
                        </AccountProvider>
                    </ModalsProvider>
                </IceWaterProvider>
            </Provider>
            </AlertProvider>
      </WalletProvider>
    </ThemeProvider>
  );
};

export default App;

interface AlertTypes {
  account: string,
  h2o: string,
  ice: string,
  steam: string,
  value: number
}




/*

let greet: Function;
greet = () => {
  console.log("hello")
}

// third argument is optional
const add = (a: number, b: number, c?: number | string) => {
  console.log(a + b);
}
add(5, 10);

// inferred return type
const minus = (a: number, b: number) => {
  return a - b;
}
let result = minus(10, 7)

// explicitly setting the return type.
const multiply = (a: number, b: number): number => {
  return a * b;
}
result = multiply(10, 7)
console.log("multiply: " + result)

// function with callback
function addWithCallback(a:number, b:number, cb: (label:string, val:any) => void ){  
  cb('addWithCallback: ', a + b)
}

function prettyPrint(label: string, val: any) {
  console.log(`${label} :: ${val}`)
}
addWithCallback(10, 7, prettyPrint)

addWithCallback(3, 5, (label: string, val: any): void => {
  console.log(`${label} :: ${val}`)
})

// class with static functions
class MyStaticClass {
  static addOneTo(val: number) {
    return 1 + val;
  }
}
result = MyStaticClass.addOneTo(3)

*/