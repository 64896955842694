import { black, purple, blue, teal, grey, red, white, box, green } from './colors'

const theme = {
  borderRadius: 12,
  color: {
    black,
    grey,
    purple,
    blue,
    red,
    green: green,
    primary: {
      light: blue[200],
      main: blue[400],
      box: '#1b1b1b'
    },
    secondary: {
      main: blue[400],
    },
    white,
    teal,
    h2o: '#24a9a3',
    ice: '#0191c7',
    steam: '#a54381',
  },
  nav: {
    backgroundColor: '#1f2223'
  },
  card: {
    backgroundColor: '#383d42'
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,//72,
  subTopBarSize: 50,
  botBarSize: 72,
  gradients: {
    icewatersteam: "linear-gradient(to right, #24a9a3, #0191c7, #a54381)",
    water_ice_steam: "#0191c7, #24a9a3, #a54381",
    water_ice: "#24a9a3, #0191c7",
    water_steam: "#24a9a3, #a54381",
    ice_water: "#0191c7, #24a9a3",
    ice_steam: "#0191c7, #a54381",
    steam_water: "#a54381, #24a9a3",
    steam_ice: "#a54381, #0191c7"
  },
  table: {
    borderColor: '#4a4f54',
    borderColorBold: '#616370',
    headerBackground: '#2f3538',
    oddRowBackground: '#25282b',
    evenRowBackground: '#2b3033',
  },
}

export default theme