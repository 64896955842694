import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

interface Props {

}

export const TabBar: React.FC<Props> = ({

}) => { 
    return (
      <Content>
            <div className='inner'>

                <div className='left'>
                    <Tab label="Swap" link='/' />
                    <Tab label="Auction" link='/auction'/>
                    <Tab label="CUBES" link='/cubes' />

                    <a href='https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x86247AA4D1313700aaCB30B406F7dE8E98cD97c1'
                        target='_blank' 
                        rel='nofollow'
                        className='geth2o'>
                        Get H2O <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </a>
                </div>

                {/* <div className='center'>

                </div>
            
                <div className='right'>
                    <Tab label="Account" link='/rewards' />
                </div> */}
            </div>
      </Content>
    );
}


export default TabBar

const Content = styled.div `
    position: relative;   
    background: #222;
    padding-top: 10px;

    .geth2o {
        color: inherit;
        text-decoration: none;
        position: relative;
        padding: 5px 10px;
        opacity: .8;
        svg {
            position: relative;
            top: -3px;
            margin-left: 2px;
            font-size: 12px;
            
        }
        &:hover {
            opacity: 1;
        }
    }

    .inner {
        max-width: 800px;   
        margin: 0px auto; 
        display: flex;
        
        .left {
            display: flex;
            align-items: left;
        }

        .center {
            flex: 1;
        }
    
        .right {
            display: flex;
            align-items: right;
        }
    }

    //background: ${props => props.theme.gradients.icewatersteam};
    
    ${MobileStyles(`
        display: none;
        .inner {
            width: 100%;
        }
    `)};

`

interface TabProps {
    label: string,
    link: string
}

const Tab: React.FC<TabProps> = ({
    label,
    link
}) => { 
    const location = useLocation();
    const isActive = location.pathname === link;

    return (
        <TabContent className={isActive ? 'active' : 'inactive'}>
            <Link to={link}>
                { label }
            </Link>
            <div className='bar' />
        </TabContent>
    );
}

const TabContent = styled.div `
    position: relative;
    cursor: pointer;
    
    a {
        display: block;
        padding: 5px 15px 15px 15px;
        color: inherit;
        text-decoration: none;
    }

    &.inactive {
        opacity: .8
    }

    &:hover {
        opacity: 1;
    }

    .bar {
        position: absolute;
        left: 7px;
        right: 7px;
        bottom: 8px;
        height: 3px;
        background: ${props => props.theme.gradients.icewatersteam};
        display: none;
        border-radius: 2px;
    }

    &.active {
        .bar {
            display: block;
        }
        font-weight: bold;
    }
`
