import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import Modal, { ModalProps } from '../../components/Modal';
import ModalTitle from '../../components/ModalTitle';
import ModalActions from '../../components/ModalActions';
import { AccountContext, TransactionType } from '../../contexts/Account/AccountContext';
import config from 'config';
import Transaction from 'views/Account/components/Transaction';
//import Transaction from 'views/Transactions/components/Transaction';

interface ConnectModalProps extends ModalProps {
  onConfirm: (data:any) => void,
  onDismiss: () => void,
  transactions: TransactionType[]
}

const TransasctionsModal: React.FC<ConnectModalProps> = ({ onConfirm, onDismiss, transactions }) => {
  return (
    <Modal >
      <ModalTitle text="Pending Transactions"/>

      <h4>{ transactions.length } Pending</h4>
      <Message>
        {transactions.map((tx:TransactionType, index:number) => (
            <Tx key={tx.transactionHash}>
                <Transaction tx={tx} isOpen={true} />
            </Tx>
        ))}
      </Message>   
      
      <ModalActions>
        <Button size='md' text="Close" variant="water_ice" onClick={onDismiss}  />
      </ModalActions>     
    </Modal>
  )
}

const Message = styled.div `

`

const Tx = styled.div `

`

export default TransasctionsModal