
//import './NavBar.css';
import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components'
import { AccountContext } from '../../contexts/Account/AccountContext'

// Modals
import useModal from '../../hooks/useModal';
import TransactionsModal from './TransactionsModal'


var _ = require('lodash');



interface Props {

}
export const TxPendingCount: React.FC<Props> = ({}) => { 
    const { transactions } = useContext(AccountContext);

    const [onPresent, onDismiss] = useModal(
        <TransactionsModal 
            transactions={transactions}
            onConfirm={() => {
                onDismiss()
            }}
            onDismiss={() => {
      
            }}
        />
    )

    return (
        <NavItemWrap>
            <TxPending onClick={onPresent}>
                <div className='border'></div>
                <div className='count'>
                { transactions.length }
                </div>
            </TxPending>
        </NavItemWrap>
    );
}

export default TxPendingCount

const NavItemWrap = styled.div `
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const TxPending = styled.div `
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: ${(props) => props.theme.color.white};

    @keyframes rotating {
        from{
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes rotating {
        from{
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        to{
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .border {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -3px;
        left: 0px;
        border-radius: 50%;
        padding: 5px;
        animation: rotating 2s infinite linear;
        -webkit-animation: rotating 2s linear infinite;
        // background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.water_ice_steam});
        // background: -moz-linear-gradient(to right, ${props => props.theme.gradients.water_ice_steam});
        // background: linear-gradient(to right, ${props => props.theme.gradients.water_ice_steam});
        background: linear-gradient(0deg, ${props => props.theme.color.h2o} 23%, ${props => props.theme.color.ice} 66%, ${props => props.theme.color.steam} 33%);
    }

    .count {
        position: absolute;
        top: -2px;
        left: 1px;
        width: 22px;
        height: 22px;
        background: ${props => props.theme.nav.backgroundColor};
        border-radius: 50%;
        padding: 5px;
        margin: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: filter 300ms;
        font-weight: bold;
    }
    
    :hover {
        filter: brightness(1.2);
    }
`