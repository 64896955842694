import React from 'react';
import TxClaimReward from './TxClaimReward';
import TxSwap from './TxSwap';
import TxTransfer from './TxTransfer';
import TxMintCube from './TxMintCube';
import TxRedeemCube from './TxRedeemCube';
import TxCloseAuction from './TxCloseAuction';
import TxCubeReward from './TxCubeReward';

interface Props {
    tx: any
}

const Transaction: React.FC<Props> = ({
    tx
}) => {
    if ( tx.event == 'ClaimRewards') {
        return (
            <TxClaimReward tx={tx} />
        )
    }

    if ( tx.event == 'ClaimRewardsFromCube') {
        return (
            <TxCubeReward tx={tx} />
        )
    }

    if ( tx.event == 'RedeemCube') {
        return (
            <TxRedeemCube tx={tx} />
        )
    }

    if ( tx.event == 'CloseAuction') {
        return (
            <TxCloseAuction tx={tx} />
        )
    }
    
    if ( tx.event == 'MintCube') {
        return (
            <TxMintCube tx={tx} />
        )
    }

    if ( tx.event == 'Transfer') {
        return (
            <TxTransfer tx={tx} />
        )
    } 

    if ( tx.event == 'Swap') {
        return (
            <TxSwap tx={tx} />
        )
    }  
    
    return (
        <></>
    )
}

export default Transaction
