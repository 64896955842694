import React from 'react'
import styled from 'styled-components'


interface CardData {  
  variant?: 'default' | 'glass' | 'dark',
  className?: string,
  padding?: number
}

const Card: React.FC<CardData> = ({ children, variant="default", className='', padding=30}) => {
  return (
    <StyledCard padding={padding} className={`${variant} ${className} card`}>
      {children}
    </StyledCard>
  )
  }

interface StyledCardProps {
    padding: number
}


const StyledCard = styled.div<StyledCardProps>`  
  border-radius: 15px;  
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${props => props.padding}px;
  

  &.default {
    background-color: ${(props) => props.theme.card.backgroundColor};
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1); 
  }
  
  &.glass {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 20px 0px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px);  
  }

  &.dark {
    background-color: ${(props) => props.theme.nav.backgroundColor};
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);  
  }
`

export default Card