import React, { Children, createContext, useCallback, useState } from 'react'
import styled from 'styled-components'

interface ModalsContext {
  content?: React.ReactNode,
  isOpen?: boolean,
  onPresent: (content: React.ReactNode) => void,
  onDismiss: () => void
}

export const Context = createContext<ModalsContext>({
  onPresent: () => {},
  onDismiss: () => {},
})

const Modals: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<React.ReactNode>()

  const handlePresent = useCallback((modalContent: React.ReactNode) => {
    setContent(modalContent)
    setIsOpen(true)
    document.getElementById("nonModal").classList.add("fixed");
  }, [setContent, setIsOpen])

  const handleDismiss = useCallback(() => {
    setContent(undefined)
    setIsOpen(false)
    document.getElementById("nonModal").classList.remove("fixed");
    //console.log('dismissed')
  }, [setContent, setIsOpen])

  // const childrenWithProps = React.Children.map(children, (child:any, index) => {
  //   return React.cloneElement(child, {
  //     top: top
  //   });
  // });

  return (
    <Context.Provider value={{
      content,
      isOpen,
      onPresent: handlePresent,
      onDismiss: handleDismiss,
    }}>
      <NonModalContent id='nonModal'>
      {children}
      </NonModalContent>
      {isOpen && (
        <StyledModalWrapper className='styledModalWrapper'>
          <StyledModalBackdrop onClick={handleDismiss} />
          <StyledModal>

            { React.isValidElement(content) && 
              React.cloneElement(content, {
                onDismiss: handleDismiss
              })
              // https://victorofoegbu.com/notes/pass-props-to-react-children-faq
              // { Children.map(props.children, (child) =>
              //   React.cloneElement(child, props)
              // )}
            }
          </StyledModal>
        </StyledModalWrapper>
      )}
    </Context.Provider>
  )
}

const NonModalContent = styled.div `
  &.fixed {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
  }
`

const StyledModal = styled.div `
  position: relative;
  margin: 50px 0px;
`

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 26;
`

const StyledModalBackdrop = styled.div`
  background-color: #000000ab;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
`


export default Modals