import React, { useContext, useEffect, useImperativeHandle, forwardRef, useState } from 'react'
import styled from 'styled-components'
import { MobileStyles } from 'theme/Mixins';
import { WalletContext } from 'contexts/Account/WalletContext';
import { IceWaterContext } from 'contexts/IceWaterProvider';
import { DateTime } from "luxon";
import Web3 from 'web3';
import numeral from 'numeral';
import useInterval from 'hooks/useInterval';
import config from 'config';
import AuctionPayout from './AuctionPayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { Desktop, Mobile } from "components/Responsive";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import useModal from 'hooks/useModal';
import SimpleModal from 'components/SimpleModal';
import Spacer from 'components/Spacer';
import { AuctionProps } from './Auction';

type Props = {
    active: boolean,
    surplus: number | null,
    auction: AuctionProps
}

const AuctionStats: React.FC<any> = forwardRef(({ 
    active,
    surplus,
    auction
}, resetRef) => {
    const { address } = useContext(WalletContext)
    
    function truncate(str:string, n:number){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    };

    useImperativeHandle(resetRef, () => ({
        refresh,
    }));

    const refresh = () => {
        
    }

    const [showRaiseModal] = useModal(
        <SimpleModal title='Increase H2O' top='200'>
            There's currently a surplus of { numeral(surplus).format('0,0.00') } H2O in the protocol. 
            <Spacer size='md' />
            When there's a surplus of H2O, you may choose to raise the auction's burn amount by any amount less than or equal to the surplus.
            <Spacer size='md' />
            When you make a bid the H2O is immediately withdrawn from your wallet and burned by the protocol. If someone outbids you, your H2O will be immediately returned to your wallet. If you win the auction you will receive the ETH payout.
        </SimpleModal>
    )
        
return (
    <Content className='stats'>

        <div className='current'>
            <AuctionPayout auction={auction} amount={auction.h2o} active={active} />

            <Desktop>
                <div className='leader'> 
                    <div className='leaderVal'>
                        { auction.type === 'positive' ? (
                        <label>
                            Leading Bid
                        </label>
                        ) : (
                        <label>
                            Lowest Bid
                        </label>
                        )}
                        <div className='leaderAmt'>
                        { auction.eth !== null ? (
                            <>{numeral(auction.eth).format('0,0.000')} ETH</>
                        ) : (
                            <>--</>
                        )}
                        </div>
                    </div>
                    <div className='eth'>
                        <FontAwesomeIcon icon={faEthereum} />    
                    </div> 
                </div>
            </Desktop>
        </div>
        
        <div className='hr' />

        <div className='rows'>

            <Mobile>

                { auction.type === 'positive' ? (
                    <div className='row'>
                        <div className='label'>
                            Leading Bid
                        </div>
                        <div className='value'>
                            { auction.h2o !== null ? (
                                <div className={(address && auction.leadingBidder 
                                    && address.toLocaleLowerCase() == auction.leadingBidder.toLowerCase()) 
                                    ? 'winning' : ''}>
                                    {numeral(auction.h2o).format('0,0.000')} H2O
                                </div>
                            ) : (
                                <>--</>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='row'>
                        <div className='label'>
                            Lowest Bid
                        </div>
                        <div className='value'>
                            { auction.eth !== null ? (
                                <div className={(address && auction.leadingBidder 
                                    && address.toLocaleLowerCase() == auction.leadingBidder.toLowerCase()) 
                                    ? 'winning' : ''}>
                                    {numeral(auction.eth).format('0,0.000')} ETH
                                </div>
                            ) : (
                                <>--</>
                            )}
                        </div>
                    </div>
                )}

            </Mobile>

            {/* <div className='row'>
                <div className='label'>
                    Auction
                </div>
                <div className='value'>
                    { active !== null &&
                        <>
                            { active ? (
                                <>ACTIVE</>
                            ) : (
                                <>NONE</>
                            ) }
                        </>
                    }
                </div>
            </div> */}
            {/* <div className='row'>
                <div className='label'>
                    Start Date
                </div>
                <div className='value'>
                    { startTime !== null ? (
                        <>{ DateTime.fromSeconds(startTime)
                            .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }</>
                    ) : (
                        <>--</>
                    )}
                </div>
            </div> */}

            { active && surplus !== null && surplus > 0 && 
            <div className='row'>
                <div className='label raiseLimit'>
                    Increase Limit <FontAwesomeIcon onClick={showRaiseModal} icon={faInfoCircle} /> 
                </div>
                <div className='value'>
                    {numeral(surplus).format('0,0.00')} H2O
                </div>
            </div>
            }

            <div className='row'>
                <div className='label'>
                    Time Remaining
                </div>
                <div className='value'>
                    { auction.timeRemaining !== null ? (
                        <>
                        { auction.timeRemaining.days && auction.timeRemaining.days == 1 &&
                            <>{auction.timeRemaining.days} day</>
                        }
                        { auction.timeRemaining.days && auction.timeRemaining.days > 1 &&
                            <>{auction.timeRemaining.days} days</>
                        }
                        { auction.timeRemaining.hours && auction.timeRemaining.hours > 1 &&
                            <> {Math.floor(auction.timeRemaining.hours)} hours</>
                        }
                        { auction.timeRemaining.days == 0 && Math.floor(auction.timeRemaining.hours) == 0 &&
                            <>{ Math.floor(auction.timeRemaining.mintues)} Minutes</>
                        }
                        </>
                    ) : (
                        <>--</>
                    ) }
                </div>
            </div>

            <div className='row'>
                <div className='label'>
                    End Date
                </div>
                <div className='value'>
                    { auction.endTime != null ? (
                        <>
                        { DateTime.fromSeconds(auction.endTime)
                            .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) }
                        </>
                    ) : (
                        <>--</>
                    ) }
                </div>
            </div>  

            <div className='row'>
                <div className='label'>
                    Leading Bidder
                </div>
                <div className='value'>
                    { auction.leadingBidder !== null ? (
                        <div className={(address && auction.leadingBidder 
                            && address.toLocaleLowerCase() == auction.leadingBidder.toLowerCase()) 
                            ? 'winning' : ''}>
                            {truncate(auction.leadingBidder, 15)}
                        </div>
                    ) : (
                        <>--</>
                    )}
                </div>
            </div>
            {/* <div className='row'>
                <div className='label'>
                    Payout
                </div>
                <div className='value'>
                    { amount !== null ? (
                        <>{numeral(amount).format('0,0.000')} H2O</>
                    ) : (
                        <>--</>
                    )}
                </div>
            </div> */}
            
        </div>
    </Content>
  )
})

const Content = styled.div `
    padding: ${(props) => props.theme.spacing[3]}px;
    color: ${(props) => props.theme.color.white};
    -webkit-border-bottom-right-radius: 15px;
    -webkit-border-bottom-left-radius: 15px;
    -moz-border-radius-bottomright: 15px;
    -moz-border-radius-bottomleft: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: rgba(100, 100, 100, 0.1);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 

    .raiseLimit {
        svg {
            margin-left: 5px;
            cursor: pointer;
        }
    }

    ${MobileStyles(`
        background: none;
        border: none;
        background-color: none;
        box-shadow: none;
        backdrop-filter: none; 
        -webkit-backdrop-filter: none;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
    `)};

    .rows {
        
    }

    .current {
        width: 100%;

        display: flex;
        justify-content: space-between;
        > div:last-child {
            justify-content: right;
        }

        

        .leader {
            display: flex;
            justify-content: right;
            align-items: center;

            label {
                display: flex;
                font-size: 14px;
                opacity: .5;
                justify-content: right;
            }

            .leaderAmt {
                font-size: 20px;
                text-align: right;
            }
            

            .eth {
                margin-left: 10px;
                width: 37px;
                height: 37px;
                background: rgba(255,255,255,0.2);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                svg {
                    display: inline-block;
                    position: relative;
                    top: 0px;
                    left: initial;
                    bottom: initial;
                    right: initial;
                    font-size: 20px;
                }
            }
        }

    }

    .hr {
        margin: 15px 0px;
        height: 1px;
        background: #131414;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    h4 {
        font-weight: bold;
    }

    .row {
        display: flex;
        padding: 10px 0px;
        .label {
            width: 175px;
            opacity: .5;
            text-align: left;
        }
        .value {
            flex: 1;
            text-align: right;
            .winning {
                color: ${props => props.theme.color.h2o};
                font-weight: bold;
            }
        }
    }

    ${MobileStyles(`
        .row {
            display: block;
            .value {
                text-align: left;
                margin-top: 2px;
            }
        }
    `)};
`

export default AuctionStats