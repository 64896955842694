import React, { useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import Spacer from '../../components/Spacer';

import SwapForm from '../../components/SwapForm';
import numeral from 'numeral';
import useiceWater from '../../hooks/useIceWater';
import AccountBalances from '../Account/components/AccountBalances'
import { AccountContext } from '../../contexts/Account/AccountContext';
import  Web3 from 'web3';

import { httpsCallable } from "firebase/functions";
import { ref, update} from "firebase/database";
import { database, functions } from '../../firebase';

import { TxAlert } from 'components/Alert';
import { useAlert } from 'react-alert';
import config from 'config';
import { WalletContext } from 'contexts/Account/WalletContext';
import { MobileStyles } from 'theme/Mixins';

import useModal from 'hooks/useModal';
import SimpleModal from 'components/SimpleModal';

import BigNumber from "bignumber.js";
import MarketStats from './components/MarketStats';

interface submitSwapProps {
    fromToken: string,
    toToken: string,
    fromTokenAmount: number,
    deadline: number,
    slippage: number
}

interface submitCubesProps {
    amount: number
}

const Swap: React.FC = ({  }) => {
    const { balances, 
            refreshBalances, 
            addTransaction, 
            removeTransaction } = useContext(AccountContext)

    const alert = useAlert()
    const iceWater = useiceWater()
    const { connected } = useContext(WalletContext)

    const swapFormResetRef:any = useRef()

    async function onTransactionHash(hash:string, fromToken: string, 
        toToken: string, fromAmount: number, summary: string) {

        if ( swapFormResetRef.current ) {
            swapFormResetRef.current.resetForm()
        }

        const account = iceWater.getAccount()

        const estimate = await iceWater.previewSwapTokens(
            fromToken, toToken, fromAmount)

        var tx = {
            from: account,
            transactionHash: hash,
            fromToken: fromToken,
            toToken: toToken,
            fromAmount: fromAmount,
            toAmount: parseFloat(estimate),
            summary: summary,
            timestamp: Date.now(),
            status: 'pending',
            transactionType: 'Swap'
        }
        addTransaction(tx)   
    }

  // When the transaction is successful
    async function onTransactionReceipt (receipt: any, summary: string) {
        const hash = receipt.transactionHash

        removeTransaction(hash)
        const amountTo = parseFloat(Web3.utils.fromWei(
            receipt.events.Swap.returnValues.amountTo));

        refreshBalancesAndMarkets()

        var txData:any = {
            status: receipt.status,
            blockHash: receipt.blockHash,
            blockNumber: receipt.blockNumber,
            timestamp: Date.now(),
            toAmount: amountTo,
            summary: summary + " ➔ " + numeral(amountTo).format('0,0.00')
        }

        alert.show(<TxAlert message={`Success! ${txData.summary}`} hash={hash}/>)
    }

  

    // Submit Form
    const handleSubmit = async ( 
        fromToken:string, 
        toToken:string, 
        fromTokenAmount:number,
        deadline: number,
        slippage: number
    ) => {

    const account = iceWater.getAccount()
    const summary = `${fromToken} ➔ ${toToken}: ${numeral(fromTokenAmount).format('0,0.00')}`
    
    const fromTokenAmountBN = new BigNumber(fromTokenAmount)
    const weiAmount = Web3.utils.toWei(fromTokenAmountBN.toString())

    const expected = await iceWater.previewSwapTokens(
        fromToken, toToken, fromTokenAmount
    )
    const expectedValue = parseFloat(expected)
    const minAmountValue = new BigNumber(expectedValue / (1 + slippage))
    const minAmount = Web3.utils.toWei(minAmountValue.toString())
   
    var txHash:string
    // H2O ➔ ICE 
    if (fromToken === "H2O" && 
        toToken === "ICE") {
        iceWater.getContract('CTR').methods
            .swapH2OForICE(weiAmount, minAmount, deadline)
            .send({ from: account })
            .on('transactionHash', (hash:any) => {
                txHash = hash
                onTransactionHash(hash, fromToken, toToken, 
                    fromTokenAmount, summary)
            })
            .on('error', function(err:any) {
                showErrorCode(txHash)
            })
            .then((receipt:any) => {
                onTransactionReceipt(receipt, summary)
            })
    // H2O ➔ STEAM
    } else if (fromToken === "H2O" && 
            toToken === "STEAM") {
        iceWater.getContract('CTR').methods
            .swapH2OForSTM(weiAmount, minAmount, deadline)
            .send({ from: account })
            .on('transactionHash', (hash:any) => {
                txHash = hash
                onTransactionHash(hash, fromToken, toToken, 
                    fromTokenAmount, summary)
            })
            .on('error', function(err:any) {
                showErrorCode(txHash)
            })
            .then((receipt:any) => {
                onTransactionReceipt(receipt, summary)
            })
    // ICE ➔ H2O
    } else if (fromToken === "ICE" 
            && toToken === "H2O") {
        iceWater.getContract('CTR').methods
            .swapICEForH2O(weiAmount, minAmount, deadline)
            .send({ from: account })
            .on('transactionHash', (hash:any) => {
                txHash = hash
                onTransactionHash(hash, fromToken, toToken, 
                    fromTokenAmount, summary)
            })
            .on('error', function(err:any) {
                showErrorCode(txHash)
            })
            .then((receipt:any) => {
                onTransactionReceipt(receipt, summary)
            })
    // STEAM ➔ H2O
    } else if (fromToken === "STEAM" 
            && toToken === "H2O") {
        iceWater.getContract('CTR').methods
            .swapSTMForH2O(weiAmount, minAmount, deadline)
            .send({ from: account })
            .on('transactionHash', (hash:any) => {
                txHash = hash
                onTransactionHash(hash, fromToken, toToken, 
                    fromTokenAmount, summary)
            })
            .on('error', function(err:any) {
                showErrorCode(txHash)
            })
            .then((receipt:any) => {
                onTransactionReceipt(receipt, summary)
            })    
        }   
    }

    const showErrorCode = async (txHash:string) => {
        if ( !txHash ) return

        const code = await iceWater.getRevertReason(txHash)
        removeTransaction(txHash)
        if ( code === 'DeadlineExpired' ) {
            presentDeadlineModal()
        }
        if ( code === 'ResultingamountB<minAmountB' ) {
            presentSlippageModal()
        }
    }

    const [presentSlippageModal] = useModal(
        <SimpleModal title='Transaction Reverted' top='300'>
            The transaction was reverted because the amount receieved out of the virtual pool was less than the amount allowed based on your Max Slippage settings.
        </SimpleModal>
    )

    const [presentDeadlineModal] = useModal(
        <SimpleModal title='Transaction Reverted' top='300'>
            The transaction was reverted because the deadline passed for the transaction to complete based on your Max Delay settings.
        </SimpleModal>
    )

    const refreshBalancesAndMarkets = () => {
        refreshBalances()
    }

    const onSubmit = (data:submitSwapProps) => {  
        handleSubmit(
            data.fromToken, 
            data.toToken, 
            data.fromTokenAmount,
            data.deadline,
            data.slippage
        )
        return 
    }

    return (
    <Page className="default" >  

      { connected ? (
        <>
          <AccountBalances />       
        </>
      ) : (
        <>
          <Spacer size="lg" />
          <Spacer size="lg" />
        </>
      )}
        
      { connected &&  
        <ResponsiveWrap>  
            <Spacer size="lg" />
        </ResponsiveWrap> 
      }

      <ResponsiveWrap>    
        <PageHeader/>

          <SwapCardWrap>  
            <SwapCard>              
              <StyledInputLabel>Swap</StyledInputLabel> 
              <SwapForm
                tokenBalances={balances}
                onSubmit={onSubmit}
                ref={swapFormResetRef}
              ></SwapForm> 
            </SwapCard>

            <MarketStats />

          </SwapCardWrap> 

          <Spacer size='lg' />

      </ResponsiveWrap> 
      
      <Spacer size="lg" />
    </Page>
  );
};

const ResponsiveWrap = styled.div`
  width: 100%;
  max-width: 580px;    

  @media (max-width: 768px) {
    width: 90%;
  };
`;

const SwapCardWrap = styled.div `
    display: flex;
    ${MobileStyles(`
        display: block;
    `)};
`


const SwapCard = styled.div`  
    width: 350px;
    padding: ${(props) => props.theme.spacing[3]}px;
    color: ${(props) => props.theme.color.white};
    -webkit-border-top-left-radius: 15px;
    -webkit-border-bottom-left-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-bottomleft: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 
    ${MobileStyles(`
        width: auto;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        margin-bottom: 25px;
    `)};
`;

const StyledInputLabel = styled.h4`    
  margin: 0 0 10px 0;  
`

export default Swap;