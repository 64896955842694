import React, { createContext, useEffect, useState, useCallback } from 'react'
import config from 'config'
import Web3 from "web3"
import Web3Modal from "web3modal"
import WalletConnectProvider from "@walletconnect/web3-provider"
import WalletLink from "walletlink"

const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: config.InfuraID
      }
    },
    walletlink: {
        package: WalletLink,
        options: {
          appName: config.name,
          infuraId: config.InfuraID, 
          rpc: "", // Optional if `infuraId` is provided; otherwise it's required
          chainId: config.chainId, 
          appLogoUrl: config.logoUrl, 
          darkMode: true
        }
    }
}

const web3Modal = new Web3Modal({
    //network: "",// "mainnet", // optional
    cacheProvider: true,
    providerOptions
})

function initWeb3(provider: any) {
    const web3: any = new Web3(provider)
    web3.eth.extend({
        methods: [{
            name: "chainId",
            call: "eth_chainId",
            outputFormatter: web3.utils.hexToNumber
        }]
    })
    return web3
}

interface connectionProps {
    address: string,
    balance: string,
    web3: any,
    provider: any,
    connected: boolean,
    chainId: number,
    isOnChain: boolean
}

const Init:connectionProps = {  
    address: null,
    balance: "",
    web3: null,
    provider: null,
    connected: false,
    chainId: config.chainId,
    isOnChain: true
}

export type WalletContextProps = {
    address: string | null,
    connected: boolean,
    chainId: number,
    provider: any,
    web3: any,
    isOnChain: boolean,
    connect: () => void,
    disconnect: () => void
}

const WalletContext = createContext<WalletContextProps>({ 
    address: Init.address,
    connected: Init.connected,
    chainId: Init.chainId,
    provider: Init.provider,
    web3: Init.web3,
    isOnChain: Init.isOnChain,
    connect: () => null,
    disconnect: () => null
})

const WalletProvider: React.FC = ({ children }) => {
    var _web3:any = null

    // Connection
    const [web3, setWeb3] = useState<any>(Init.address)
    const [provider, setProvider] = useState<any>(Init.provider)
    const [connected, setConnected] = useState<boolean>(Init.connected)
    const [chainId, setChainId] = useState<number>(Init.chainId)
    const [isOnChain, setIsOnChain] = useState<boolean>(true)
    const [address, setAddress] = useState<string>(Init.address)  

    useEffect(() => {
        if ( web3Modal.cachedProvider) {
            onConnect()
        }
    }, [])
    
    useEffect(() => {
        if ( chainId == config.chainId ) {
            setIsOnChain(true)
        } else {
            setIsOnChain(false)
        }
    }, [chainId])

    const updateAddress = (address: string) => {
        setAddress(address.toLowerCase())
    }

    const onConnect = async () => {
        try {
            const _provider = await web3Modal.connect()
            setProvider(_provider)
            
            await subscribeProvider(_provider)
            await _provider.enable()
        
            _web3 = initWeb3(_provider)
            setWeb3(_web3)
            
            const accounts = await _web3.eth.getAccounts()
            updateAddress(accounts[0])
        
            //const _networkId = await _web3.eth.net.getId()
            
            setChainId(await _web3.eth.chainId())
    
            setConnected(true)
        } catch (error) {
            console.error(error)
            onDisconnect()
        }
    }

    const subscribeProvider = async (provider: any) => {
        if ( !provider.on ) return 

        provider.on('close', () => {
            onDisconnect()
        })

        provider.on('accountsChanged', async (accounts: string[]) => {
            updateAddress(accounts[0])
        })

        provider.on('networkChanged', async (id: any) => {
            const netId = await _web3.eth.chainId()
            setChainId(netId)
        })

        /* 
        provider.on('chainChanged', async (id: any) => {
            const chainId = await _web3.eth.net.getId()
            setChainId(chainId)
        })
        */
    }

    const onDisconnect = async () => {
        setConnected(false)
        setAddress(Init.address)
        setProvider(Init.provider)

        if (_web3 && _web3.currentProvider && _web3.currentProvider.close) {
            await _web3.currentProvider.close()
        }

        web3Modal.clearCachedProvider()
    }

    // Callbacks
    const connect = useCallback(() => {
        onConnect()
    }, [])

    const disconnect = useCallback(() => {
        onDisconnect()
    }, [])

    return  (
        <WalletContext.Provider value={{ 
            address, 
            connected, 
            chainId, 
            provider, 
            web3, 
            isOnChain, 
            connect, 
            disconnect }}>
            {children}
        </WalletContext.Provider>
    );
};
export  { WalletContext,  WalletProvider }
