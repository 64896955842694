import ModalActions from 'components/ModalActions';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import Modal, { ModalProps } from 'components/Modal';
import ModalTitle from 'components/ModalTitle';


interface SimpleModalProps extends ModalProps {
    title: string
    children?: React.ReactNode,
}


const SimpleModal: React.FC<SimpleModalProps> = ({ 
    title,
    children,
    top='50',
    onDismiss 
}) => {
  return (
    <Content>
        <Modal top={top}>
            <ModalTitle text={title} />

            { children }

            <ModalActions>
                <Button size='md' text="Close" variant="water_ice" onClick={onDismiss}  />
            </ModalActions>
        
        </Modal>
    </Content>
  )
}
const Content = styled.div`  

`

export default SimpleModal