// @ts-nocheck
import React, { useCallback, useEffect, useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import Spacer from '../../components/Spacer';
import AccountBalances from './components/AccountBalances'
import { WalletContext } from 'contexts/Account/WalletContext';
import { Link } from 'react-router-dom'
import MiddleEllipsis from "react-middle-ellipsis";
import AccountTransactions from '../Transactions/components/AccountTransactions';
import AccountRewards from './components/AccountRewards';
import Button from 'components/Button';

var _ = require('lodash');


const Account: React.FC = () => {
    const { address, connected, disconnect } = useContext(WalletContext)
    const [tab, setTab] = useState<string>('transactions');

    const transactionRef:any = useRef()

    const onRewardsSuccess = () => {
        if ( transactionRef.current ) {
            transactionRef.current.refresh()
        }
    }

    const onDisconnect = () => {
        disconnect()
    }

    return (
        
    <Page>

        { connected ? (
        <>
            <Header>
                <h2>My Account</h2>
                <Address>
                    <MiddleEllipsis>
                        <span>{ address }</span>
                    </MiddleEllipsis>
                </Address>
            </Header>

            <Spacer size="lg" />
            <AccountBalances label='' /> 
            <Spacer size="lg" />

            <ResponsiveWrap>
                <AccountRewards onSuccess={onRewardsSuccess}/>

                <Spacer size="lg" />

                {/* <Tabs>
                    <Tab className={(tab === 'holdings') ? 'active' : ''}
                        onClick={() => {setTab('holdings')}}>
                        Holdings
                    </Tab>
                    <Tab className={(tab === 'transactions') ? 'active' : ''}
                        onClick={() => {setTab('transactions')}}>
                        Transactions
                    </Tab>
                </Tabs> */}

                <h3>Recent Transactions</h3>
                { tab === 'transactions' &&
                    <AccountTransactions 
                        limit={10} 
                        ref={transactionRef} />
                }

                <div className='more'>
                    <Link to='/transactions'>
                        All Transactions
                    </Link>
                </div>

                <Button variant='hallow' onClick={onDisconnect}>
                    Disconnect
                </Button>
 
            </ResponsiveWrap>                 
        </>
        ) : (
        <>
            <Spacer size="lg" />
            <h2>Please Connect Your Wallet</h2>
            <Spacer size="lg" />
        </>
        )}        
    </Page>
  );
};


const ResponsiveWrap = styled.div`
    width: 100%;
    max-width: 550px;  
    .more {
        margin: 25px 0px;
        text-align: center;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
`;

const Tabs = styled.div `
    display: flex;
    margin-bottom: 15px;
`

const Tab = styled.div `
    padding: 10px; 
    cursor: pointer;
    opacity: .8;
    
    &:hover {
        filter: brightness(1.2);
        opacity: 1;
    }
    &.active {
        opacity: 1;
        font-weight: bold;
        color: ${props => props.theme.color.ice};
    }
`



const Info = styled.div `
    text-align: center;
`

const Header = styled.div `
    h2 {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`

const Address = styled.div `
    max-width: 300px; 
    margin: 0px auto;
    text-align: center;
    opacity: .5;
`


export default Account;
