import React, { useCallback, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import useModal from '../../hooks/useModal';
import useiceWater from '../../hooks/useIceWater';
import BigNumber from "bignumber.js";
import { WalletContext } from '../../contexts/Account/WalletContext';
import CubesForm from 'views/Cubes/CubesForm';
import  Web3 from 'web3';
import { AccountContext } from '../../contexts/Account/AccountContext';
import { useAlert } from 'react-alert';
import { TxAlert } from 'components/Alert';
import AccountBalances from 'views/Account/components/AccountBalances';
import Spacer from 'components/Spacer';
import CubesReceived from './CubesReceived';
import CubesIssued from './CubesIssued';
import { DateTime } from "luxon";
import SimpleModal from 'components/SimpleModal';
import { MobileStyles } from 'theme/Mixins';

interface submitCubesProps {
    amount: number,
    address: string,
    redeemDate: number
};

const Cubes: React.FC = () => {
    const { address, connected } = useContext(WalletContext)
    
    const { cubesReceived,
            cubesIssued,
            refreshBalances,
            refreshCubes, 
            addTransaction, 
            removeTransaction } = useContext(AccountContext)

    
    const iceWater = useiceWater()
    const alert = useAlert()
    
    const onCubesSubmit = (data:submitCubesProps) => {  
        const weiAmount = Web3.utils.toWei(new BigNumber(data.amount).toString())
        var summary = `Minting CUBES with ${data.amount} ICE`

        var txHash:string
        iceWater.contracts.CTR.methods.mintIceCube(
            weiAmount,
            data.address,
            data.redeemDate)
            .send({ 
                from: address 
            })
            .on('transactionHash', (hash:string) => { 
                txHash = hash
                addTransaction({
                    from: address,
                    fromAmount: data.amount,
                    transactionHash: hash,
                    data: {
                        ...data,
                        recipient: data.address,
                        endTime: data.redeemDate
                    },
                    summary: summary,
                    timestamp: DateTime.now().toSeconds(),
                    status: 'pending', 
                    transactionType: 'mint_cube'
                })
                if ( cubesFormResetRef.current ) {
                    cubesFormResetRef.current.resetForm()
                }
            })
            .on('receipt', (receipt:any) => { 
                return receipt
            })
            .on('error', function(err:any) {
                showErrorCode(txHash)
                if ( cubesFormResetRef.current ) {
                    cubesFormResetRef.current.resetLoading()
                }
            })
            .then((receipt:any) => {
                const hash = receipt.transactionHash
                removeTransaction(txHash)
                refreshBalances()
                refreshCubes()
                alert.show(<TxAlert message={`Success! ${summary}`} hash={hash}/>)
                if ( cubesFormResetRef.current ) {
                    cubesFormResetRef.current.resetForm()
                }
                if ( cubesIssuedRefreshRef.current ) {
                    cubesIssuedRefreshRef.current.refresh()
                }
            })
        return 
    }

    const showErrorCode = async (txHash:string) => {
        if ( !txHash ) return
        const code = await iceWater.getRevertReason(txHash)
        removeTransaction(txHash)
        if ( code === 'DeadlineExpired' ) {
            console.log("Deadline expired")
        }   
    }

    const [showAboutModal] = useModal(
        <SimpleModal title='About Cubes' top='0'>
            <p>
            ICE CUBES are designed to temporarily assign the stream of H2O from your ICE to another address without actually transferring your ICE. This provides more flexibility in how you use your ICE and enables new uses of streaming payments over time. 
            </p>

            <p>
            In order to mint CUBES, you need to hold ICE. Once the CUBES have expired, you will need to redeem those CUBES in order to continue receiving H2O from that ICE.    
            </p>
        </SimpleModal>
    )

    const cubesFormResetRef:any = useRef()
    const cubesIssuedRefreshRef:any = useRef()
 
    return (
        <Page>
            <Content className='cubes'>
                { connected ? (
                    <div className='balances'>
                        <AccountBalances />   
                        <Spacer size="lg" />    
                    </div>
                ) : (
                    <>
                    <Spacer size="lg" />
                    <Spacer size="lg" />
                    </>
                )}

                <div className='form'>
                    <CubesForm onSubmit={onCubesSubmit}
                        ref={cubesFormResetRef} />
                </div>

                <div className='about' onClick={showAboutModal}>
                    <div>
                        About CUBES
                    </div>
                </div>

                <Spacer size="lg" />

                <div className='responsive'>
                    { cubesReceived && cubesReceived.length > 0 && (
                        <CubesReceived />
                    )}
                    <Spacer size="lg" />

                    { cubesIssued && cubesIssued.length > 0 && (
                        <CubesIssued ref={cubesIssuedRefreshRef} />
                    )}

                </div>

                <Spacer size="lg" />
            </Content>
        </Page>
    );
};

const Content = styled.div`
    width: 100%;
    .balances {
        > div {
            margin: 0px auto;
        }
    }

    .form {
        max-width: 580px;  
        margin: 0px auto;
    }

    .responsive {
        max-width: 650px;
        margin: 0px auto;

        h3 {
            text-align: center;
            font-weight: normal;
            font-size: 16px;
            opacity: .8;
        }
    }

    .about {
        display: flex;
        justify-content: center;
        margin-top: 55px;

        ${MobileStyles(`
            margin-top: 25px;
        `)};

        > div {
            padding-right: 10px;
            cursor: pointer;
            svg {
                opacity: .6;
            }
            &:hover {
                filter: brightness(1.5);
            }
        }
    }
`;

export default Cubes;
