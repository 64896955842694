import React, { useMemo } from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Label from '../Label';
import Modal, { ModalProps } from '../Modal';
import ModalActions from '../ModalActions';
import ModalTitle from '../ModalTitle';
import TokenSymbol from '../TokenSymbol';
import BorderGradientBox from '../../components/BorderGradientBox';
import { MobileStyles } from 'theme/Mixins';

/*
interface SimpleModalProps {
  title?: string | "My Modal"
}
*/

interface TokenPickerProps extends ModalProps {
  title: string,
  fromToken?: string,
  toToken?: string,
  direction: string,
  onConfirm: (amount: string) => void
}

const TokenPickerModal: React.FC<TokenPickerProps> = ({
  title,
  fromToken=null,
  toToken=null,
  direction,
  onConfirm,
  onDismiss }) => {

    
  return (
    <Modal top={'250'}>
      <ModalTitle text={title} />

      <Actions>
      {/* FROM */}
        { direction == 'from' && 
          <>
            <Button size='lg' variant='round' onClick={() => onConfirm('H2O')}>
              <TokenSymbol size={40} symbol="H2O" /> <span>H2O</span> 
            </Button>
            <Button size='lg' variant='round' onClick={() => onConfirm('ICE')}>
              <TokenSymbol size={40} symbol="ICE" /> <span>ICE</span> 
            </Button>
             <Button size='lg' variant='round' onClick={() => {onConfirm('STEAM')}}>
              <TokenSymbol size={40} symbol="STEAM" /> <span>STEAM</span> 
            </Button>
          </>
        }
     

        {/* TO */}
        { direction == 'to' && fromToken != 'H2O' && 
          <Button size='lg' variant='round' onClick={() => onConfirm('H2O')}>
            <TokenSymbol size={40} symbol="H2O" /> <span>H2O</span> 
          </Button>
        }

        { direction == 'to' && fromToken == 'H2O' &&       
          <Button size='lg' variant='round' onClick={() => onConfirm('ICE')}>
            <TokenSymbol size={40} symbol="ICE" /> <span>ICE</span> 
          </Button>
        }
        
        { direction == 'to' && fromToken == 'H2O' &&  
          <Button size='lg' variant='round' onClick={() => {onConfirm('STEAM')}}>
            <TokenSymbol size={40} symbol="STEAM" /> <span>STEAM</span> 
          </Button>
        }
       
        </Actions>   
    </Modal>
  )
}

 {/* <Token className={`${selected == 'H2O' ? "selected" : ""}`}>
    H2O Token Here
  </Token> */}

  {/* <BorderGradientBox borderColor='light' bgColor='light' onClick={() => onConfirm('H2O')}>
    <TokenSymbol symbol="H2O" /> H2O 
  </BorderGradientBox>

  <BorderGradientBox borderColor='light' bgColor='light' onClick={() => onConfirm('ICE')}>
    <TokenSymbol symbol="ICE" /> ICE 
  </BorderGradientBox>

  <BorderGradientBox borderColor='light' bgColor='light' onClick={() => onConfirm('STEAM')}>
    <TokenSymbol symbol="STEAM" /> STEAM
  </BorderGradientBox> */}

export default TokenPickerModal

const Actions = styled.div `
  display: flex;
  justify-content: center;

  button {
    display: block;
    display: flex;
    align-items: center;
    margin: 0px 5px;

    background: rgba(255,255,255,0.1) !important;

    &:hover {
        background: rgba(255,255,255,0.15) !important;
    }

    img {
      margin-right: 10px;
    }
  }

    ${MobileStyles(`
        flex-direction: column;
        button {
            justify-content: left;
            margin-bottom: 5px;
        }
    `)};
`

const Tokens = styled.div`
button {
  text-align: left;
  justify-content: normal;
  margin-bottom: 7px;
  img {
    margin-right: 15px;
  }
  span {
    font-size: 36px;
  }
}
`