import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'

interface Props {
    label: string,
    className?: string,
    children?: React.ReactNode,
}

const TxInfo: React.FC<Props> = ({
    label,
    className = '',
    children
}) => {
    return (
        <Content className={className}>
            <label>{label}</label>
            <div>
                { children }
            </div>
        </Content>
    )    
}

export default TxInfo

const Content = styled.div `
    margin-bottom: 10px;
    label {
        display: block;
        width: 150px;
        float: left;
    }
    > div {
        overflow: hidden;
        text-align: right;
        opacity: .5;
    }
    :after {
        content: "";
        clear: both;
    }
    ${MobileStyles(`
        font-size: 90%;
        label {
            width: 100px;
        }
    `)};
    .etherscan {
        opacity: 1;
        svg {
            margin-left: 5px;
            font-size: 14px;
        }
    }
`