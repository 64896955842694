import React, { useContext, useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import useModal from '../../hooks/useModal';
import Spacer from "../../components/Spacer";
import styled from 'styled-components';
import Logo from '../Logo'
import  { SideMenuItem } from './SideMenuItem'
import  { SideMenuSpacer } from './SideMenuSpacer'
import StyledLink from '../../components/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faExchangeAlt, faExternalLinkAlt, faHome, faUsers } from '@fortawesome/free-solid-svg-icons';


// Modals
import NetworkInfoModal from '../NetworkInfoModal';
import { NavLink } from 'react-router-dom';
import { SideMenuSection } from './SideMenuSection';
import { faDiscord, faMedium, faMediumM, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { WalletContext } from 'contexts/Account/WalletContext';


interface SideMenuProps {
    showLogo?: boolean,
    title?: string,
    isOpen?: boolean,
    children?: React.ReactNode
}
  
const SideMenu: React.FC<SideMenuProps> = ({
    showLogo=true,
    title='',
    isOpen=false,
    children
  }) => { 
    const { address } = useContext(WalletContext)
    
    // TODO: This solution is ghetto.. for some reason setIsOpen(false) is not working
    const closeMenu = () => {
        // setIsOpen(false)
        let element: HTMLElement = document.getElementsByClassName("bm-overlay")[0] as HTMLElement;
        element.click();
    }

    const [onPresentNetworkModal, onDissmissNetworkModal] = useModal(
        <NetworkInfoModal 
        onConfirm={() => {
            onDissmissNetworkModal()
        }}
        onDismiss={() => {
            onDissmissNetworkModal()
        }}
        />
    )

    const showNetworkModal = () => {
        closeMenu()
        onPresentNetworkModal()
    }

  
    return (
        <Content>
            <Menu isOpen={isOpen} 
                  className='side-menu'
                  >
                { showLogo && 
                    <SideMenuSection>
                        <SideMenuItem>
                            <br/>
                            <Logo href='https://icewater.money' />
                        </SideMenuItem>
                    </SideMenuSection>
                }
                { title && 
                    <Title className='bm-item'>{ title }</Title>
                }
                { children && 
                    <>
                        { children }
                        <SideMenuSpacer/>
                    </>
                } 

                { address &&   
                <SideMenuSection title={`Account - ${address.substring(0,6)}...`}>
                    <SideMenuItem 
                        title='Transactions' 
                        to='/transactions'
                        icon={<FontAwesomeIcon icon={faExchangeAlt} />} />
                </SideMenuSection>
                }

                <SideMenuSection title='Protocol'>
                    <StyledLink to='/'>Swap Tokens</StyledLink>
                    <StyledLink to='/auction'>Auction</StyledLink>
                    <StyledLink to='/cubes'>Cubes</StyledLink>
                    <StyledLink to='/docs'>Docs</StyledLink>

                    <a href='https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x86247AA4D1313700aaCB30B406F7dE8E98cD97c1'
                        target='_blank' 
                        rel='nofollow'
                        className='geth2o'>
                        Get H2O <FontAwesomeIcon icon={faExternalLinkAlt}/>
                    </a>
                    {/* <SideMenuItem title='Contracts' onClick={showNetworkModal}/> */}
                </SideMenuSection>


                <SideMenuSection title='Hydrosphere'>
                    <SideMenuItem 
                        title='Icewater' 
                        href='https://icewater.money'
                        icon={<FontAwesomeIcon icon={faHome} />} />
                    {/* <SideMenuItem 
                        title='Community' 
                        href='https://community.icewater.money'
                        icon={<FontAwesomeIcon icon={faUsers} />} /> */}
                    <SideMenuItem 
                        title='Discord' 
                        href='https://discord.gg/yw84JYaXHM'
                        icon={<FontAwesomeIcon icon={faDiscord} />} />
                    <SideMenuItem 
                        title='Medium' 
                        href='https://medium.com/icewatermoney'
                        icon={<FontAwesomeIcon icon={faMediumM} />} />
                    <SideMenuItem 
                        title='Twitter' 
                        href='https://twitter.com/icewatermoney'
                        icon={<FontAwesomeIcon icon={faTwitter} />} />
                </SideMenuSection>
        </Menu>
      </Content>
    )
}

const Content = styled.div `
    .geth2o  {
        color: inherit;
        text-decoration: none;
        svg {
            margin-left: 5px;
            font-size: 12px;
            position: relative;
            top: -3px;
        }
    }
`

const Title = styled.div `

`


const MenuLogo = styled(Logo) `
    
`

export default SideMenu