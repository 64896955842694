import React from 'react'
import styled from 'styled-components'
import { MobileStyles } from 'theme/Mixins'

interface ModalTitleProps {
  text?: string,
  alignTitle?: string
}

const ModalTitle: React.FC<ModalTitleProps> = ({ text, alignTitle='center' }) => (
  <StyledModalTitle className={alignTitle}>
    {text}
  </StyledModalTitle>
)

const StyledModalTitle = styled.div` 
align-items: center;  
  display: flex;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 25px;
  
  margin-top: ${props => -props.theme.spacing[4]}px;
  color: ${(props) => props.theme.color.white};
  
  &.center {
    justify-content: center;
  }

    ${MobileStyles(`
        margin-top: 0px;
    `)};
`

export default ModalTitle