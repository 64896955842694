import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEthereum } from '@fortawesome/free-brands-svg-icons';


import h20Logo from '../../assets/logos/Water_Full_Color.png';
import steamLogo from '../../assets/logos/Steam_Full_Color.png';
import iceLogo from '../../assets/logos/Ice_Full_Color.png';
import cubeLogo from '../../assets/logos/Cube.png';

/*
import h20Logo from '../../assets/img/Water_Full_Color.png';
import steamLogo from '../../assets/img/Steam_Full_Color.png';
import iceLogo from '../../assets/img/Ice_Full_Color.png';
*/

const logosBySymbol: {[title: string]: string} = {
  'H2O': h20Logo,
  'STEAM': steamLogo,
  'ICE': iceLogo,
  'CUBE': cubeLogo
};

type IceWaterLogoProps = {
  symbol: string;
  size?: number;
}

const TokenSymbol: React.FC<IceWaterLogoProps> = ({ symbol, size = 64 }) => {
    
    if ( symbol === 'ETH' ) {
        return (
            <Content s={size}>
                <div>
                    <FontAwesomeIcon icon={faEthereum} />
                </div>
            </Content>
        )
    }

    if ( symbol === 'CTR' ) {
        return (
            <Content s={size}>
                <div>
                    <FontAwesomeIcon icon={faEthereum} />
                </div>
            </Content>
        )
    }

    if (!logosBySymbol[symbol]) {
        throw new Error(`Invalid IceWater symbol: ${symbol}`);
    }

  return (
    <img
      src={logosBySymbol[symbol]}
      alt={`${symbol} Logo`}
      width={size}
      height={size}
    />
  )
};

export default TokenSymbol;

interface StyleProps {
    s: number
}

const Content = styled.div<StyleProps> `
    display: block;

        border-radius: 50%;
        background: rgba(255,255,255,0.1);
        margin: 0px;
        padding: 0px;
        width: ${(props) => props.s}px;
        height: ${(props) => props.s - 5}px;
    
   
    svg {
        position: relative;
        right: inherit;
        top: inherit;
        font-size: 20px;
    }
`
