import React from 'react';
import { useMediaQuery } from 'react-responsive'

// https://www.npmjs.com/package/react-responsive

/*
export const useDesktopMediaQuery = () =>
  useMediaQuery({ query: "(minWidth: 768 )" })

export const useMobileMediaQuery = () =>
  useMediaQuery({ query: "(maxWidth: 767)" })

export const useTabletMediaQuery = () =>
  useMediaQuery({ query: "(minWidth: 768, maxWidth: 991)" })

export const useWidescreenMediaQuery = () =>
  useMediaQuery({ query: "(minWidth: 992)" })
*/

interface MediaQueryProps {
    children?: React.ReactNode
}
export const Desktop = ({ children }: MediaQueryProps) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? (<>{ children }</>) : null
}
export const Mobile = ({ children }: MediaQueryProps) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? (<>{ children }</>) : null
}
export const Tablet = ({ children }: MediaQueryProps) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? (<>{ children }</>) : null
}
export const Widescreen = ({ children }: MediaQueryProps) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? (<>{ children }</>) : null
}




