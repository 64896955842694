import React from 'react';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";

import farmer from '../../assets/img/farmer.png';
import icewaterLogo from '../../assets/img/icewater_logo_color.svg';
import StyledLink from 'components/Link'

interface Props {
    to?: string,
    href?: string,
    height?: number
}

const Logo: React.FC<Props> = ({
    to,
    href,
    height = 32
}) => {
  return (
    <StyledLogo className='logo'>  
        { href && 
            <StyledLink href={href}>
                <img src={icewaterLogo} height={height} />
            </StyledLink>
        }

        { to &&
            <StyledLink to={to}>
                <img src={icewaterLogo} height={height} />
            </StyledLink>
        }

        { (!href && !to) &&
            <img src={icewaterLogo} height={height} />
        }
    </StyledLogo>
  );
};

const StyledLogo = styled.div`
  align-items: center;
  display: flex;
  img {
      //margin-top: -4;
  }
`;



export default Logo;
