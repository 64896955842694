// @ts-nocheck
import React, { useCallback, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Page from '../../components/Page';
import { WalletContext } from 'contexts/Account/WalletContext';
import useiceWater from 'hooks/useIceWater';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MobileStyles } from 'theme/Mixins'

import MiddleEllipsis from "react-middle-ellipsis";
import TokenSymbol from 'components/TokenSymbol';
import { faCode } from '@fortawesome/free-solid-svg-icons';

const Docs: React.FC = () => {
    const { address, connected } = useContext(WalletContext) 
    const iceWater = useiceWater()
    const [tokenAddresses, setTokenAddresses] = useState<any>(null);

    useEffect(() => {
        if ( iceWater?.isInitialized ) {
            fetchAddresses()
        }    
    }, [iceWater?.isInitialized])
  
    const fetchAddresses = async () => {
        const [control, h2o, ice, steam, cube] = await Promise.all([
            iceWater.getContractAddress('CTR'),
            iceWater.getContractAddress('H2O'),
            iceWater.getContractAddress('ICE'),
            iceWater.getContractAddress('STEAM'),
            iceWater.getContractAddress('CUBE'),
        ]);    
        setTokenAddresses({ 
          ctr: control, 
          h2o: h2o, 
          ice: ice,
          steam: steam,
          cube: cube
        });
    }

    return (      
    <Page>
            <Content>
            <Header>
                <h2>Contract Addresses</h2>
            </Header>       
            { tokenAddresses !== null && 
                <div className='tokens'>
                    <div className='token'>
                        <div className='ctr'>
                            <FontAwesomeIcon icon={faCode} />
                        </div>
                        <div className='value'>
                            <label>CONTROLLER</label>
                            <div className='address'>
                                { tokenAddresses.ctr }
                            </div>
                        </div>
                    </div>

                    <div className='token'>
                        <TokenSymbol symbol='H2O' size={35}/>
                        <div className='value'>
                            <label>H2O</label>
                            <div className='address'>
                                { tokenAddresses.h2o }
                            </div>
                        </div>
                    </div>

                    <div className='token'>
                        <TokenSymbol symbol='ICE' size={35}/>
                        <div className='value'>
                            <label>ICE</label>
                            <div className='address'>
                                { tokenAddresses.ice }
                            </div>
                        </div>
                    </div>

                    <div className='token'>
                        <TokenSymbol symbol='CUBE' size={35}/>
                        <div className='value'>
                            <label>CUBE</label>
                            <div className='address'>
                                { tokenAddresses.cube }
                            </div>
                        </div>
                    </div>

                    {/* <div className='token'>
                        <TokenSymbol symbol='STEAM' size={35}/>
                        <div className='value'>
                            <label>STEAM</label>
                            <div className='address'>
                                { tokenAddresses.steam }
                            </div>
                        </div>
                    </div> */}
                </div>
            }
            
        </Content>
    </Page>
  );
};

export default Docs;


const Content = styled.div`
    width: 100%;
    max-width: 600px;  

    .tokens {
        margin-top: 25px;
    }

    .token {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        img {
            margin-right: 15px;
        }
        .value {
            label {
                display block;
                opacity: .5;
                font-size: 14px;
            }
        }
    }

    .ctr {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #535c63;
        border-radius: 50%;
        position: relative;
        left: -10px;
        svg {
            opacity: .8;
        }
    }

    ${MobileStyles(`
        .address {
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    `)};
`;

const Header = styled.div `
    h2 {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
`



