import { ChainId } from '@uniswap/sdk';
const IceWaterConfig = require('./ice-water/config');

export type Configuration = {
  name: string,
  //chainId: ChainId,
  chainId: 1 | 4 | 5 | 10,
  epoch: {
    id: number,
    start: string,
    end: string
  },
  logoUrl: string,
  InfuraID: string,
  marketVersion: number,
  etherscanUrl: string,
  defaultProvider: string,
  refreshInterval: number;
};

var config = IceWaterConfig;

const configurations: { [env: string]: Configuration } = config;

export default configurations[process.env.NODE_ENV || "development"];
