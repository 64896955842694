import { css } from 'styled-components'
// import { MobileStyles } from 'theme/Mixins'
/*
    ${MobileStyles(`

    `)};
*/

export const MobileStyles = (style:any, maxWidth:number=767) => css`   
@media only screen and (max-width: ${maxWidth}px) {
    ${style}
}
`;
