import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import TextChanged from '../../../components/TextChanged';
import { MobileStyles } from '../../../theme/Mixins'
import { Desktop, Mobile } from "components/Responsive";
import useiceWater from 'hooks/useIceWater';
import { AccountContext } from 'contexts/Account/AccountContext'
import { WalletContext } from 'contexts/Account/WalletContext';
import Spacer from 'components/Spacer';
import TokenSymbol from 'components/TokenSymbol';
import Card from 'components/Card';
import Button from 'components/Button';
import { DateTime } from 'luxon';

interface Props {
    onSuccess?: () => void,
}

const AccountRewards: React.FC<Props> = ({
    onSuccess
}) => {  
    const { address, connected } = useContext(WalletContext)
    const { balances, refreshBalances, 
        addTransaction, removeTransaction } = useContext(AccountContext)
    const iceWater = useiceWater()

    
    const onClaimH2O = async () => {
        const total = parseFloat(balances.claimableH2OFromICE) 
        iceWater.contracts.CTR.methods.claimRewards()
            .send({ from: address })
            .on('transactionHash', (hash:any) => {
                addTransaction({
                    from: address,
                    transactionHash: hash,
                    amount: total,
                    summary: "Vent Dividends",
                    timestamp: DateTime.now().toSeconds(),
                    status: 'pending', 
                    transactionType: 'reward_dividends'
                })
            })
            .on('receipt', (receipt:any) => { 
                removeTransaction(receipt.transactionHash)
                refreshBalances() 
                if ( onSuccess ) {
                    onSuccess()
                }
            }).then((response:any) => {
                return response
            })       
    }

    return (
        <Content>
            <Card variant='glass'>
                <h4>H2O Rewards</h4>

                <Rewards>
                    <Reward label='ICE Melt' 
                        amount={balances.claimableH2OFromICE}
                        symbol='H2O' />

                    <div>
                        <Button type="button"
                            size="md"
                            variant='water_ice'
                            text='Claim H2O'
                            onClick={onClaimH2O}/>
                    </div>


                    {/* <Spacer size='sm' /> */}

                    {/* <Reward label='STM Condensation' 
                        amount={balances.claimableH2OFromSTEAM}
                        symbol='H2O' /> */}
                </Rewards>

                {/* <Spacer size='sm' /> */}

                  
            </Card>

            {/* <Card>
                <h5>STM Rewards</h5>

                <Rewards>
                    <Reward label='Vent' 
                            amount={balances.claimableVent}
                            symbol='STEAM'
                            reward='STM' />
                </Rewards>

                <Spacer size='sm' />

                <Button type="button"
                        size="md"
                        variant='ice_steam'
                        text='Claim STM'
                        onClick={onClaimVent}/>
            </Card> */}
        </Content>
    )
}

export default AccountRewards


interface RewardProps {
    label: string;
    amount: string;
    symbol: string;
    reward?: string;
}
  
const Reward: React.FC<RewardProps> = ({ 
    label,
    amount,
    symbol,
    reward="H2O"
}) => {
    return (
        <RewardStyles>
            <Claim>
                <div>
                    <TokenSymbol symbol={symbol} size={35} />
                </div>
                <div>
                    <label>
                        {label}
                    </label>
                    { ( parseFloat(amount) < 0.001 ) ? (
                        <div className={`value ${reward.toLowerCase()}`}>
                            +{numeral(amount).format('0,0.000000')} {reward}
                        </div>
                    ) : (
                        <div className={`value ${reward.toLowerCase()}`}>
                            +{numeral(amount).format('0,0.000')} {reward}
                        </div>
                    )}
                </div>
            </Claim>
        </RewardStyles>
    )
}


const Content = styled.div `
    max-width: 600px;
    margin: 0px auto;

    .card {
        position: relative;
        h4 {
            margin: 0px 0px 15px 0px;
        }
    }

    .card:first-child {
        margin-right: 10px;
    }
    .card:last-child {
        margin-left: 10px;
    }

    ${MobileStyles(`
        display: block;
        .card:first-child {
            margin: 10px;
        }
        .card:last-child {
            margin: 0px 10px;
        }
    `)};
`


const RewardStyles = styled.div `

`

const Rewards = styled.div `
    display: flex;
    align-items: center;

    > div:first-child {
        flex: 1;
        margin-top: 10px;
    }

    > div:last-child {
        width: 150px;
    }

    ${MobileStyles(`
        height: auto;
    `)};
`

const Claim = styled.div`
    display: flex;
    margin-bottom: 15px;


    .value {
        font-weight: bold;
        font-size: 120%;
    }

    .h2o {
        color: ${props => props.theme.color.h2o};
    }
    .ice {
        color: ${props => props.theme.color.ice};
    }
    .steam {
        color: ${props => props.theme.color.steam};
    }
    
    > div:nth-child(1) {
        width: 55px;
        padding-top: 5px;
    }
    > div:nth-child(2) {
        flex: 1;
    }
`