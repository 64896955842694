import React, { useMemo } from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Label from '../Label';
import Modal, { ModalProps } from '../Modal';
import ModalTitle from '../ModalTitle';
import ModalActions from '../ModalActions';
import TokenSymbol from '../TokenSymbol';


interface DisconnectModalProps extends ModalProps {
  onConfirm: () => void,
  onDismiss: () => void
}

const DisconnectModal: React.FC<DisconnectModalProps> = ({ onConfirm, onDismiss }) => {

  const dismissSimpleModal = () : void => {
    console.log("dismissed!")
  }
  return (
    <Modal >
      <ModalTitle text="Disconnect"/>

      <Message>
        Are you sure you want to sign out?
      </Message>
      
      <ModalActions>
        <Button size='md' text="Close" variant="empty" onClick={onDismiss}  />
        <Button size='md' text="Sign Out" variant="water_ice" onClick={onConfirm} />
      </ModalActions>
      
      
    </Modal>
  )
}

const Message = styled.div `
  text-align: center;
`

export default DisconnectModal