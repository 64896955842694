import React, { useContext, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons';


interface ButtonProps {
  children?: React.ReactNode,
  disabled?: boolean,
  href?: string,
  onClick?: () => void,
  size?: 'sm' | 'md' | 'lg',
  text?: string | React.ReactNode,
  to?: string,
  type?: 'button' | 'submit' | 'reset',
  variant?: string,
  active?: boolean,
  loading?: boolean
  //variant?: 'default' | 'secondary' | 'tertiary' | 'invert' | 'hallow'
}

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  type='button',
  variant='default',
  active,
  loading=false
}) => {
  const { color, spacing } = useContext(ThemeContext)

  let buttonColor: string
  switch (variant) {
    case 'secondary':
      buttonColor = color.teal[200]
      break
    case 'default':
    default:
      buttonColor = color.primary.main
  }

  let boxShadow: string = ""
  let buttonSize: number
  let buttonPadding: number
  let fontSize: number
  let radius: number
  switch (size) {
    case 'sm':
      buttonPadding = spacing[3]
      buttonSize = 36
      fontSize = 14
      radius = 10
      break
    case 'lg':
      buttonPadding = spacing[4]
      buttonSize = 72
      fontSize = 16
      radius = 20
      break
    case 'md':
    default:
      buttonPadding = spacing[4]
      buttonSize = 56
      fontSize = 16
      radius = 15
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}{children}</StyledLink>
    } else if (href) {
      return <StyledExternalLink href={href} target="__blank">{text}</StyledExternalLink>
    } else {
      return text
    }
  }, [href, text, to])

  const onButtonClick = () => {
    if ( !loading ) {
        if ( onClick ) {
            onClick()
        }
    }
  }

  return (
    <StyledButton
      boxShadow={boxShadow}      
      disabled={disabled}
      fontSize={fontSize}
      radius={radius}
      onClick={onButtonClick}
      padding={buttonPadding}
      size={buttonSize}
      type={type}
      className={variant}
      active={active}
    >
        { loading ? (
            <Loading>
                <FontAwesomeIcon icon={faSyncAlt} />
            </Loading>
        ):(
            <>
            { !to &&
                <>{children}</>
            }
            {ButtonChild}
            </>
        )}
    </StyledButton>
  )
}

interface StyledButtonProps {
  boxShadow: string,  
  disabled?: boolean,
  fontSize: number,
  padding: number,
  active?: boolean,
  size: number,
  radius: number
}

/*
&.secondary {
  background: #D655A9;
  background: -webkit-linear-gradient(top left, #D655A9, #656CCC);
  background: -moz-linear-gradient(top left, #D655A9, #656CCC);
  background: linear-gradient(to bottom right, #D655A9, #656CCC);
}
&.secondary:hover {
    background: #E653B2;
    background: -webkit-linear-gradient(top left, #E653B2, #6871E6);
    background: -moz-linear-gradient(top left, #E653B2, #6871E6);
    background: linear-gradient(to bottom right, #E653B2, #6871E6);
  }
*/

const Loading = styled.div `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  svg {
    animation: spin 2s linear infinite;
  }
`

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background-color: ${props => props.theme.color.blue[200]};
  border: 0;
  border-radius: ${props => props.radius}px;
  box-shadow: ${props => props.boxShadow};
  color: ${props => !props.disabled ? props.theme.color.white : `${props.color}55`};
  cursor: pointer;
  display: flex;
  font-size: ${props => props.fontSize}px;
  font-weight: 700;
  height: ${props => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${props => props.padding}px;
  padding-right: ${props => props.padding}px;
  pointer-events: ${props => !props.disabled ? undefined : 'none'};
  width: 100%;  
  &:hover {
    background-color: ${props => props.theme.color.blue[100]};
    color: ${props => props.theme.color.white};
  }

  -webkit-transition: background 150ms ease-out;  
  -moz-transition: background 150ms ease-out;  
  -o-transition: background 150ms ease-out;  
  transition: background 150ms ease-out;  

  &.water_ice_steam {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.water_ice_steam});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.water_ice_steam});
    background: linear-gradient(to right, ${props => props.theme.gradients.water_ice_steam});
  }

  &.water_ice {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.water_ice});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.water_ice});
    background: linear-gradient(to right, ${props => props.theme.gradients.water_ice});
  }

  &.water_steam {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.water_steam});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.water_steam});
    background: linear-gradient(to right, ${props => props.theme.gradients.water_steam});
  }

  &.ice_water {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.ice_water});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.ice_water});
    background: linear-gradient(to right, ${props => props.theme.gradients.ice_water});
  }

  &.ice_steam {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.ice_steam});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.ice_steam});
    background: linear-gradient(to right, ${props => props.theme.gradients.ice_steam});
  }

  &.steam_water {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.steam_water});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.steam_water});
    background: linear-gradient(to right, ${props => props.theme.gradients.steam_water});
  }

  &.steam_ice {
    background: -webkit-linear-gradient(to right, ${props => props.theme.gradients.steam_ice});
    background: -moz-linear-gradient(to right, ${props => props.theme.gradients.steam_ice});
    background: linear-gradient(to right, ${props => props.theme.gradients.steam_ice});
  }

  &.secondary {
    background: #D655A9;
    background: -webkit-linear-gradient(to right, #0191c7, #24a9a3, #a54381);
    background: -moz-linear-gradient(to right, #0191c7, #24a9a3, #a54381);
    background: ${props => props.theme.gradients.icewatersteam};
  }
  &.secondary:hover {
    background: #E653B2;
    background: -webkit-linear-gradient(to left, #01a3e0, #22bdb6, #bf4392);
    background: -moz-linear-gradient(to right, #01a3e0, #22bdb6, #bf4392);
    background: linear-gradient(to right, #01a3e0, #22bdb6, #bf4392);
  }

  &.tertiary {
    backdrop-filter: blur(5px);   
    -webkit-backdrop-filter: blur(5px);
    background-color: ${({active, theme}) => {
        if ( active === true ) {
            return  '#0191c7';
        }
        if ( active === false ) {
            return 'rgba(255, 255, 255, 0.1)';
        }
        return 'rgba(255, 255, 255, 0.1)';
    }};
  }
  &.tertiary:hover {    
    background-color: ${({active, theme}) => {
        if ( active === true ) {
            return  '#0191c7';
        }
        if ( active === false ) {
            return 'rgba(255, 255, 255, 0.2)';
        }
        return 'rgba(255, 255, 255, 0.2)';
    }};
    color: ${props => props.theme.color.white};    
  }

  &.disabled {
    background: rgb(43,43,43);
    background: linear-gradient(180deg, rgba(43,43,43,1) 0%, rgba(24,24,24,1) 100%);
    color: #555;
  }
 

  &.invert {
    background: none;
    border: 0;
    color: #6871E6;
    background: -webkit-linear-gradient(top left, #E653B2, #6871E6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.empty {
    cursor: pointer;
    background: none;
    opacity: .9;
    &:hover {
      opacity: 1;
    }
  }

  &.hallow {
    cursor: pointer;
    background: none;
    border: 2px #aaaa solid;
    border-radius: ${props => props.theme.borderRadius}px;
  }

  &.hallow:hover {
    background-color: rgba(255, 255, 255, 0.3);  
    backdrop-filter: blur(5px);   
    -webkit-backdrop-filter: blur(5px);
  }

  &.round {
    border-radius: 100px !important;
    cursor: pointer;
    background: none;
    border: 2px #aaaa solid;
    border-radius: ${props => props.theme.borderRadius}px;
  }

  &.round:hover {
    background-color: rgba(255, 255, 255, 0.1);  
  }
`

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${props => -props.theme.spacing[4]}px;
  padding: 0 ${props => props.theme.spacing[4]}px;
  text-decoration: none;
`

export default Button