import React, { MouseEvent, useState, useEffect, useContext, useCallback, useRef, useImperativeHandle, forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import { MobileStyles } from 'theme/Mixins'
import Button from 'components/Button';
import TokenSymbol from 'components/TokenSymbol';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCalendarAlt, faExclamationTriangle, faInfoCircle, faTrophy } from '@fortawesome/free-solid-svg-icons';
import Spacer from 'components/Spacer';
import useModal from 'hooks/useModal';
import SimpleModal from 'components/SimpleModal';
import { IceWaterContext } from 'contexts/IceWaterProvider/IceWaterProvider';
import { WalletContext } from 'contexts/Account/WalletContext';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { DateTime } from 'luxon';
import AccountButton from 'components/AccountButton';
import { AuctionProps } from './Auction';
import numeral from 'numeral';


type Inputs = {
    amount: number,
    h2oAmount: number
};

interface Props {
    active: boolean,
    hasEnded: boolean,
    loading: boolean,
    surplus: number | null,
    auction: AuctionProps,
    onSubmit: (data:Inputs) => void,
    onEnd: () => void,
}

// TODO: passing the resetRef with forwardRef is giving a type error so
// As a work around I'm changing the the component prop types to "any"
const AuctionForm: React.FC<any> = forwardRef(({
    active,
    hasEnded,
    loading,
    surplus,
    auction,
    onSubmit,
    onEnd
}, resetRef) => {
    const { marketStats } = useContext(IceWaterContext)
    const { address } = useContext(WalletContext)
    const [diff, setDiff] = useState<number | null>(null)
    const [showH2oInput, setShowH2oInput] 
        = useState<boolean>(auction.active 
            && auction.type === 'negative' 
            && auction.raiseLimit ? false : true)
    const [auctionType, setAuctionType] = 
        useState<'positive' | 'negative' | null>(null) 
    const [errorMsg, setErrorMsg] = useState<string | null>(null) 
    
    // 0xe9388A482b6f502Fa768905ff3E606B404CDB000

    useEffect(() => {
        if ( marketStats ) {
            const d = marketStats.h2oTotalSupply - marketStats.h2oTargetSupply
            setDiff(d)
            setAuctionType((d < 0) ? 'positive' : 'negative')
        }
    }, [marketStats])

    const { register,
            handleSubmit,
            formState: { isSubmitSuccessful, errors },
            control,
            reset,
            setValue,
            clearErrors} = useForm<Inputs>();  

    useMemo(() => {
        if ( active && auction.type === 'negative') {
            setValue('h2oAmount', 0)
        }
    }, []);

    const amountRef = useRef<HTMLInputElement | null>(null);
    const amountReg = register("amount", { 
        required: "Enter the amount of ETH you want to bid."             
    })

    const h2oAmountRef = useRef<HTMLInputElement | null>(null);
    const h2oAmountReg = register("h2oAmount", { 
        required: auctionType === 'positive' ? false 
            : "Enter the amount of H2O you want to bid on."             
    })
            
    const submitForm = useCallback((data: Inputs) => {  
        if ( !address ) {
            showConnectWalletMsg()
            return
        }

        if ( !validateH2oAmount(data.h2oAmount) ) {
            return 
        }

        if ( !validateEthAmount(data.amount) ) {
            return 
        }

        onSubmit({
            amount: data.amount,
            h2oAmount: (auctionType === 'negative' ? data.h2oAmount : 0) 
        })
    }, [onSubmit]);

    const validateH2oAmount = (amount:number, ignoreEmpty:boolean=false) => {
        if ( auctionType === 'positive' ) {
            return true
        }

        if ( !amount && ignoreEmpty ) {
            setErrorMsg(null)
            return true
        } 

        if ( auction.type === 'negative') {
            if ( amount > surplus ) {
                setErrorMsg(`H2O must be less than the surplus: ${numeral(surplus).format('0,0.00')}`)
                return false
            }
        }

        setErrorMsg(null)
        return true
    }

    const validateEthAmount = (amount:number, ignoreEmpty:boolean=false) => {
        if ( !amount && ignoreEmpty ) {
            setErrorMsg(null)
            return true
        }

        if ( active && auctionType === 'negative') {
            if ( amount >= auction.eth ) {
                setErrorMsg(`Your ETH bid must be less than the leading bid of ${numeral(auction.eth).format('0,0.00')}`)
                return false
            }
        }

        if ( active && auctionType === 'positive') {
            if ( amount <= auction.eth ) {
                setErrorMsg(`Your ETH bid must be greater than the leading bid of ${numeral(auction.eth).format('0,0.00')}`)
                return false
            }
        }

        if ( !amount || amount === 0 ) {
            setErrorMsg("You must provide an ETH amount")
            return false
        }

        setErrorMsg(null)
        return true
    }

    const endAuction = () => {
        onEnd()
    }

    useImperativeHandle(resetRef, () => ({
        resetForm,
    }));

    const resetForm = () => {
        setErrorMsg(null)
        reset() 
        clearErrors()
        if ( active && auction.type === 'negative') {
            setValue('h2oAmount', 0)
        }
    }

    const [showConnectWalletMsg] = useModal(
        <SimpleModal title='Connect Wallet'>
            Please connect your wallet.
        </SimpleModal>
    )

    const [showAboutBurn] = useModal(
        <SimpleModal title='Burn Auction' top='0'>
            <p>
                Auctions are designed to keep H2O stable by increasing or decreasing the amount of H2O supply through auctioning ETH.
            </p>
            <p>
                When the supply of H2O is higher than the target supply, the protocol will auction some of its ETH to burn H2O. In this case, making a bid will remove the amount of H2O being auctioned from the bidder's wallet until a lower ETH bid is made or the auction is ended. 
            </p>
            <p>
                When the auction ends, whichever address has the current lowest ETH bid will be given that amount of ETH from the protocol. 
            </p>
        </SimpleModal>
    )

    const [showAboutMint] = useModal(
        <SimpleModal title='Mint Auction' top='0'>
            <p>
                Auctions are designed to keep H2O stable by increasing or decreasing the amount of H2O supply through auctioning ETH.
            </p>
            <p>
                When the supply of H2O is lower than the target supply, the protocol will auction newly minted H2O for ETH. The highest eth bid will acquire the newly minted H2O. When a bid is made, the ETH will be transferred to the IceWater protocol until a higher bid is made or the auction is ended. 
            </p>
        </SimpleModal>
    )

    const toggleAddSurplus = () => {
        if ( showH2oInput ) {
            setShowH2oInput(false)
            setValue('h2oAmount', 0, {
                shouldValidate: false
            })
            if ( amountRef ) {
                amountRef.current.focus()
            }
        } else {
            setShowH2oInput(true)
            setMaxAmount()
            if ( h2oAmountRef ) {
                h2oAmountRef.current.focus()
            }
        }
    }

    const setMaxAmount = () => {
        if ( active && auction.type === 'negative' ) {
            setValue('h2oAmount', surplus, {
                shouldValidate: false
            })
        } else {
            setValue('h2oAmount', surplus, {
                shouldValidate: false
            })
        }
        if ( amountRef ) {
            amountRef.current.focus()
        }
    }

    const onEthAmountBlur = (e: MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.value
        validateEthAmount(parseFloat(value), true)
    };

    const onH2oAmountBlur = (e: MouseEvent<HTMLButtonElement>) => {
        const value = e.currentTarget.value
        validateH2oAmount(parseFloat(value), true)
    };

    return (
    <Form>              
        <Spacer size='sm' />

        <Head>
            { auctionType == 'positive' ? (
                <div>
                    <div>Mint Auction</div>
                    <div className='info' onClick={showAboutMint}>
                        <FontAwesomeIcon icon={faInfoCircle} />    
                    </div>
                </div> 
            ) : (
                <div>
                    <div>Burn Auction</div>
                    <div className='info' onClick={showAboutBurn}>
                        <FontAwesomeIcon icon={faInfoCircle} />    
                    </div>
                </div> 
            )}

            {/* <div>
                { auctionType == 'positive' ? (
                    <div>Mint Auction</div>
                ) : (
                    <div>Burn Auction</div>
                )}
                <div className='info' onClick={showAboutBurn}>
                    <FontAwesomeIcon icon={faInfoCircle} />    
                </div>
            </div>  */}
            { active && auctionType == 'negative' && surplus > 0 &&
                <div>
                    <div className='max' onClick={toggleAddSurplus}>
                    + Increase H2O
                    </div>
                </div>
            }
            { !active && auctionType == 'negative' &&
                <div>
                    <div className='max' onClick={setMaxAmount}>
                        + MAX
                    </div>
                </div>
            }
        </Head>
        
        <form onSubmit={handleSubmit(submitForm)}>
            <Row>
                <InputFlexGroup>
                    <TokenPicker>
                        <div className='eth'>
                            <FontAwesomeIcon icon={faEthereum} />    
                        </div> 
                        <span>ETH</span>
                    </TokenPicker>
                    <InputWrap className='sqLeft'>
                        <input type='text'
                            {...amountReg}
                            ref={(e) => {
                                amountReg.ref(e)
                                amountRef.current = e
                            }}
                            onBlur={(e:any) => onEthAmountBlur(e) }
                            autoFocus={true}
                            />
                    </InputWrap>
                </InputFlexGroup>
            </Row>   


            { auctionType == 'negative' &&
                <div className={`${showH2oInput ? 'h2oInput' : 'h2oInput hidden'}`}>
                    {/* <Head>                 
                        { active && surplus &&
                        <div>
                            Burn Surplus (optional)
                            <div className='info' onClick={showAboutModal}>
                                <FontAwesomeIcon icon={faInfoCircle} />    
                            </div>
                        </div>
                        }
                    </Head> */}
                    
                    <Row>
                        <InputFlexGroup>
                            <TokenPicker>
                                <TokenSymbol symbol='H2O' /> 
                                <span>H2O</span>
                            </TokenPicker>
                            <InputWrap className='sqLeft'>
                                <input type='text'
                                    {...h2oAmountReg}
                                    ref={(e) => {
                                        h2oAmountReg.ref(e)
                                        h2oAmountRef.current = e
                                    }}
                                    // value={ (active && auction.h2o !== null) ? auction.h2o : ''}
                                    onBlur={(e:any) => onH2oAmountBlur(e) }
                                    //autoFocus={auctionType == 'negative' ? true : false}
                                    />
                            </InputWrap>
                        </InputFlexGroup>
                    </Row>
                    <Spacer size='sm' />
                </div>
            } 

            <Spacer size='sm' />
            <Row className='buttonWrap'>
                { address ? (
                    <>
                        { active ? (
                            <>
                                { hasEnded ? (
                                    <Button type='submit' 
                                        loading={loading}
                                        variant={'water_ice'}>
                                        Last Bid
                                    </Button> 
                                ) : (
                                    <Button type='submit' 
                                        loading={loading}
                                        variant={'water_ice'}>
                                        Bid
                                    </Button> 
                                )}
                            </>
                        ) : (
                            <Button type='submit' 
                                loading={loading}
                                variant={'water_ice'}>
                                Start
                            </Button> 
                        ) }
                    </>
                ) : (
                    <AccountButton />
                ) }
            </Row>

            <Spacer size='sm' />
            { active && hasEnded && 
                <Row>
                    <div className='or'>
                        <div></div>
                        <div>OR</div>
                        <div></div>
                    </div>
                    <Button type='button' 
                            variant={'water_ice'}
                            loading={loading}
                            onClick={endAuction}>
                        End Auction
                    </Button> 
                </Row>
            }
            
            { errors.amount &&
                <InputError>
                    <div className="error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        {errors.amount.message}
                    </div>
                </InputError>       
            } 

            { errors.h2oAmount &&
                <InputError>
                    <div className="error">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        {errors.h2oAmount.message}
                    </div>
                </InputError>       
            } 

            { errorMsg !== null &&
                <InputError>
                    <div className="error">
                        { errorMsg }
                    </div>
                </InputError> 
            }
        </form>
    </Form>
  )
})

export default AuctionForm

const Head = styled.div `
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    > div:first-child {
        display: flex;
    }

    > div:last-child {
        display: flex;
        .max {
            cursor: pointer;
            margin-right: 10px;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .info {
        margin-left: 10px;
        opacity: .7;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
`

const Row = styled.div `
    max-width: 480px;
    display: flex;
    ${MobileStyles(`
        display: block;
    `)};
`
const Col = styled.div `
    &:first-child {
        margin-right: 5px;
        flex: 1;
    }
    &:last-child {
        margin-left: 5px;
        width: 150px;
    }
    ${MobileStyles(`
        &:last-child {
            margin-left: 0px;
            width: auto;
            h4 {
                display: none;
            }
        }
    `)};
`



const Form = styled.div `  
    position: relative;
    padding: ${(props) => props.theme.spacing[3]}px;
    color: ${(props) => props.theme.color.white};
    
    -webkit-border-top-left-radius: 15px;
    -webkit-border-top-right-radius: 15px;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 20px 0px 50px rgba(0, 0, 0, 0.5);
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px); 
    -webkit-backdrop-filter: blur(5px); 
    ${MobileStyles(`
        width: auto;
        -webkit-border-bottom-left-radius: 15px;
        -webkit-border-bottom-right-radius: 15px;
        -moz-border-radius-bottomleft: 15px;
        -moz-border-radius-bottomright: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    `)};

    .accountBtn {
        width: 100%;
    }

    .h2oInput {
        &.hidden {
            display: none;
        }
    }

    form {
        button {
            height: 62px;
        }

        .error {
            color: ${props => props.theme.color.h2o};      
            margin: 10px 0px 0px 3px;
         
            svg {
                margin-right: 10px;
            }
        }
        input.address {
            text-align: left;
            margin-left: 5px;
            //font-size: 16px !important;
        }
        input {  
            text-align: right;  
            background: none;
            border: 0;
            color: ${props => props.theme.color.white};
            font-size: 21px !important;
            height: 61px !important;
            margin: 0;
            padding: 0;
            outline: none;
            width: 100%;
    
            ${MobileStyles(`
                font-size: 18px !important;
            `)};
         }
        
        input::placeholder {
            color: rgba(255, 255, 255, 0.5);    
        }
         
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    .or {
        display: flex;
        align-items: center;
        padding: 15px 0px;
        
        > div:first-child {
            flex: 1;
            height: 1px;
            background: #1f2223;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
        > div:nth-child(2) {
            width: 50px;
            text-align: center;
        }
        > div:last-child {
            flex: 1;
            height: 1px;
            background: #1f2223;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
`;


const InputFlexGroup = styled.div` 
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
`


const TokenPicker = styled.div`  
    width: 80px;
    height: 56px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: default;
    display: flex;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-top-left-radius: ${props => props.theme.borderRadius}px;
    border-bottom-left-radius: ${props => props.theme.borderRadius}px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    position: relative;

    // &:hover {
    //     background-color: rgba(255, 255, 255, 0.2);
    // }

    img {
        width: 35px;
        height: 35px;
        display: inline;
        vertical-align: middle;
        margin-right: 10px;
    }

    .eth {
        width: 40px;
        height: 40px;
        background: rgba(0,0,0,0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        svg {
            display: inline-block;
            position: relative;
            top: 10px;
            left: initial;
            bottom: initial;
            right: initial;
            font-size: 20px;
        }
    }

    span {
        font-weight: bold;
    }

    svg {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0%, -50%);
    }

    svg.upsidedown {
        transform: rotate(180deg);
        margin-top: -8px;
    }
`


const InputWrap = styled.div` 
    flex: 1;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    -webkit-border-radius: ${props => props.theme.borderRadius}px;
    -moz-border-radius: ${props => props.theme.borderRadius}px;
    border-radius: ${props => props.theme.borderRadius}px;

    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-right: 10px;
    padding-left: 10px;

    &.sqLeft {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    
`

const InputError = styled.div` 
    margin-bottom: 25px;
`
