import React, { createContext, useMemo, useContext, useEffect, useState, useCallback } from 'react';
import { signInWithCustomToken, signOut, onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { getDatabase, ref, get, child, update, onValue} from "firebase/database";
import { auth, functions, database, snapshotToArray } from '../../firebase';
import { UserProps } from "../../ice-water/types";
import useiceWater from '../../hooks/useIceWater';
import config from 'config';
import { useAlert } from 'react-alert';
import { WalletContext } from './WalletContext';
import useInterval from 'hooks/useInterval';
import Web3 from 'web3';

export type TransactionType = {
    from: string;
    transactionHash: string;
    fromToken: string;
    toToken: string;
    fromAmount: number;
    toAmount: number;
    summary: string;
    timestamp: number;
    status: string;
    type: string;
}

export type AchievementType = {
    category: string;
    description: string;
    epoch: string;
    id: string;
    title: string;
    type: string;
    value: string;
}

export interface tokenBalancesProps {
    h2o: string,
    ice: string,
    steam: string,
    claimableH2OFromICE: string
}

export type AccountContextType = {
    accountUser: UserProps | null;
    transactions: TransactionType[];
    balances: tokenBalancesProps,
    cubesReceived: number[],
    cubesIssued: number[],
    addTransaction: (tx: any) => void;
    removeTransaction: (hash: string) => void;
    refreshBalances: () => void;
    refreshCubes: () => void;
}

const InititialBalances:tokenBalancesProps = {
    h2o: null,
    ice: null,
    steam: null,
    claimableH2OFromICE: null
}

const InitialUser:UserProps = {
    uid: '',
    backgroundImg: '',
    description: '',
    discord: '',
    github: '',
    lastLogin: '',
    medium: '',
    name: '',
    optInShare: '',
    profileImg: '',
    telegram: '',
    twitter: '',
    website: '',
    avatar: ''
}

const AccountContext = createContext<AccountContextType>({ 
    accountUser: null, 
    transactions: [],
    balances: null,
    cubesReceived: null,
    cubesIssued: null,
    addTransaction: () => null,
    removeTransaction: () => null,
    refreshBalances: () => null,
    refreshCubes: () => null,
})

const AccountProvider: React.FC = ({ children }) => {
    const iceWater = useiceWater()
    const getAuthToken = httpsCallable(functions, 'getAuthToken')
    const addUserAchievement = httpsCallable(functions, 'addUserAchievement')
    const getUser = httpsCallable(functions, 'getUser')
    const { address, isOnChain } = useContext(WalletContext)
    //const [accountUser, setAccountUser] = useState<UserProps | null>(null); 
    const [accountUser, setAccountUser] = useState<any | null>(null); 
    const [transactions, setTransactions] = useState<any[]>([]);//<TransactionType[]>([]);
    const [achievements, setAchievements] = useState<AchievementType[]>([]);
    const [balances, setBalances] = useState<tokenBalancesProps>(InititialBalances)

    const [cubesReceived, setCubesReceived] = useState<any>()
    const [cubesIssued, setCubesIssued] = useState<any>()

    useInterval(() => {
        refresh()
    }, isOnChain ? config.refreshInterval : null)

    useEffect(() => {
        if ( address ) {
            refresh()
        } else {
            reset()
        }
    }, [address])


    const reset = () => {
        setAccountUser(null)
    }

    const refresh = async () => {
        getAccountUser()
        fetchBalances()
        fetchCubes()
    }

    // TODO: Do we want to use this anymore?
    const getAccountUser = async () => {
        return 

        if ( !address ) return

        // Update the lastLogin info
        update(ref(database, `users/${address}`), {
            uid: address,
            lastLogin: Date.now()
        })

        getAuthToken({ 
            address: address
        }).then((result) => {
            const data:any = result.data        

            signInWithCustomToken(auth, data.token)
                .then((userCredential) => {

                    getUser({
                        id: address
                    }).then((result) => {
                        setAccountUser(result.data)
                    })
                   
                    addUserAchievement({ 
                        id: '1',
                        account: address.toLowerCase()
                    }).then((result) => {
                        
                    });
                    
                    const userAchievementsRef = ref(database, `userAchievements/${address.toLowerCase()}`);
                    onValue(userAchievementsRef, (snapshot) => {
                        if ( snapshot.val() ) {
                            const data:any = snapshotToArray(snapshot.val());
                            //console.log("achievements", data)
                            setAchievements(data);
                        }
                    }); 

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        })
    }


    const fetchBalances = async() => {
        if ( !address ) {
            console.log("early exit, no address")
            return 
        }

        const [
            h2o,
            ice, 
            //steam, 
            claimableH2OFromICE
        ] = await Promise.all([
            iceWater.contracts.H2O.methods.balanceOf(address).call(),
            iceWater.contracts.ICE.methods.balanceOf(address).call(),
            //iceWater.contracts.STEAM.methods.balanceOf(address).call(),
            iceWater.contracts.CTR.methods.claimableH2OFromICE().call({ 
                from: address 
            })
        ]); 

        setBalances({
            h2o: Web3.utils.fromWei(h2o),
            ice: Web3.utils.fromWei(ice),
            steam: Web3.utils.fromWei('0'),//Web3.utils.fromWei(steam),
            claimableH2OFromICE: Web3.utils.fromWei(claimableH2OFromICE)
        })
    }

    const fetchCubes = async() => {
        if ( !iceWater?.isUnlocked ) {
            return
        }
        if ( !isOnChain ) return

        // List of cubes issued to address
        const balance = await iceWater.contracts.CUBE.methods.balanceOf(address).call()
        // console.log(balance)
        
        var cubeIds = [];
        for (let i = 0; i < balance; i++) {
            cubeIds[i] = await iceWater.contracts.CUBE.methods.tokenOfOwnerByIndex(address, i).call();
        }
        setCubesReceived(cubeIds)

        const issuedBalance = await iceWater.contracts.CUBE.methods
            .getCreatorBalanceOf(address).call()
    
        var issuedCubeIds = []
        for (let i = 0; i < issuedBalance; i++) {
            const id = await iceWater.contracts.CUBE.methods
                .getCreatorCubeIdByIndex(address, i).call()
            const isRedeemed = await iceWater.contracts.CUBE.methods
                .isRedeemed(id).call()
            if ( !isRedeemed ) {
                issuedCubeIds[i] = parseInt(id)
            }
        }
        setCubesIssued(issuedCubeIds)
    }

    const addTransaction = useCallback((transaction: any) => {
        setTransactions([...transactions, transaction])
    }, [transactions, setTransactions]);

    const removeTransaction = useCallback((hash: string) => {
        setTransactions(transactions.filter(function( obj ) {
            return obj.transactionHash !== hash;
        }))
    }, [transactions, setTransactions]);

    const refreshBalances = useCallback(() => {
        fetchBalances()
    }, [balances, setBalances]);

    const refreshCubes = useCallback(() => {
        fetchCubes()
    }, [cubesReceived, setCubesReceived]);

    return  (
        <AccountContext.Provider value={{ 
            accountUser, 
            transactions, 
            balances,
            cubesReceived,
            cubesIssued,
            addTransaction, 
            removeTransaction, 
            refreshBalances,
            refreshCubes }}>
            {children}
        </AccountContext.Provider>
    );
};
export  { AccountContext,  AccountProvider };
