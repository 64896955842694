import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { MobileStyles } from 'theme/Mixins'

const Nav: React.FC = () => {
  return (
    <StyledNav>      
        <Left>
            <StyledLink href="https://icewater.money" target="_blank">IceWater</StyledLink>
            <Link to="/docs">Docs</Link>
            {/* <StyledLink href="https://community.icewater.money" target="_blank">Community</StyledLink> */}
        </Left>
        <div className='center'>

        </div>
        <Right>
            <StyledLink href="https://discord.gg/yw84JYaXHM" target="_blank">Discord</StyledLink>
            <StyledLink href="https://medium.com/icewatermoney" target="_blank">Medium</StyledLink>
            <StyledLink href="https://twitter.com/icewatermoney" target="_blank">Twitter</StyledLink> 
        </Right>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .center {
        height: 20px;
        width: 1px;
        margin: 0px 15px;
        background: #555;
    }
    ${MobileStyles(`
        display: block;

        .center {
            display: none;
        }
    `)};

    a {
        color: ${props => props.theme.color.white};
        padding-left: ${props => props.theme.spacing[3]}px;
        padding-right: ${props => props.theme.spacing[3]}px;
        text-decoration: none;
        opacity: .8;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 13px; 
      
        &:hover {
          opacity: 1;
        }
    }
`

const Left = styled.div `   
    ${MobileStyles(`
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 20px;
    `)};
`

const Right = styled.div `
    ${MobileStyles(`
        display: flex;
        width: 100%;
        justify-content: center;
    `)};
`

const StyledLink = styled.a`

`
export default Nav