import React, { useCallback } from 'react';
import styled from 'styled-components';

interface SideMenuHeaderProps {
    title: string
}
  
export const SideMenuHeader: React.FC<SideMenuHeaderProps> = ({
    title
}) => {  

    return (
        <Header className='bm-item'>
            { title }
        </Header>
    )
}

const Header = styled.div `
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px 0px;
    color: #ffffff;
`
