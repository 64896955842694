// @ts-nocheck
import React, { useCallback, useEffect, useContext, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';
import { WalletContext } from 'contexts/Account/WalletContext';
import Transaction from "./Transaction";
import useiceWater from '../../../hooks/useIceWater';

type Props = {
    limit?: number
}

const AccountTransactions: React.FC<any> = forwardRef(({
    limit = 20
}, refreshRef) => {
    const { address, connected } = useContext(WalletContext)
    const iceWater = useiceWater()
    const [pastEvents, setPastEvents] = useState<any>(null)
    const [transactions, setTransactions] = useState<any>(null)
    const [transactionLimit, setLimit] = useState<any>(limit)

    useImperativeHandle(refreshRef, () => ({
        refresh,
    }));

    const refresh = () => {
        fetchPastEvents()
    }

    useEffect(() => {
        if ( address ) { 
            fetchPastEvents()
        }
    }, [address])

    useEffect(() => {
        fetchTransactions()
    }, [pastEvents])

    const fetchPastEvents = async () => {
        if ( !address ) return

        setPastEvents(null)
        setTransactions(null)

        var Events: any[] = []

        // NOTE: Order matters. For example, we're fetching the ClaimRewards
        // before ICE transactions so that the ClaimRewards event is
        // used instead of the trasactions event for the same transaction.

        // Claim ICE Melt Rewards
        await iceWater.contracts.CTR.getPastEvents(
            'ClaimRewards',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // Redeem Cube
        await iceWater.contracts.CTR.getPastEvents(
            'RedeemCube',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    redeemer: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // Mint Cube
        await iceWater.contracts.CTR.getPastEvents(
            'MintCube',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    senderAccount: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // Auction Prize Winner
        await iceWater.contracts.CTR.getPastEvents(
            'CloseAuction',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    leadingBidder: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // Swap
        await iceWater.contracts.CTR.getPastEvents(
            'Swap',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // ClaimRewardsFromCube
        await iceWater.contracts.CTR.getPastEvents(
            'ClaimRewardsFromCube',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    account: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // H2O Received
        await iceWater.contracts.H2O.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    to: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        events.forEach(function (e:any) {
                            Events.push(e)
                        })
                    })
                }
            }
        )

        // H2O Sent
        await iceWater.contracts.H2O.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    from: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // ICE Received
        await iceWater.contracts.ICE.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    to: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        // ICE Sent
        await iceWater.contracts.ICE.getPastEvents(
            'Transfer',
            {
                fromBlock: 0, 
                toBlock: 'latest',
                filter: {
                    from: address
                }
            },
            (error:any, events:any) => {
                if ( !error ) {
                    events.forEach(function (e:any) {
                        Events.push(e)
                    })
                }
            }
        )

        /*
        // Debugging
        var tmp = []
        Events.forEach((event:any) => {
            if ( event.transactionHash == '0x9a07034e0abc975ebb95c29f5e98ebed22cd37324ef176393ea5856ca1fc1c75' ) {
                tmp.push(event)
            }
        })
        if ( tmp.length ) {
            const x = await iceWater.getTransactionReceipt(tmp[0]['transactionHash'])
            console.log(x)
        }
        //*/

        // unique 
        Events = Events.filter((obj, index, self) => {
            return index === self.findIndex((t) => (
                t.transactionHash === obj.transactionHash
            ));
        })

        // sort by blockNumber (more recent tx = higher blockNumber)
        Events = Events.sort((a:any, b:any) => {
            return ( a.blockNumber < b.blockNumber ) ? 1 : -1
        })

        setPastEvents(Events)
    }

    const fetchTransactions = async () => {
        if ( pastEvents && pastEvents.length > 0 ) {
            const LimitEvents = pastEvents.slice(0, transactionLimit)
            //console.log(LimitEvents)
            setTransactions(LimitEvents)

            /*
            var txPromises = []
            LimitEvents.forEach((event:any) => {
                txPromises.push(
                    iceWater.web3.eth.getTransactionReceipt(event.transactionHash)
                        .then((res:any) => {     
                            return res     
                        })
                )
            })
            const allTxs = await Promise.all(txPromises)
            console.log(allTxs)

            var blockPromises = []
            LimitEvents.forEach((event:any) => {
                blockPromises.push(
                    iceWater.web3.eth.getBlock(event.blockNumber)
                        .then((res:any) => {     
                            return res     
                        })
                )
            })
            const allBlocks = await Promise.all(blockPromises)
            console.log(allBlocks)
            */
        }
    }

    return (
        <Content>
            { transactions && transactions.length > 0 &&
                <>
                    {transactions.map((tx:any, index:number) => (
                        <Transaction tx={tx} key={`tx-${index}`} />
                    ))}
                </>
            }
        </Content> 
    );
});

export default AccountTransactions;

const Content = styled.div`

`;



